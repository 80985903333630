import dayjs from 'dayjs';
import { SEARCH_TYPES } from 'modals/RefactoredCashOrderModal/components/containers/ClientCounterpartyContainer/constants.js';
import {
    CASH_ORDER_TYPES,
    COUNTERPARTY_TYPES,
    DEF_BACK_DATE,
    MODES,
    SERVICE_OPERATION_TYPES,
    STORAGE_DOCUMENT_OPERATION_CODES
} from 'modals/RefactoredCashOrderModal/constants';
import {
    clearAnalyticsState,
    setAnalyticsFilters,
    setOrdersFilters,
    setStoreDocsFilters,
    setSuppliersStoreDocsFilters
} from 'modals/RefactoredCashOrderModal/redux/duck';
import { combineReducers } from 'redux';
import analyticsReducer, { moduleName as analyticsModule } from './analytics/duck';
import cashboxesReducer, { moduleName as cashboxesModule, clearCashboxesState } from './cashboxes/duck';
import clientsReducer, { clearClientsState, moduleName as clientsModule } from './clients/duck';
import employeesReducer, { clearEmployeesState, moduleName as employeesModule } from './employees/duck';
import ordersReducer, { clearOrdersState, moduleName as ordersModule } from './orders/duck';
import storeDocsReducer, { clearStoreDocsState, moduleName as storeDocsModule } from './storeDocs/duck';
import suppliersReducer, { clearSuppliersState, moduleName as suppliersModule } from './suppliers/duck';
import {
    clearSuppliersStoreDocsState,
    moduleName as rstModule,
    default as rstReducer,
    moduleName as suppliersStoreDocsModule,
    default as suppliersStoreDocsReducer
} from './suppliersStoreDocs/duck';

// Reexports from other ducks(required to access their content):
export * from './analytics/duck';
export * from './cashboxes/duck';
export * from './clients/duck';
export * from './employees/duck';
export * from './orders/duck';
export * from './rst/duck';
export * from './storeDocs/duck';
export * from './suppliers/duck';
export * from './suppliersStoreDocs/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const mainModuleName = 'refactoredCashOrderModal';
export const moduleName = 'common';
const prefix = `cpb/${mainModuleName}/${moduleName}`;

export const SET_CASH_ORDER_TYPE = `${prefix}/SET_CASH_ORDER_TYPE`;
export const SET_CASH_ORDER_DATE = `${prefix}/SET_CASH_ORDER_DATE`;

export const SET_SELECTED_COUNTERPARTY_TYPE = `${prefix}/SET_SELECTED_COUNTERPARTY_TYPE`;
export const SET_CLIENT_SEARCH_TYPE = `${prefix}/SET_CLIENT_SEARCH_TYPE`;
export const SET_CASH_ORDER_SUM = `${prefix}/SET_CASH_ORDER_SUM`;
export const SET_CASH_COLLECTED = `${prefix}/SET_CASH_COLLECTED`;
export const SET_REQUISITE = `${prefix}/SET_REQUISITE`;

export const SET_CASH_REMAINING = `${prefix}/SET_CASH_REMAINING`;
export const SET_COMMENT = `${prefix}/SET_COMMENT`;
export const SET_SALARY_DOC_NUM = `${prefix}/SET_SALARY_DOC_NUM`;
export const SET_SALARY = `${prefix}/SET_SALARY`;
export const CASH_COLLECTED = `${prefix}/CASH_COLLECTED`;
export const SET_SALARY_DOC_ID = `${prefix}/SET_SALARY_DOC_ID`;
export const SET_COMMENT_SECOND = `${prefix}/SET_COMMENT_SECOND`;
export const SET_OTHER_COUNTERPARTY_NAME = `${prefix}/SET_OTHER_COUNTERPARTY_NAME`;
export const SET_RESPONSIBLE = `${prefix}/SET_RESPONSIBLE`;
export const SET_REGISTERED_RST = `${prefix}/SET_REGISTERED_RST`;
export const FETCH_CASH_ORDER = `${prefix}/FETCH_CASH_ORDER`;
export const FETCH_CASH_ORDER_SUCCESS = `${prefix}/FETCH_CASH_ORDER_SUCCESS`;
export const SET_FETCHING_CASH_ORDER = `${prefix}/SET_FETCHING_CASH_ORDER`;

export const CREATE_CASH_ORDER = `${prefix}/CREATE_CASH_ORDER`;
export const UPDATE_ORDER = `${prefix}/UPDATE_ORDER`;
export const PRINT_CASH_ORDER = `${prefix}/PRINT_CASH_ORDER`;
export const CLEAR_MODAL_STATE = `${prefix}/CLEAR_MODAL_STATE`;

export const SET_ON_CASH_ORDER_CREATED_CALLBACK = `${prefix}/SET_ON_CASH_ORDER_CREATED_CALLBACK`;
export const SET_ON_CASH_ORDER_UPDATED_CALLBACK = `${prefix}/SET_ON_CASH_ORDER_UPDATED_CALLBACK`;
export const SET_MODE = `${prefix}/SET_MODE`;
export const SET_SERVICE_OPERATION_TYPE = `${prefix}/SET_SERVICE_OPERATION_TYPE`;
export const SET_CASH_ORDER_ID = `${prefix}/SET_CASH_ORDER_ID`;
export const SET_INITIALIZED = `${prefix}/SET_INITIALIZED`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    cashOrderType: CASH_ORDER_TYPES.INCOME,
    cashOrderDate: dayjs().format(DEF_BACK_DATE),
    selectedCounterpartyType: COUNTERPARTY_TYPES.CLIENT,
    clientSearchType: SEARCH_TYPES.FOR_CLIENT,
    cashOrderSum: 0,
    cashCollected: 0,
    requisiteId: null,
    cashRemaining: 0,
    comment: '',
    otherCounterpartyName: undefined,
    responsible: undefined,
    isRegisteredWithRst: false,
    initialized: false, // Defines if modal was initialized or not

    cashOrder: {},
    fetchingCashOrder: false,

    onCashOrderCreatedCallback: undefined, // Callback has to be called after cashOrder was successfully created
    onCashOrderUpdatedCallback: undefined, // Callback has to be called after cashOrder was successfully updated
    mode: MODES.ADD,
    serviceOperationType: SERVICE_OPERATION_TYPES.DEFAULT,
    salaryDocNum: undefined,
    salary: undefined,
    salaryDocId: undefined,
    cashOrderId: undefined // Used in edit and view mode,
};

// eslint-disable-next-line complexity
export function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_CASH_ORDER_TYPE:
            return {
                ...state,
                cashOrderType: payload
            };
        case SET_CASH_ORDER_DATE:
            return {
                ...state,
                cashOrderDate: payload
            };
        case SET_SELECTED_COUNTERPARTY_TYPE:
            return {
                ...state,
                selectedCounterpartyType: payload
            };
        case SET_CLIENT_SEARCH_TYPE:
            return {
                ...state,
                clientSearchType: payload
            };
        case SET_CASH_ORDER_SUM:
            return {
                ...state,
                cashOrderSum: payload
            };
        case SET_CASH_COLLECTED:
            return {
                ...state,
                cashCollected: payload
            };
        case SET_REQUISITE:
            return {
                ...state,
                requisiteId: payload
            };
        case SET_CASH_REMAINING:
            return {
                ...state,
                cashRemaining: payload
            };
        case SET_COMMENT:
            return {
                ...state,
                comment: payload
            };
        case SET_COMMENT_SECOND:
            return {
                ...state,
                commentSec: payload
            };
        case SET_OTHER_COUNTERPARTY_NAME:
            return {
                ...state,
                otherCounterpartyName: payload
            };
        case SET_RESPONSIBLE:
            return {
                ...state,
                responsible: payload
            };
        case SET_REGISTERED_RST:
            return {
                ...state,
                isRegisteredWithRst: payload
            };
        case SET_ON_CASH_ORDER_CREATED_CALLBACK:
            return {
                ...state,
                onCashOrderCreatedCallback: payload
            };
        case SET_ON_CASH_ORDER_UPDATED_CALLBACK:
            return {
                ...state,
                onCashOrderUpdatedCallback: payload
            };
        case SET_INITIALIZED:
            return {
                ...state,
                initialized: payload
            };
        case CLEAR_MODAL_STATE:
            return ReducerState;
        case SET_MODE:
            return {
                ...state,
                mode: payload
            };
        case SET_SERVICE_OPERATION_TYPE:
            return {
                ...state,
                serviceOperationType: payload
            };
        case FETCH_CASH_ORDER_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_FETCHING_CASH_ORDER:
            return {
                ...state,
                fetchingCashOrder: payload
            };
        case SET_CASH_ORDER_ID:
            return {
                ...state,
                cashOrderId: payload
            };

        case SET_SALARY_DOC_NUM:
            return {
                ...state,
                salaryDocNum: payload
            };
        case SET_SALARY:
            return {
                ...state,
                salary: payload
            };

        case SET_SALARY_DOC_ID:
            return {
                ...state,
                salaryDocId: payload
            };
        default:
            return state;
    }
}

// Export this reducer in the way we can connect other reducers
export default combineReducers({
    [moduleName]: reducer,
    [clientsModule]: clientsReducer,
    [ordersModule]: ordersReducer,
    [cashboxesModule]: cashboxesReducer,
    [analyticsModule]: analyticsReducer,
    [storeDocsModule]: storeDocsReducer,
    [employeesModule]: employeesReducer,
    [suppliersModule]: suppliersReducer,
    [suppliersStoreDocsModule]: suppliersStoreDocsReducer,
    [rstModule]: rstReducer
});

/** ------------------------------------- Selectors ------------------------------------- * */

export const selectCashOrderType = state => state[mainModuleName][moduleName].cashOrderType;
export const selectCashOrderDate = state => state[mainModuleName][moduleName].cashOrderDate;
export const selectSelectedCounterpartyType = state => state[mainModuleName][moduleName].selectedCounterpartyType;
export const selectClientSearchType = state => state[mainModuleName][moduleName].clientSearchType;
export const selectCashOrderSum = state => state[mainModuleName][moduleName].cashOrderSum;
export const selectComment = state => state[mainModuleName][moduleName].comment;
export const selectCommentSecond = state => state[mainModuleName][moduleName].commentSec;
export const selectOtherCounterpartyName = state => state[mainModuleName][moduleName].otherCounterpartyName;
export const selectResponsible = state => state[mainModuleName][moduleName].responsible;
export const selectIsRegisteredRST = state => state[mainModuleName][moduleName].isRegisteredWithRst;
export const selectOnCashOrderCreatedCallback = state => state[mainModuleName][moduleName].onCashOrderCreatedCallback;
export const selectOnCashOrderUpdatedCallback = state => state[mainModuleName][moduleName].onCashOrderUpdatedCallback;
export const selectMode = state => state[mainModuleName][moduleName].mode;
export const selectServiceOperationType = state => state[mainModuleName][moduleName].serviceOperationType;
export const selectCashOrder = state => state[mainModuleName][moduleName].cashOrder;
export const selectFetchingCashOrder = state => state[mainModuleName][moduleName].fetchingCashOrder;
export const selectCashOrderId = state => state[mainModuleName][moduleName].cashOrderId;
export const selectInitialized = state => state[mainModuleName][moduleName].initialized;
export const selectSalaryDocNum = state => state[mainModuleName][moduleName].salaryDocNum;
export const selectSalary = state => state[mainModuleName][moduleName].salary;
export const selectCashCollected = state => state[mainModuleName][moduleName].cashCollected;
export const selectRequisiteId = state => state[mainModuleName][moduleName].requisiteId;
export const selectCashRemaining = state => state[mainModuleName][moduleName].cashRemaining;
export const selectSalaryDocId = state => state[mainModuleName][moduleName].salaryDocId;

/* ------------------------------------- Action Creators ------------------------------------- */

/**
 * Set elected cash order type and update analytics as it depends on it.
 * Set new storage document filters
 * @param {*} value
 */
export const setCashOrderType = value => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_CASH_ORDER_TYPE,
            payload: value
        });

        const { OUT, CRT, SRT, INC, SRV } = STORAGE_DOCUMENT_OPERATION_CODES;

        // Refresh filters for store docs and orders
        // Make sure to call each set action only once(as it fetches data automatically)
        if (value === CASH_ORDER_TYPES.INCOME || value === CASH_ORDER_TYPES.ADJUSTMENT_INCOME) {
            dispatch(setAnalyticsFilters({ cashOrderType: CASH_ORDER_TYPES.INCOME }));
            // Additional filters for adjustment cash order type
            if (value === CASH_ORDER_TYPES.ADJUSTMENT_INCOME) {
                dispatch(setSuppliersStoreDocsFilters({ operationCodes: [OUT, SRT], sumRemains: false }));
                dispatch(setStoreDocsFilters({ sumRemains: false }));
                dispatch(setOrdersFilters({ sumRemains: false }));
            } else {
                dispatch(setSuppliersStoreDocsFilters({ operationCodes: [OUT, SRT], sumRemains: true }));
                dispatch(setStoreDocsFilters({ sumRemains: true }));
                dispatch(setOrdersFilters({ sumRemains: true }));
            }
        } else {
            dispatch(setAnalyticsFilters({ cashOrderType: CASH_ORDER_TYPES.EXPENSE }));
            // Additional filters for adjustment cash order type
            if (value === CASH_ORDER_TYPES.ADJUSTMENT_EXPENSE) {
                dispatch(
                    setSuppliersStoreDocsFilters({
                        operationCodes: [INC, CRT, SRV],
                        sumRemains: false
                    })
                );
                dispatch(setStoreDocsFilters({ sumRemains: false }));
                dispatch(setOrdersFilters({ sumRemains: false }));
            } else {
                dispatch(
                    setSuppliersStoreDocsFilters({
                        operationCodes: [INC, CRT, SRV],
                        sumRemains: true
                    })
                );
                dispatch(setStoreDocsFilters({ sumRemains: true }));
                dispatch(setOrdersFilters({ sumRemains: true }));
            }
        }
    };
};

export const setCashOrderDate = value => ({
    type: SET_CASH_ORDER_DATE,
    payload: value
});

export const setSelectedCounterpartyType = value => ({
    type: SET_SELECTED_COUNTERPARTY_TYPE,
    payload: value
});

export const setClientSearchType = value => ({
    type: SET_CLIENT_SEARCH_TYPE,
    payload: value
});

/**
 * Automatically validates passed value and uses 0 if provided value was invalid.
 * If value was less then zero, then it will be casted to positive one(use other
 * cash order types if you need to decrease amount somewhere)
 * @param {*} value sum of a cash order
 */
export const setCashOrderSum = (value, precision = 2) => ({
    type: SET_CASH_ORDER_SUM,
    payload: Math.abs(Number(Number(value).toFixed(precision))) // Convert value to proper format what ever it was passed
});

export const setRequisites = value => ({
    type: SET_REQUISITE,
    payload: value
});

export const setCashCollected = (value, precision = 2) => ({
    type: SET_CASH_COLLECTED,
    payload: Math.abs(Number(Number(value).toFixed(precision))) // Convert value to proper format what ever it was passed
});

export const setCashRemaining = (value, precision = 2) => ({
    type: SET_CASH_REMAINING,
    payload: Math.abs(Number(Number(value).toFixed(precision))) // Convert value to proper format what ever it was passed
});

export const createCashOrder = () => ({
    type: CREATE_CASH_ORDER
});

export const updateOrder = () => ({
    type: UPDATE_ORDER
});

export const setComment = value => ({
    type: SET_COMMENT,
    payload: value
});

export const setSalaryDocNum = value => ({
    type: SET_SALARY_DOC_NUM,
    payload: value
});

export const setSalary = value => ({
    type: SET_SALARY,
    payload: value
});

export const setSalaryDocId = value => ({
    type: SET_SALARY_DOC_ID,
    payload: value
});

export const setCommentSecond = value => ({
    type: SET_COMMENT_SECOND,
    payload: value
});

export const setOtherCounterpartyName = value => ({
    type: SET_OTHER_COUNTERPARTY_NAME,
    payload: value
});

export const setResponsible = value => ({
    type: SET_RESPONSIBLE,
    payload: value
});

export const setRegisteredRst = value => ({
    type: SET_REGISTERED_RST,
    payload: value
});

/**
 * @callback onCashOrderCreatedCallback({cashOrderId}) -- called after cash order was created
 */
export const setOnCashOrderCreatedCallback = value => ({
    type: SET_ON_CASH_ORDER_CREATED_CALLBACK,
    payload: value
});

/**
 * @callback onCashOrderUpdatedCallback({cashOrderId}) -- called after cash order was updated
 */
export const setOnCashOrderUpdatedCallback = value => ({
    type: SET_ON_CASH_ORDER_UPDATED_CALLBACK,
    payload: value
});

/**
 * Set value which define if modal is initialized or it isn't.
 * When redux is cleared(modal is closed and state is set ot default) default value is set to false.
 * @param {*} value - is modal initialized
 */
export const setInitialized = value => ({
    type: SET_INITIALIZED,
    payload: value
});

/**
 * Clear redux state of all components
 */
export const clearModalState = () => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: CLEAR_MODAL_STATE
        });

        dispatch(clearAnalyticsState());
        dispatch(clearCashboxesState());
        dispatch(clearClientsState());
        dispatch(clearEmployeesState());
        dispatch(clearOrdersState());
        dispatch(clearStoreDocsState());
        dispatch(clearSuppliersState());
        dispatch(clearSuppliersStoreDocsState());
    };
};

/** Set mode for this modal, components behavior depend on this field */
export const setMode = value => ({
    type: SET_MODE,
    payload: value
});

export const setServiceOperationType = value => ({
    type: SET_SERVICE_OPERATION_TYPE,
    payload: value
});

export const fetchCashOrder = () => ({
    type: FETCH_CASH_ORDER
});

/*
 * @param {*} fetchedData.cashOrder
 */
export const fetchCashOrderSuccess = fetchedData => ({
    type: FETCH_CASH_ORDER_SUCCESS,
    payload: fetchedData
});

export const setFetchingCashOrder = value => ({
    type: SET_FETCHING_CASH_ORDER,
    payload: value
});

/**
 * cash order id is used in edit and view mode to represent data.
 * This automatically fetches cash order
 * @param value - cash order id
 */
export const setCashOrderId = value => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_CASH_ORDER_ID,
            payload: value
        });

        dispatch(fetchCashOrder());
    };
};

export const printCashOrder = () => ({
    type: PRINT_CASH_ORDER
});
