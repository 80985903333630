/* eslint-disable no-sparse-arrays */
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';

// Ant Design
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    BlockOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    FilterFilled,
    ForkOutlined,
    LockOutlined,
    MenuOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    RedoOutlined,
    SearchOutlined,
    ShoppingOutlined,
    SortAscendingOutlined,
    UnlockOutlined,
    UnorderedListOutlined,
    WarningOutlined
} from '@ant-design/icons';
import {
    Button,
    Dropdown,
    Input,
    InputNumber,
    Menu,
    Modal,
    Popconfirm,
    Popover,
    Radio,
    Select,
    Space,
    Table,
    Tabs,
    Tooltip,
    notification
} from 'antd';

// Project Imports
import { Catcher, Layout } from 'commons';
import { AvailabilityIndicator, DateRangePicker, HamburgerMenu, ReserveButton } from 'components';
import { OrderDrawerContainer } from 'containers';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import UpdatePriceModal from 'forms/OrderForm/OrderFormTables/DetailsTable/modals/UpdatePriceModal';
import {
    AddRowsReturnSurplusesModal,
    DetailStorageModal,
    DetailSupplierModal,
    DetailWarehousesCountModal,
    OrderDetailModal,
    StoreProductModal,
    StoreProductTrackingModal,
    VinCodeModal
} from 'modals';

import book from 'routes/book';
import { GridIcon, PriceTagIcon } from 'theme';
import {
    buildStoreGroupsTree,
    fetchAPI,
    isForbidden,
    numeralFormatter4Digits,
    permissions,
    showDetailsActionNotification
} from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import SuppliersIncomeModal from '../../forms/OrderForm/OrderFormTables/DetailsTable/modals/SuppliersIncomeModal';

// Local Redux
import {
    changeOrdersSurplusesDataFilters,
    changeSparePartsWorkplaceDataFilters,
    changeSparePartsWorkplaceStorageDataFilters,
    changeSparePartsWorkplaceTab,
    fetchSparePartsWorkplaceData,
    returnDetailsToSupplier,
    selectOrdersToStorageData,
    selectSparePartsWorkplaceData,
    selectSparePartsWorkplaceFilters,
    selectSparePartsWorkplaceStorageFilters,
    updateOrdersForSurpluses,
    updateSparePartsWorkplaceData
} from './redux/duck';

// Styles
import Styles from './styles.m.css';

// Constants & Config
const warehouseSkipColumns = ['price', 'reserve', 'agreement'];
const inputPattern = /[A-Za-z0-9\u0410-\u044F]/g; // Allows alphanumeric + Cyrillic
const { confirm } = Modal;
const { Option } = Select;
const { TabPane } = Tabs;
const { SubMenu } = Menu;

/**
 * Redux mappings
 */
const mapStateToProps = state => ({
    sparePartsData: selectSparePartsWorkplaceData(state),
    ordersToStorageData: selectOrdersToStorageData(state),
    filters: selectSparePartsWorkplaceFilters(state),
    storageFilters: selectSparePartsWorkplaceStorageFilters(state),
    brands: selectBrands(state),
    warehouses: state.warehouses.warehouses,
    user: state.auth,
    modal: state.modals.modal
});

const mapDispatchToProps = {
    fetchSparePartsWorkplaceData,
    updateSparePartsWorkplaceData,
    updateOrdersForSurpluses,
    changeSparePartsWorkplaceDataFilters,
    changeSparePartsWorkplaceStorageDataFilters,
    changeOrdersSurplusesDataFilters,
    returnDetailsToSupplier,
    changeSparePartsWorkplaceTab,
    fetchBrands,
    fetchWarehouses,
    setModal,
    resetModal
};

/**
 * Main class component
 */
@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class SparePartsWorkplacePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRowKeys: [],
            selectedRows: [],
            searchText: '',
            searchedColumn: '',
            loading: true,
            detailsTreeData: [],
            allDetails: [],
            statuses: [],
            activeKey: 'fromOrders',
            displayType: 'list',
            units: [],
            disableSparePartsWorkPlace: true,
            suppliers: [],
            page: 1,
            pageSize: 15
        };

        this.columns = () => {
            const isWarehouse = this.state.activeKey === 'storageOrders';

            return [
                {
                    title: () => {
                        const { warehouses } = this.props;

                        const {
                            fetchSparePartsWorkplaceData,
                            updateSparePartsWorkplaceData,
                            updateOrdersForSurpluses
                        } = this;

                        const { selectedRowKeys, selectedRows, activeKey } = this.state;
                        const actionsMenu = () => (
                            <Menu className={Styles.actionMenuDropdown}>
                                <Menu.Item disabled={!selectedRowKeys.length}>
                                    <div
                                        onClick={async () => {
                                            if (activeKey === 'returnSurpluses') {
                                                await fetchAPI(
                                                    'PUT',
                                                    'orders/details',
                                                    undefined,
                                                    {
                                                        details: selectedRows.map(({ id }) => ({
                                                            id,
                                                            isCriticalByTime: true
                                                        }))
                                                    },
                                                    { handleErrorInternally: true }
                                                );
                                                this.getOrdersForSurpluses();
                                            } else {
                                                updateSparePartsWorkplaceData(
                                                    selectedRows.map(({ id }) => ({
                                                        id,
                                                        isCriticalByTime: true
                                                    }))
                                                );
                                            }
                                        }}
                                    >
                                        <WarningOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({
                                            id: 'profile.spare_parts_workplace.critical'
                                        })}
                                    </div>
                                </Menu.Item>
                                <SubMenu
                                    disabled={!selectedRowKeys.length}
                                    title={
                                        <React.Fragment>
                                            <EditOutlined className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                            {this.props.intl.formatMessage({
                                                id: 'profile.spare_parts_workplace.change_status'
                                            })}
                                        </React.Fragment>
                                    }
                                >
                                    {this.state.statuses.map(({ status }) => (
                                        <Menu.Item
                                            key={status}
                                            onClick={async () => {
                                                if (activeKey === 'returnSurpluses') {
                                                    await fetchAPI(
                                                        'PUT',
                                                        'orders/details',
                                                        undefined,
                                                        {
                                                            details: selectedRows.map(({ id }) => ({
                                                                id,
                                                                status
                                                            }))
                                                        },
                                                        { handleErrorInternally: true }
                                                    );
                                                    this.getOrdersForSurpluses();
                                                } else {
                                                    updateSparePartsWorkplaceData(
                                                        selectedRows.map(({ id }) => ({
                                                            id,
                                                            status
                                                        }))
                                                    );
                                                }
                                            }}
                                        >
                                            {this.props.intl.formatMessage({ id: `status.${status}` })}
                                        </Menu.Item>
                                    ))}
                                </SubMenu>
                                <SubMenu
                                    disabled={!selectedRowKeys.length || isWarehouse}
                                    title={
                                        <React.Fragment>
                                            <QuestionCircleOutlined
                                                className={Styles.actionMenuIcon}
                                                style={{ fontSize: 18 }}
                                            />
                                            {this.props.intl.formatMessage({ id: 'order_form_table.PD' })}
                                        </React.Fragment>
                                    }
                                >
                                    <Menu.Item
                                        key='undefined'
                                        onClick={async () => {
                                            if (activeKey === 'returnSurpluses') {
                                                await fetchAPI(
                                                    'PUT',
                                                    'orders/details',
                                                    undefined,
                                                    {
                                                        details: selectedRows.map(({ id }) => ({
                                                            id,
                                                            agreement: 'UNDEFINED'
                                                        }))
                                                    },
                                                    { handleErrorInternally: true }
                                                );
                                                this.getOrdersForSurpluses();
                                            } else {
                                                updateSparePartsWorkplaceData(
                                                    selectedRows.map(({ id }) => ({
                                                        id,
                                                        agreement: 'UNDEFINED'
                                                    }))
                                                );
                                            }
                                        }}
                                    >
                                        <QuestionCircleOutlined
                                            style={{
                                                fontSize: 18,
                                                verticalAlign: 'sub',
                                                marginRight: 8
                                            }}
                                        />
                                        {this.props.intl.formatMessage({ id: 'agreement.undefined' })}
                                    </Menu.Item>
                                    <Menu.Item
                                        key='agreed'
                                        onClick={async () => {
                                            if (activeKey === 'returnSurpluses') {
                                                await fetchAPI(
                                                    'PUT',
                                                    'orders/details',
                                                    undefined,
                                                    {
                                                        details: selectedRows.map(({ id }) => ({
                                                            id,
                                                            agreement: 'AGREED'
                                                        }))
                                                    },
                                                    { handleErrorInternally: true }
                                                );
                                                this.getOrdersForSurpluses();
                                            } else {
                                                updateSparePartsWorkplaceData(
                                                    selectedRows.map(({ id }) => ({
                                                        id,
                                                        agreement: 'AGREED'
                                                    }))
                                                );
                                            }
                                        }}
                                        style={{ color: 'var(--green)' }}
                                    >
                                        <CheckCircleOutlined
                                            style={{
                                                fontSize: 18,
                                                verticalAlign: 'sub',
                                                marginRight: 8,
                                                color: 'var(--green)'
                                            }}
                                        />
                                        {this.props.intl.formatMessage({ id: 'agreement.agreed' })}
                                    </Menu.Item>
                                    <Menu.Item
                                        key='rejected'
                                        onClick={async () => {
                                            if (activeKey === 'returnSurpluses') {
                                                await fetchAPI(
                                                    'PUT',
                                                    'orders/details',
                                                    undefined,
                                                    {
                                                        details: selectedRows.map(({ id }) => ({
                                                            id,
                                                            agreement: 'REJECTED'
                                                        }))
                                                    },
                                                    { handleErrorInternally: true }
                                                );
                                                this.getOrdersForSurpluses();
                                            } else {
                                                updateSparePartsWorkplaceData(
                                                    selectedRows.map(({ id }) => ({
                                                        id,
                                                        agreement: 'REJECTED'
                                                    }))
                                                );
                                            }
                                        }}
                                        style={{ color: 'rgb(255, 126, 126)' }}
                                    >
                                        <CloseCircleOutlined
                                            style={{
                                                fontSize: 18,
                                                marginRight: 8
                                            }}
                                        />
                                        {this.props.intl.formatMessage({ id: 'agreement.rejected' })}
                                    </Menu.Item>
                                </SubMenu>
                                <Menu.Item disabled={!selectedRowKeys.length}>
                                    <div
                                        onClick={() => {
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({ id: 'orders.update_stage' }),
                                                onOk: async () => {
                                                    const response = await fetchAPI(
                                                        'PUT',
                                                        'orders/details',
                                                        undefined,
                                                        {
                                                            details: selectedRows.map(({ id }) => ({ id }))
                                                        },
                                                        { handleErrorInternally: true }
                                                    );
                                                    await showDetailsActionNotification(
                                                        this.props.intl,
                                                        'update_stage',
                                                        response.all,
                                                        response.success
                                                    );
                                                    if (activeKey === 'returnSurpluses') {
                                                        this.getOrdersForSurpluses();
                                                    } else {
                                                        fetchSparePartsWorkplaceData();
                                                    }
                                                }
                                            });
                                        }}
                                    >
                                        <RedoOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'orders.update_stage' })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    key='groupDetailsMenu.changeGroup'
                                    disabled
                                    onClick={async () => {
                                        const groupName = v4();
                                        if (activeKey === 'returnSurpluses') {
                                            await fetchAPI(
                                                'PUT',
                                                'orders/details',
                                                undefined,
                                                {
                                                    details: selectedRows.map(({ id, agreement }, index) => ({
                                                        id,
                                                        groupName,
                                                        agreement: index >= 1 ? 'REJECTED' : agreement
                                                    }))
                                                },
                                                { handleErrorInternally: true }
                                            );
                                            this.getOrdersForSurpluses();
                                        } else {
                                            updateSparePartsWorkplaceData(
                                                selectedRows.map(({ id, agreement }, index) => ({
                                                    id,
                                                    groupName,
                                                    agreement: index >= 1 ? 'REJECTED' : agreement
                                                }))
                                            );
                                        }
                                    }}
                                >
                                    <div>
                                        <BlockOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'orders.to_group' })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item disabled={!selectedRowKeys.length}>
                                    <div
                                        onClick={async () => {
                                            const payload = {
                                                details: [
                                                    ...this.state.selectedRows.map(row => ({
                                                        storeGroupId: row.storeGroupId,
                                                        name: row.detailName,
                                                        productCode: row.detailCode,
                                                        supplierId: row.supplierId,
                                                        supplierBrandId: row.supplierBrandId,
                                                        purchasePrice: row.purchasePrice,
                                                        count: row.count,
                                                        price: row.price,
                                                        putAfterId: row.id,
                                                        orderId: row.orderId,
                                                        agreement:
                                                            this.state.activeKey === 'storageOrders'
                                                                ? 'AGREED'
                                                                : undefined
                                                    }))
                                                ]
                                            };
                                            const response = await fetchAPI(
                                                'POST',
                                                'orders/details',
                                                undefined,
                                                payload
                                            );
                                            if (activeKey === 'returnSurpluses') {
                                                this.getOrdersForSurpluses();
                                            } else {
                                                fetchSparePartsWorkplaceData();
                                            }
                                        }}
                                    >
                                        <CopyOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.copy' })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item disabled={!selectedRowKeys.length}>
                                    <div onClick={() => this.setState({ setSupplierModalVisible: true })}>
                                        <PlusOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({
                                            id: 'profile.spare_parts_workplace.set_supplier'
                                        })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item disabled={!selectedRowKeys.length}>
                                    <div
                                        onClick={() => {
                                            this.setState({
                                                priceRecalculationModalSelectedRow: selectedRows
                                            });
                                        }}
                                    >
                                        <PriceTagIcon className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'update_price' })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    disabled={
                                        !selectedRowKeys.length ||
                                        activeKey === 'returnSurpluses' ||
                                        this.state.selectedRows.find(({ detailCode }) =>
                                            detailCode
                                                ? !detailCode.match(inputPattern)
                                                : undefined || detailCode === null
                                        )
                                    }
                                >
                                    <div
                                        onClick={() => {
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'profile.spare_parts_workplace.to_order'
                                                }),
                                                onOk: async () => {
                                                    const response = await fetchAPI(
                                                        'POST',
                                                        'v2/store_docs/order_all_possible',
                                                        undefined,
                                                        {
                                                            ordersAppurtenanciesIds: selectedRows
                                                                .filter(row => row.agreement == 'AGREED')
                                                                .map(({ id }) => id)
                                                        },
                                                        { handleErrorInternally: true }
                                                    );
                                                    await showDetailsActionNotification(
                                                        this.props.intl,
                                                        'to_order',
                                                        response.all,
                                                        response.success
                                                    );
                                                    fetchSparePartsWorkplaceData();
                                                }
                                            });
                                        }}
                                    >
                                        <ShoppingOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({
                                            id: 'profile.spare_parts_workplace.to_order'
                                        })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    disabled={
                                        !selectedRowKeys.length ||
                                        this.state.selectedRows.find(({ detailCode }) =>
                                            detailCode
                                                ? !detailCode.match(inputPattern)
                                                : undefined || detailCode === null) ||
                                        isWarehouse
                                    }
                                    onClick={async () => {
                                        if (selectedRowKeys.length) {
                                            await this.setState({
                                                visibleSuppliersIncomeModal: true
                                            });
                                        }
                                    }}
                                >
                                    <CheckOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.accept' })}
                                </Menu.Item>
                                <Menu.Item
                                    disabled={!selectedRowKeys.length || activeKey === 'returnSurpluses' || isWarehouse}
                                >
                                    <div
                                        onClick={async () => {
                                            const response = await fetchAPI(
                                                'POST',
                                                'v2/store_docs/reserve_all_possible',
                                                undefined,
                                                {
                                                    ordersAppurtenanciesIds: selectedRows.map(({ id }) => id)
                                                },
                                                { handleErrorInternally: true }
                                            );
                                            await showDetailsActionNotification(
                                                this.props.intl,
                                                'reserved',
                                                response.all,
                                                response.success
                                            );
                                            if (activeKey === 'returnSurpluses') {
                                                this.getOrdersForSurpluses();
                                            } else {
                                                fetchSparePartsWorkplaceData();
                                            }
                                        }}
                                    >
                                        <LockOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'reserve' })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    disabled={!selectedRowKeys.length || activeKey === 'returnSurpluses' || isWarehouse}
                                >
                                    <div
                                        onClick={async () => {
                                            const response = await fetchAPI(
                                                'POST',
                                                'v2/store_docs/unreserve_all_possible',
                                                undefined,
                                                {
                                                    ordersAppurtenanciesIds: selectedRows.map(({ id }) => id)
                                                },
                                                { handleErrorInternally: true }
                                            );
                                            await showDetailsActionNotification(
                                                this.props.intl,
                                                'unreserved',
                                                response.all,
                                                response.success
                                            );
                                            if (activeKey === 'returnSurpluses') {
                                                this.getOrdersForSurpluses();
                                            } else {
                                                fetchSparePartsWorkplaceData();
                                            }
                                        }}
                                    >
                                        <UnlockOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'unreserve' })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    disabled={!selectedRowKeys.length || activeKey === 'returnSurpluses' || isWarehouse}
                                >
                                    <div
                                        onClick={() => {
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'profile.spare_parts_workplace.give'
                                                }),
                                                onOk: async () => {
                                                    const response = await fetchAPI(
                                                        'POST',
                                                        'v2/store_docs/transfer_reserved_all_possible',
                                                        undefined,
                                                        {
                                                            ordersAppurtenanciesIds: selectedRows.map(({ id }) => id),
                                                            toWarehouseId: warehouses.find(
                                                                ({ attribute }) => attribute == 'REPAIR_AREA'
                                                            ).id
                                                        },
                                                        { handleErrorInternally: true }
                                                    );
                                                    await showDetailsActionNotification(
                                                        this.props.intl,
                                                        'transfer_to_repair',
                                                        response.all,
                                                        response.success
                                                    );
                                                    if (activeKey === 'returnSurpluses') {
                                                        this.getOrdersForSurpluses();
                                                    } else {
                                                        fetchSparePartsWorkplaceData();
                                                    }
                                                }
                                            });
                                        }}
                                    >
                                        <ArrowRightOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.give' })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    disabled={!selectedRowKeys.length || activeKey === 'returnSurpluses' || isWarehouse}
                                >
                                    <div
                                        onClick={() => {
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'profile.spare_parts_workplace.return_to_stock'
                                                }),
                                                onOk: async () => {
                                                    const response = await fetchAPI(
                                                        'POST',
                                                        'v2/store_docs/transfer_reserved_all_possible',
                                                        undefined,
                                                        {
                                                            ordersAppurtenanciesIds: selectedRows.map(({ id }) => id),
                                                            toWarehouseId: warehouses.find(
                                                                ({ attribute }) => attribute == 'MAIN'
                                                            ).id
                                                        },
                                                        { handleErrorInternally: true }
                                                    );
                                                    await showDetailsActionNotification(
                                                        this.props.intl,
                                                        'transfer_to_stock',
                                                        response.all,
                                                        response.success
                                                    );
                                                    if (activeKey === 'returnSurpluses') {
                                                        this.getOrdersForSurpluses();
                                                    } else {
                                                        fetchSparePartsWorkplaceData();
                                                    }
                                                }
                                            });
                                        }}
                                    >
                                        <ArrowLeftOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({
                                            id: 'profile.spare_parts_workplace.return_to_stock'
                                        })}
                                    </div>
                                </Menu.Item>
                                {activeKey !== 'fromOrders' && (
                                    <Menu.Item disabled={!selectedRowKeys.length}>
                                        <div
                                            onClick={() => {
                                                Modal.confirm({
                                                    title: this.props.intl.formatMessage({
                                                        id: 'add_order_form.delete_confirm'
                                                    }),
                                                    onOk: async () => {
                                                        await fetchAPI(
                                                            'DELETE',
                                                            'orders/details',
                                                            undefined,
                                                            {
                                                                ids: selectedRows
                                                                    .filter(
                                                                        ({ reservedCount, status, agreement }) =>
                                                                            status != 'OK' &&
                                                                            status != 'READY' &&
                                                                            agreement != 'AGREED' &&
                                                                            agreement != 'REJECTED' &&
                                                                            !reservedCount
                                                                    )
                                                                    .map(({ id }) => id)
                                                            },
                                                            { handleErrorInternally: true }
                                                        );
                                                        await notification.success({
                                                            message: this.props.intl.formatMessage({
                                                                id: 'details_table.deleted'
                                                            })
                                                        });
                                                        if (activeKey === 'returnSurpluses') {
                                                            this.getOrdersForSurpluses();
                                                        } else {
                                                            fetchSparePartsWorkplaceData();
                                                        }
                                                    }
                                                });
                                            }}
                                        >
                                            <DeleteOutlined className={Styles.actionMenuIcon} />
                                            {this.props.intl.formatMessage({ id: 'delete' })}
                                        </div>
                                    </Menu.Item>
                                )}
                            </Menu>
                        );

                        return (
                            <Space>
                                <HamburgerMenu actionsMenu={actionsMenu}>
                                    <Button data-qa='btn_menu_details_table_order_page' icon={<MenuOutlined />} />
                                </HamburgerMenu>
                                {isWarehouse && (
                                    <Tooltip title={this.props.intl.formatMessage({ id: 'close_order' })}>
                                        <Popconfirm
                                            onConfirm={async () => {
                                                if (selectedRows.length) {
                                                    Promise.all(
                                                        selectedRows.map(row =>
                                                            fetchAPI('PUT', `/massive_market/${row.orderId}`, null, {
                                                                status: 'success'
                                                            }))
                                                    ).then(() => {
                                                        this.fetchSparePartsWorkplaceData();
                                                    });
                                                } else {
                                                    await fetchAPI('DELETE', '/massive_market', null, null, {
                                                        handleErrorInternally: true
                                                    });
                                                    this.fetchSparePartsWorkplaceData();
                                                }
                                            }}
                                            title={`${this.props.intl.formatMessage({ id: 'delete' })}?`}
                                        >
                                            <Button icon={<CloseCircleOutlined />} />
                                        </Popconfirm>
                                    </Tooltip>
                                )}
                            </Space>
                        );
                    },
                    key: 'actions',
                    className: Styles.tableColumn,
                    align: 'center',
                    render: row => {
                        const { activeKey } = this.state;
                        const actionsMenu = () => (
                            <Menu>
                                <Menu.Item>
                                    <div
                                        onClick={() => {
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({ id: 'orders.update_stage' }),
                                                onOk: async () => {
                                                    const response = await fetchAPI(
                                                        'PUT',
                                                        'orders/details',
                                                        undefined,
                                                        {
                                                            details: [{ id: row.id }]
                                                        },
                                                        { handleErrorInternally: true }
                                                    );
                                                    await showDetailsActionNotification(
                                                        this.props.intl,
                                                        'update_stage',
                                                        response.all,
                                                        response.success
                                                    );
                                                    if (activeKey === 'returnSurpluses') {
                                                        this.getOrdersForSurpluses();
                                                    } else {
                                                        this.fetchSparePartsWorkplaceData();
                                                    }
                                                }
                                            });
                                        }}
                                    >
                                        <RedoOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'orders.update_stage' })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item>
                                    <div
                                        onClick={async () => {
                                            if (activeKey === 'returnSurpluses') {
                                                await fetchAPI(
                                                    'PUT',
                                                    'orders/details',
                                                    undefined,
                                                    {
                                                        details: [
                                                            {
                                                                id: row.id,
                                                                isCriticalByTime: !row.isCriticalByTime
                                                            }
                                                        ]
                                                    },
                                                    { handleErrorInternally: true }
                                                );
                                                this.getOrdersForSurpluses();
                                            } else {
                                                this.updateSparePartsWorkplaceData([
                                                    {
                                                        id: row.id,
                                                        isCriticalByTime: !row.isCriticalByTime
                                                    }
                                                ]);
                                            }
                                        }}
                                    >
                                        <WarningOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({
                                            id: 'profile.spare_parts_workplace.critical'
                                        })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    disabled={
                                        row.status == 'GIVE_2_REPAIR' ||
                                        row.status == 'MOUNT' ||
                                        row.status == 'OK' ||
                                        row.status == 'BACK_2_STOCK' ||
                                        row.status == 'CANCEL' ||
                                        row.agreement == 'REJECTED' ||
                                        row.reservedCount > 0
                                    }
                                    onClick={() => {
                                        this.setState({
                                            detailModalVisibleMode: 1,
                                            detailModalSelectedRow: row
                                        });
                                    }}
                                >
                                    <EditOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'edit' })}
                                </Menu.Item>
                                <Menu.Item
                                    disabled={
                                        row.status == 'OK' ||
                                        row.status == 'READY' ||
                                        row.agreement == 'AGREED' ||
                                        row.agreement == 'REJECTED' ||
                                        row.reservedCount
                                    }
                                >
                                    <div
                                        onClick={() => {
                                            if (
                                                row.status == 'OK' ||
                                                row.status == 'READY' ||
                                                row.agreement == 'AGREED' ||
                                                row.agreement == 'REJECTED' ||
                                                row.reservedCount
                                            ) {
                                                return;
                                            }
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'add_order_form.delete_confirm'
                                                }),
                                                onOk: async () => {
                                                    await fetchAPI(
                                                        'DELETE',
                                                        'orders/details',
                                                        undefined,
                                                        { ids: [row.id] },
                                                        { handleErrorInternally: true }
                                                    );
                                                    if (activeKey === 'returnSurpluses') {
                                                        this.getOrdersForSurpluses();
                                                    } else {
                                                        this.fetchSparePartsWorkplaceData();
                                                    }
                                                }
                                            });
                                        }}
                                    >
                                        <DeleteOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'delete' })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    disabled={row.status == 'OK'}
                                    onClick={async () => {
                                        const payload = {
                                            details: [
                                                {
                                                    putAfterId: row.id,
                                                    storeGroupId: row.storeGroupId,
                                                    name: row.detailName,
                                                    productCode: row.detailCode,
                                                    supplierId: row.supplierId,
                                                    supplierBrandId: row.supplierBrandId,
                                                    purchasePrice: row.purchasePrice,
                                                    count: row.count,
                                                    price: row.price,
                                                    orderId: row.orderId,
                                                    agreement:
                                                        this.state.activeKey === 'storageOrders' ? 'AGREED' : undefined
                                                }
                                            ]
                                        };
                                        await fetchAPI('POST', 'orders/details', undefined, payload);
                                        if (activeKey === 'returnSurpluses') {
                                            this.getOrdersForSurpluses();
                                        } else {
                                            this.fetchSparePartsWorkplaceData();
                                        }
                                    }}
                                >
                                    <CopyOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.copy' })}
                                </Menu.Item>
                                <Menu.Item
                                    disabled={
                                        row.status == 'GIVE_2_REPAIR' ||
                                        row.status == 'MOUNT' ||
                                        row.status == 'OK' ||
                                        row.status == 'BACK_2_STOCK' ||
                                        row.status == 'CANCEL'
                                    }
                                    onClick={() => {
                                        this.setState({
                                            priceRecalculationModalSelectedRow: [row]
                                        });
                                    }}
                                >
                                    <PriceTagIcon className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.check' })}
                                </Menu.Item>
                                <Menu.Item
                                    disabled={
                                        row.status == 'ENTER_DATA' ||
                                        row.status == 'SEND' ||
                                        row.status == 'WAIT_DEL' ||
                                        row.status == 'INTAKE' ||
                                        row.status == 'RESERVE' ||
                                        row.status == 'READY' ||
                                        row.status == 'GIVE_2_REPAIR' ||
                                        row.status == 'MOUNT' ||
                                        row.status == 'OK' ||
                                        row.status == 'BACK_2_STOCK' ||
                                        row.status == 'BACK_2_SUP' ||
                                        row.status == 'CANCEL' ||
                                        activeKey === 'returnSurpluses' ||
                                        !row.detailCode.match(inputPattern) ||
                                        row.detailCode === null
                                    }
                                    onClick={async () => {
                                        await fetchAPI(
                                            'POST',
                                            'store_docs/order_all_possible',
                                            undefined,
                                            {
                                                ordersAppurtenanciesIds: [row.id]
                                            },
                                            { handleErrorInternally: true }
                                        );
                                        await notification.success({
                                            message: this.props.intl.formatMessage({
                                                id: 'details_table.ordered'
                                            })
                                        });
                                        this.fetchSparePartsWorkplaceData();
                                    }}
                                >
                                    <ShoppingOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.to_order' })}
                                </Menu.Item>
                                <Menu.Item
                                    disabled={
                                        row.status == 'ENTER_DATA' ||
                                        row.status == 'SEARCH' ||
                                        row.status == 'CHECK' ||
                                        row.status == 'ORDER' ||
                                        row.status == 'RESERVE' ||
                                        row.status == 'READY' ||
                                        row.status == 'GIVE_2_REPAIR' ||
                                        row.status == 'MOUNT' ||
                                        row.status == 'OK' ||
                                        row.status == 'BACK_2_STOCK' ||
                                        row.status == 'BACK_2_SUP' ||
                                        row.status == 'CANCEL' ||
                                        !row.detailCode.match(inputPattern) ||
                                        row.detailCode === null
                                    }
                                    onClick={async () => {
                                        await this.setState({
                                            visibleSuppliersIncomeModal: true,
                                            suppliersIncomeModalRow: row
                                        });
                                    }}
                                >
                                    <CheckOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.accept' })}
                                </Menu.Item>
                                <Menu.Item
                                    disabled={
                                        row.status == 'ENTER_DATA' ||
                                        row.status == 'SEARCH' ||
                                        row.status == 'CHECK' ||
                                        row.status == 'ORDER' ||
                                        row.status == 'SEND' ||
                                        row.status == 'WAIT_DEL' ||
                                        row.status == 'INTAKE' ||
                                        row.status == 'RESERVE' ||
                                        row.status == 'READY' ||
                                        row.status == 'GIVE_2_REPAIR' ||
                                        row.status == 'MOUNT' ||
                                        row.status == 'OK' ||
                                        row.status == 'BACK_2_SUP' ||
                                        row.status == 'CANCEL' ||
                                        activeKey === 'returnSurpluses'
                                    }
                                    onClick={async () => {
                                        const response = await fetchAPI(
                                            'POST',
                                            'store_docs/transfer_reserved_all_possible',
                                            undefined,
                                            {
                                                ordersAppurtenanciesIds: [row.id],
                                                toWarehouseId: this.props.warehouses.find(
                                                    ({ attribute }) => attribute == 'REPAIR_AREA'
                                                ).id
                                            },
                                            { handleErrorInternally: true }
                                        );
                                        if (response && response.error) {
                                            notification.error({
                                                message:
                                                    response.message || this.props.intl.formatMessage({ id: 'error' })
                                            });
                                        } else {
                                            notification.success({
                                                message: this.props.intl.formatMessage({
                                                    id: 'barcode.success'
                                                })
                                            });
                                        }
                                        if (activeKey === 'returnSurpluses') {
                                            this.getOrdersForSurpluses();
                                        } else {
                                            this.fetchSparePartsWorkplaceData();
                                        }
                                    }}
                                >
                                    <ArrowRightOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.give' })}
                                </Menu.Item>
                                <Menu.Item
                                    disabled={
                                        row.status == 'ENTER_DATA' ||
                                        row.status == 'SEARCH' ||
                                        row.status == 'CHECK' ||
                                        row.status == 'ORDER' ||
                                        row.status == 'SEND' ||
                                        row.status == 'WAIT_DEL' ||
                                        row.status == 'INTAKE' ||
                                        row.status == 'RESERVE' ||
                                        row.status == 'READY' ||
                                        row.status == 'GIVE_2_REPAIR' ||
                                        row.status == 'MOUNT' ||
                                        row.status == 'OK' ||
                                        row.status == 'BACK_2_SUP' ||
                                        row.status == 'CANCEL' ||
                                        activeKey === 'returnSurpluses'
                                    }
                                    onClick={async () => {
                                        const response = await fetchAPI(
                                            'POST',
                                            'store_docs/transfer_reserved_all_possible',
                                            undefined,
                                            {
                                                ordersAppurtenanciesIds: [row.id],
                                                toWarehouseId: this.props.warehouses.find(
                                                    ({ attribute }) => attribute == 'MAIN'
                                                ).id
                                            },
                                            { handleErrorInternally: true }
                                        );
                                        if (response && response.error) {
                                            await notification.error({
                                                message:
                                                    response.message || this.props.intl.formatMessage({ id: 'error' })
                                            });
                                        } else {
                                            await notification.success({
                                                message: this.props.intl.formatMessage({
                                                    id: 'barcode.success'
                                                })
                                            });
                                        }
                                        if (activeKey === 'returnSurpluses') {
                                            this.getOrdersForSurpluses();
                                        } else {
                                            this.fetchSparePartsWorkplaceData();
                                        }
                                    }}
                                >
                                    <ArrowLeftOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({
                                        id: 'profile.spare_parts_workplace.return_to_stock'
                                    })}
                                </Menu.Item>
                                {Boolean(_.get(row, 'variations.length') || row.leadIndex) && (
                                    <Menu.Item
                                        key='detailsActionMenu.ungroup'
                                        onClick={async () => {
                                            if (activeKey === 'storageOrders') {
                                                this.updateSparePartsWorkplaceData([
                                                    {
                                                        id: row.id,
                                                        groupName: null
                                                    }
                                                ]);
                                            } else if (activeKey === 'returnSurpluses') {
                                                await fetchAPI(
                                                    'PUT',
                                                    'orders/details',
                                                    undefined,
                                                    {
                                                        details: [
                                                            {
                                                                id: row.id,
                                                                groupName: null
                                                            }
                                                        ]
                                                    },
                                                    { handleErrorInternally: true }
                                                );
                                                this.getOrdersForSurpluses();
                                            }
                                        }}
                                    >
                                        <ForkOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'orders.ungroup' })}
                                    </Menu.Item>
                                )}
                            </Menu>
                        );

                        return (
                            <Space>
                                <HamburgerMenu actionsMenu={actionsMenu}>
                                    <Button data-qa='btn_menu_detail_table_order_page' icon={<MenuOutlined />} />
                                </HamburgerMenu>
                                {isWarehouse && (
                                    <Tooltip
                                        title={this.props.intl.formatMessage({
                                            id: 'profile.spare_parts_workplace.to_order'
                                        })}
                                    >
                                        <Button
                                            icon={<ShoppingOutlined />}
                                            onClick={async () => {
                                                await fetchAPI(
                                                    'POST',
                                                    'store_docs/order_all_possible',
                                                    undefined,
                                                    {
                                                        ordersAppurtenanciesIds: [row.id]
                                                    },
                                                    { handleErrorInternally: true }
                                                );
                                                await notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'details_table.ordered'
                                                    })
                                                });
                                                this.fetchSparePartsWorkplaceData();
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </Space>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='order' />,
                    key: 'order',
                    className: Styles.tableColumn,
                    ...this.getColumnSearchProps('filterByOrdNum'),
                    render: row => {
                        const { orderNum } = row;
                        const { activeKey } = this.state;
                        const trimmedOrderNum = orderNum.split('-')[0];

                        return (
                            <div>
                                <div
                                    style={{
                                        fontWeight: 700
                                    }}
                                >
                                    {trimmedOrderNum === 'MRD' ? (
                                        <Link to={`${book.order}/${row.orderId}`}>{row.orderNum}</Link>
                                    ) : (
                                        row.orderNum
                                    )}
                                    {activeKey === 'fromOrders' && (
                                        <Button
                                            icon={<MenuOutlined />}
                                            onClick={() => this.setState({ openDrawer: row.orderId })}
                                            type='text'
                                        />
                                    )}
                                </div>
                                <div style={{ fontSize: 12 }}>{dayjs(row.orderBeginDatetime).format('DD.MM.YY')}</div>
                            </div>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='order_form_table.detail_code' />,
                    key: 'code',
                    className: Styles.tableColumn,
                    ...this.getColumnSearchProps('filterByCode'),
                    render: row => {
                        return (
                            <div>
                                <div
                                    style={{
                                        fontWeight: 700,
                                        textDecoration: row.detailCode && 'underline'
                                    }}
                                >
                                    {row.productId ? (
                                        isGrantAccessed(
                                            props.user,
                                            grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD
                                        ) ? (
                                            <Link to={`${book.product}/${row.productId}`}>
                                                {row.foundString || row.detailCode}
                                            </Link>
                                        ) : (
                                            <div>{row.foundString || row.detailCode}</div>
                                        )
                                    ) : (
                                        <span
                                            onClick={() => {
                                                if (row.detailCode.match(inputPattern) && row.detailCode !== null) {
                                                    this.props.setModal(MODALS.STORE_PRODUCT, {
                                                        code: row.detailCode,
                                                        brandId: row.supplierBrandId,
                                                        brandName: row.brandName,
                                                        name: row.detailName,
                                                        groupId: row.storeGroupId,
                                                        onSubmit: (id, code) => {
                                                            if (this.state.activeKey === 'returnSurpluses') {
                                                                this.getOrdersForSurpluses();
                                                            } else {
                                                                this.fetchSparePartsWorkplaceData();
                                                            }
                                                        }
                                                    });
                                                }
                                            }}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {row.foundString || row.detailCode || <FormattedMessage id='long_dash' />}
                                        </span>
                                    )}
                                    {row.detailCode && (
                                        <Tooltip title={<FormattedMessage id='vehicle_page.hint_copy_to_clipboard' />}>
                                            <CopyOutlined
                                                onClick={() => {
                                                    navigator.clipboard.writeText(row.detailCode);
                                                    notification.success({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'barcode.success'
                                                        })
                                                    });
                                                }}
                                                style={{ marginLeft: 2, cursor: 'pointer' }}
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                                <div style={{ fontSize: 12 }}>{row.detailName}</div>
                            </div>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='order_form_table.brand' />,
                    key: 'brand',
                    dataIndex: 'brandName',
                    className: Styles.tableColumn,
                    ...this.getColumnSearchProps('filterByBrandName'),
                    render: (data, row) => {
                        return data ? (
                            <Tooltip title={<FormattedMessage id='details_table.catalog_modal_title' />}>
                                <div
                                    onClick={() => {
                                        if (!Number(row.reservedCount)) {
                                            this.setState({
                                                detailModalVisibleMode: 2,
                                                detailModalSelectedRow: row
                                            });
                                        }
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        textDecoration: data && 'underline'
                                    }}
                                >
                                    {data}
                                </div>
                            </Tooltip>
                        ) : (
                            <FormattedMessage id='long_dash' />
                        );
                    }
                },
                {
                    title: (
                        <span>
                            <FormattedMessage id='order_form_table.store_supplier_short' />
                        </span>
                    ),
                    key: 'supplierName',
                    className: Styles.tableColumn,
                    ...this.getColumnSearchProps('filterBySupplierId'),
                    render: row => {
                        return (
                            <div>
                                {!isWarehouse && (
                                    <React.Fragment>
                                        <Tooltip title={<FormattedMessage id='details_table.stock_availability' />}>
                                            <span
                                                onClick={() => {
                                                    if (!row.reservedCount && row.productId) {
                                                        this.setState({
                                                            warehousesModalSelectedRow: row
                                                        });
                                                    }
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {row.supplierId === 0 ? (
                                                    row.cellAddress ? (
                                                        row.cellAddress
                                                    ) : (
                                                        row.warehouseName || row.supplierName
                                                    )
                                                ) : (
                                                    <FormattedMessage id='long_dash' />
                                                )}
                                            </span>
                                        </Tooltip>
                                        {' / '}
                                    </React.Fragment>
                                )}
                                <Tooltip title={<FormattedMessage id='details_table.suppliers_availability' />}>
                                    <span
                                        onClick={() => {
                                            if (!row.reservedCount) {
                                                this.setState({
                                                    supplierModalSelectedRow: row
                                                });
                                            }
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {row.supplierId === 0 ? <FormattedMessage id='long_dash' /> : row.supplierName}
                                    </span>
                                </Tooltip>
                            </div>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='order_form_table.AI' />,
                    key: 'AI',
                    className: Styles.tableColumn,
                    render: row => {
                        return <AvailabilityIndicator indexArray={row.store} />;
                    }
                },
                {
                    title: <FormattedMessage id='order_form_table.purchasePrice' />,
                    align: 'right',
                    key: 'purchasePrice',
                    dataIndex: 'purchasePrice',
                    className: Styles.tableColumn,
                    ...this.getColumnSearchProps('filterByPurchasePrice'),
                    render: (data, row) => {
                        const strVal = Number(data).toFixed(2);

                        const discount = _.get(this.props, 'discount') || 0;
                        const marge =
                            row.price || row.purchasePrice ? ((row.price - row.purchasePrice) * 100) / row.price : 100;
                        const markup = row.price && row.purchasePrice ? (row.price / row.purchasePrice - 1) * 100 : 0;
                        const content = (
                            <div>
                                <div>
                                    <FormattedMessage id='order_form_table.marge' />: {marge.toFixed(0)}%
                                </div>
                                <div>
                                    <FormattedMessage id='order_form_table.markup' />: {markup.toFixed(0)}%
                                </div>
                                <div>
                                    <FormattedMessage id='order_form_table.discount' />: {discount.toFixed(0)}%
                                </div>
                            </div>
                        );

                        return (
                            <Tooltip title={content}>
                                <span style={{ cursor: 'pointer' }}>
                                    {data ? (
                                        `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                    ) : (
                                        <FormattedMessage id='long_dash' />
                                    )}
                                </span>
                            </Tooltip>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='order_form_table.price' />,
                    align: 'right',
                    key: 'price',
                    dataIndex: 'price',
                    className: Styles.tableColumn,
                    ...this.getColumnSearchProps('filterByPrice'),
                    render: (data, row) => {
                        const strVal = Number(data).toFixed(2);

                        const discount = _.get(this.props, 'discount') || 0;
                        const marge =
                            row.price || row.purchasePrice ? ((row.price - row.purchasePrice) * 100) / row.price : 100;
                        const markup = row.price && row.purchasePrice ? (row.price / row.purchasePrice - 1) * 100 : 0;
                        const content = (
                            <div>
                                <div>
                                    <FormattedMessage id='order_form_table.marge' />: {marge.toFixed(0)}%
                                </div>
                                <div>
                                    <FormattedMessage id='order_form_table.markup' />: {markup.toFixed(0)}%
                                </div>
                                <div>
                                    <FormattedMessage id='order_form_table.discount' />: {discount.toFixed(0)}%
                                </div>
                            </div>
                        );

                        return (
                            <Popover content={content} trigger='hover'>
                                <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                                    {data ? (
                                        `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                    ) : (
                                        <FormattedMessage id='long_dash' />
                                    )}
                                </span>
                            </Popover>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='order_form_table.count' />,
                    align: 'right',
                    key: 'count',
                    dataIndex: 'count',
                    className: Styles.tableColumn,
                    ...this.getColumnSearchProps('filterByCount'),
                    render: (data, row) => {
                        const content = (
                            <div>
                                <div>
                                    <FormattedMessage id='logs.count' />: {(row.count || 0).toFixed(0)}
                                </div>
                                <div>
                                    <FormattedMessage id='storage.min' />: {(row.min || 0).toFixed(0)}
                                </div>
                                <div>
                                    <FormattedMessage id='storage.max' />: {(row.max || 0).toFixed(0)}
                                </div>
                                <div>
                                    <FormattedMessage id='storage.available' />:{' '}
                                    {(row.remaining - row.reserved).toFixed(0)}
                                </div>
                                <div>
                                    <FormattedMessage id='storage.ordered' />:{' '}
                                    {row.countInStoreOrders && row.countInStoreOrders.toFixed(0)}
                                </div>
                            </div>
                        );

                        return (
                            <Tooltip title={content}>
                                <span>{data ? numeralFormatter4Digits(data) : 0}</span>
                            </Tooltip>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='services_table.units' />,
                    align: 'right',
                    key: 'measureUnit',
                    className: Styles.numberColumn,
                    dataIndex: 'measureUnit',
                    render: data => {
                        return <span>{data || undefined}</span>;
                    }
                },
                {
                    title: <FormattedMessage id='storage.RESERVE' />,
                    align: 'right',
                    key: 'reserve',
                    className: Styles.tableColumn,
                    ...this.getColumnSearchProps(['filterByReserve']),
                    render: row => {
                        const disabled =
                            (row.stage == 'INSTALLED' && row.agreement != 'REJECTED') ||
                            isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_STOCK);

                        return (
                            <ReserveButton
                                brands={this.props.brands}
                                detail={row}
                                disabled={
                                    disabled ||
                                    this.state.activeKey === 'returnSurpluses' ||
                                    row.detailCode === null ||
                                    !row.detailCode.match(inputPattern) ||
                                    row.supplierBrandId === null
                                }
                                updateDetail={() => {
                                    this.fetchSparePartsWorkplaceData();
                                }}
                            />
                        );
                    }
                },
                {
                    title: <FormattedMessage id='order_form_table.sum' />,
                    align: 'right',
                    key: 'sum',
                    dataIndex: 'sum',
                    className: Styles.tableColumn,
                    ...this.getColumnSearchProps('filterBySum'),
                    render: (data, row) => {
                        const strVal = data && Number(data).toFixed(2);

                        const discount = _.get(this.props, 'discount') || 0;
                        const marge =
                            row.price || row.purchasePrice ? ((row.price - row.purchasePrice) * 100) / row.price : 100;
                        const markup = row.price && row.purchasePrice ? (row.price / row.purchasePrice - 1) * 100 : 0;
                        const content = (
                            <div>
                                <div>
                                    <FormattedMessage id='order_form_table.marge' />: {marge.toFixed(0)}%
                                </div>
                                <div>
                                    <FormattedMessage id='order_form_table.markup' />: {markup.toFixed(0)}%
                                </div>
                                <div>
                                    <FormattedMessage id='order_form_table.discount' />: {discount.toFixed(0)}%
                                </div>
                            </div>
                        );

                        return (
                            <Popover content={content} trigger='hover'>
                                <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                                    {data ? (
                                        `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                    ) : (
                                        <FormattedMessage id='long_dash' />
                                    )}
                                </span>
                            </Popover>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='order_form_table.PD' />,
                    key: 'agreement',
                    dataIndex: 'agreement',
                    align: 'center',
                    className: Styles.tableColumn,
                    ...this.getColumnSearchProps('filterByAgreement'),

                    render: (data, row) => {
                        let color = null;
                        let icon = <QuestionCircleOutlined />;
                        switch (data) {
                            case 'REJECTED':
                                color = 'rgb(255, 126, 126)';
                                icon = <CloseCircleOutlined style={{ fontSize: 22, color: 'rgb(255, 126, 126)' }} />;
                                break;
                            case 'AGREED':
                                color = 'var(--green)';
                                icon = <CheckCircleOutlined style={{ fontSize: 22, color: 'var(--green)' }} />;
                                break;
                            default:
                                color = null;
                                icon = <QuestionCircleOutlined style={{ fontSize: 22 }} />;
                        }
                        const updateAgreement = async value => {
                            if (this.state.activeKey === 'returnSurpluses') {
                                await fetchAPI(
                                    'PUT',
                                    'orders/details',
                                    undefined,
                                    {
                                        details: [{ id: row.id, agreement: value }]
                                    },
                                    { handleErrorInternally: true }
                                );
                                this.getOrdersForSurpluses();
                            } else {
                                this.props.updateSparePartsWorkplaceData([
                                    {
                                        id: row.id,
                                        agreement: value
                                    }
                                ]);
                            }
                        };
                        const menu = (
                            <Menu>
                                <Menu.Item
                                    key='undefined'
                                    onClick={() => {
                                        updateAgreement('UNDEFINED');
                                    }}
                                >
                                    <QuestionCircleOutlined
                                        style={{
                                            fontSize: 18,
                                            verticalAlign: 'sub',
                                            marginRight: 8
                                        }}
                                    />
                                    <FormattedMessage id='agreement.undefined' />
                                </Menu.Item>
                                <Menu.Item
                                    key='agreed'
                                    onClick={() => {
                                        updateAgreement('AGREED');
                                    }}
                                    style={{ color: 'var(--green)' }}
                                >
                                    <CheckCircleOutlined
                                        style={{
                                            fontSize: 18,
                                            verticalAlign: 'sub',
                                            marginRight: 8,
                                            color: 'var(--green)'
                                        }}
                                    />
                                    <FormattedMessage id='agreement.agreed' />
                                </Menu.Item>
                                <Menu.Item
                                    key='rejected'
                                    onClick={() => {
                                        updateAgreement('REJECTED');
                                    }}
                                    style={{ color: 'rgb(255, 126, 126)' }}
                                >
                                    <CloseCircleOutlined
                                        style={{
                                            fontSize: 18,
                                            marginRight: 8
                                        }}
                                    />
                                    <FormattedMessage id='agreement.rejected' />
                                </Menu.Item>
                            </Menu>
                        );

                        return !isGrantAccessed(
                            this.props.user,
                            grants.OPERATIONS_ORDER_DOCUMENT_PARTS_REPAIR_ORDER_STATUS_CHANGE,
                            accesses.ROWO
                        ) ? (
                            <span
                                style={{
                                    fontSize: 24,
                                    color
                                }}
                            >
                                {icon}
                            </span>
                        ) : (
                            <div>
                                <Dropdown
                                    overlay={menu}
                                    style={{
                                        fontSize: 24,
                                        color
                                    }}
                                    trigger={['click']}
                                >
                                    {icon}
                                </Dropdown>
                            </div>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='order_form_table.status' />,
                    key: 'status',
                    dataIndex: 'status',
                    align: 'center',
                    className: Styles.tableColumn,
                    ...this.getColumnSearchProps('filterByStatus'),
                    render: (data, row) => {
                        const { statuses } = this.state;
                        const currentStatus = (statuses || []).find(({ status }) => status == data);

                        const updateAgreement = async value => {
                            if (
                                statuses.findIndex(({ status }) => status == data) >
                                statuses.findIndex(({ status }) => status == value)
                            ) {
                                confirm({
                                    title: this.props.intl.formatMessage({
                                        id: 'profile.spare_parts_workplace.downgrade_status'
                                    }),
                                    content: (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-around'
                                            }}
                                        >
                                            <div>
                                                <Button
                                                    onClick={() => {
                                                        Modal.destroyAll();
                                                    }}
                                                >
                                                    {this.props.intl.formatMessage({
                                                        id: 'cancel'
                                                    })}
                                                </Button>
                                            </div>
                                            <div>
                                                <Button
                                                    onClick={async () => {
                                                        if (this.state.activeKey === 'returnSurpluses') {
                                                            await fetchAPI(
                                                                'PUT',
                                                                'orders/details',
                                                                undefined,
                                                                {
                                                                    details: [{ id: row.id, status: value }]
                                                                },
                                                                { handleErrorInternally: true }
                                                            );
                                                            await this.getOrdersForSurpluses();
                                                        } else {
                                                            await this.props.updateSparePartsWorkplaceData([
                                                                {
                                                                    id: row.id,
                                                                    status: value
                                                                }
                                                            ]);
                                                        }
                                                        Modal.destroyAll();
                                                    }}
                                                    type='primary'
                                                >
                                                    {this.props.intl.formatMessage({
                                                        id: 'general_settings.submit'
                                                    })}
                                                </Button>
                                            </div>
                                        </div>
                                    ),
                                    footer: null
                                });
                            } else if (this.state.activeKey === 'returnSurpluses') {
                                await fetchAPI(
                                    'PUT',
                                    'orders/details',
                                    undefined,
                                    {
                                        details: [{ id: row.id, status: value }]
                                    },
                                    { handleErrorInternally: true }
                                );
                                this.getOrdersForSurpluses();
                            } else {
                                await this.props.updateSparePartsWorkplaceData([
                                    {
                                        id: row.id,
                                        status: value
                                    }
                                ]);
                            }
                        };
                        const menu = (
                            <Menu>
                                {statuses.map(({ status, customStatusName }) => (
                                    <Menu.Item
                                        key={status}
                                        onClick={() => {
                                            updateAgreement(status);
                                        }}
                                    >
                                        {customStatusName || <FormattedMessage id={`status.${status}`} />}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        );

                        return (
                            <Dropdown overlay={menu} trigger={['click']}>
                                <Tooltip title={<FormattedMessage id={`status.${data}.title`} />}>
                                    <div
                                        style={{
                                            border: `2px solid ${row.statusColor}`,
                                            padding: '6px 2px',
                                            textAlign: 'center',
                                            fontWeight: 500,
                                            borderRadius: 6
                                        }}
                                    >
                                        {currentStatus && currentStatus.customStatusName ? (
                                            currentStatus.customStatusName
                                        ) : (
                                            <FormattedMessage id={`status.${data}`} />
                                        )}
                                    </div>
                                </Tooltip>
                            </Dropdown>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='time' />,
                    key: 'time',
                    dataIndex: 'time',
                    className: Styles.tableColumn,
                    // ...this.getColumnSearchProps(['time']),
                    render: (data, row) => {
                        const hours = Math.floor(data / 3600);
                        const minutes = Math.round(Math.abs((data % 3600) / 60));

                        let background;
                        let color;
                        if (this.state.hoveredTimeRowId == row.id || row.isCriticalByTime) {
                            background = 'var(--disabled)';
                            color = 'white';
                        } else if (hours >= 2) {
                            background = 'var(--db_progress)';
                        } else if (hours >= 1) {
                            background = 'var(--lightGray)';
                        } else if (hours >= 0) {
                            background = 'var(--db_approve)';
                        } else {
                            background = 'var(--approve)';
                        }

                        return (
                            <Popconfirm
                                onConfirm={async () => {
                                    if (this.state.activeKey === 'returnSurpluses') {
                                        await fetchAPI(
                                            'PUT',
                                            'orders/details',
                                            undefined,
                                            {
                                                details: [
                                                    {
                                                        id: row.id,
                                                        isCriticalByTime: !row.isCriticalByTime
                                                    }
                                                ]
                                            },
                                            { handleErrorInternally: true }
                                        );
                                        this.getOrdersForSurpluses();
                                    } else {
                                        this.props.updateSparePartsWorkplaceData([
                                            {
                                                id: row.id,
                                                isCriticalByTime: !row.isCriticalByTime
                                            }
                                        ]);
                                    }
                                }}
                                title={
                                    !row.isCriticalByTime ? (
                                        <FormattedMessage id='profile.spare_parts_workplace.mark_as_critical' />
                                    ) : (
                                        <FormattedMessage id='profile.spare_parts_workplace.unmark_as_critical' />
                                    )
                                }
                            >
                                <div
                                    style={{
                                        color,
                                        background,
                                        padding: '6px 8px',
                                        textAlign: 'center',
                                        fontWeight: 500,
                                        cursor: 'pointer',
                                        borderRadius: 6
                                    }}
                                    // onMouseEnter={()=>{
                                    //     this.setState({
                                    //         hoveredTimeRowId: row.id,
                                    //     })
                                    // }}
                                    // onMouseLeave={()=>{
                                    //     this.setState({
                                    //         hoveredTimeRowId: undefined,
                                    //     })
                                    // }}
                                >
                                    {this.state.hoveredTimeRowId == row.id || row.isCriticalByTime
                                        ? '!!!'
                                        : `${hours}:${minutes}`}
                                </div>
                            </Popconfirm>
                        );
                    }
                }
            ];
        };

        // Grouped columns are used in the 'grid' display type
        this.groupedColumns = [
            { key: 'name', dataIndex: 'name' },
            { key: 'count', render: row => row.childs.length }
        ];
    }

    /**
     * Lifecycle: after updates
     */
    async componentDidUpdate(prevProps) {
        const { sparePartsData } = this.props;
        const { loading } = this.state;

        if (sparePartsData.details.length && loading) {
            this.setState({ loading: false });
        }

        if (prevProps.sparePartsData.details !== sparePartsData.details) {
            const details = sparePartsData.details
                .filter(row => Number(row.count) === Number(row.reservedCount) && row.supplierId !== 0)
                .map(elem => this.parseDetailData({ ...elem, supplierId: 0, orderId: elem.orderId }));
            if (details.length) {
                const data = {
                    updateMode: true,
                    details
                };
                await fetchAPI('PUT', 'orders/details', null, data, {
                    handleErrorInternally: true
                });
                this.fetchSparePartsWorkplaceData();
            }
        }
    }

    /**
     * Lifecycle: on mount
     */
    componentDidMount = async () => {
        const { location, changeSparePartsWorkplaceTab, user } = this.props;

        // Handle optional location.state parameters
        if (location.state?.searchValue) {
            this.changeSparePartsWorkplaceDataFilters({
                filterByCode: location.state.searchValue.replace(/[+()]/g, '')
            });
        }

        if (location.state?.specificTab) {
            this.setState({ activeKey: location.state.specificTab });
            changeSparePartsWorkplaceTab(location.state.specificTab);
        }

        // Initialize default filters
        this.changeSparePartsWorkplaceDataFilters({
            fromBeginDate: dayjs().add(-30, 'days').format('YYYY-MM-DD'),
            toBeginDate: dayjs().add(30, 'days').format('YYYY-MM-DD'),
            orderBy: 'ORDER'
        });
        this.changeOrdersSurplusesDataFilters({
            fromBeginDate: dayjs().add(-30, 'days').format('YYYY-MM-DD'),
            toBeginDate: dayjs().add(30, 'days').format('YYYY-MM-DD'),
            orderBy: 'ORDER'
        });

        // Load display preference
        const displayType = localStorage.getItem('_my.carbook.spare_parts_displayType') || 'list';

        // Fetch needed data
        const allDetails = await fetchAPI('GET', 'store_groups', { keepFlat: true });
        this.props.fetchBrands();
        this.props.fetchWarehouses();
        this.fetchSuppliersOptions();

        let statuses = [];
        if (isGrantAccessed(user, grants.SETTINGS_PART_STATUSES)) {
            statuses = await fetchAPI('GET', 'status_settings');
        }

        const storeGroups = await fetchAPI('GET', 'store_groups');
        const detailsTreeData = buildStoreGroupsTree(storeGroups);

        // Get surplus orders
        this.getOrdersForSurpluses();

        // Safety fallback if still loading
        setTimeout(() => {
            if (this.state.loading) this.setState({ loading: false });
        }, 2000);

        this.setState({
            detailsTreeData,
            allDetails,
            statuses,
            displayType
        });
    };

    /**
     * Asynchronous fetch of measurement units
     */
    fetchUnits = async () => {
        const units = await fetchAPI('GET', 'business/measurement/units');
        this.setState({ units });
    };

    parseDetailData = detail => {
        return detail.productId
            ? {
                  id: detail.id,
                  parentId: detail.parentId || undefined,
                  storeGroupId: detail.storeGroupId,
                  name: detail.name || detail.detailName,
                  productId: detail.storeId || detail.productId,
                  productCode: detail.detailCode || detail.productCode || null,
                  purchasePrice: detail.purchasePrice || 0,
                  count: detail.count ? detail.count : 1,
                  price: detail.price ? detail.price : 0,
                  supplierBrandId: detail.supplierBrandId || detail.brandId,
                  supplierId: detail.supplierId,
                  supplierOriginalCode: detail.supplierOriginalCode,
                  supplierProductNumber: detail.supplierProductNumber,
                  supplierPartNumber: detail.supplierPartNumber,
                  cellAddress: detail.cellAddress || null,
                  warehouseId: detail.warehouseId,
                  partUnitId: detail.partUnitId || detail.productUnitId,
                  oeCode: detail.oeCode || null,
                  orderId: detail.orderId,
                  partRowDiscount: detail.partRowDiscount || 0,
                  comment: detail.comment || {
                      comment: undefined,
                      positions: []
                  }
              }
            : {
                  id: detail.id,
                  parentId: detail.parentId || undefined,
                  storeGroupId: detail.storeGroupId,
                  name: detail.name || detail.detailName,
                  productCode: detail.detailCode || detail.productCode || null,
                  supplierId: detail.supplierId,
                  supplierBrandId: detail.supplierBrandId || detail.brandId || null,
                  supplierOriginalCode: detail.supplierOriginalCode,
                  supplierProductNumber: detail.supplierProductNumber,
                  supplierPartNumber: detail.supplierPartNumber,
                  purchasePrice: detail.purchasePrice || 0,
                  count: detail.count,
                  orderId: detail.orderId,
                  price: detail.price ? detail.price : 0,
                  partUnitId: detail.partUnitId || detail.productUnitId,
                  oeCode: detail.oeCode || null,
                  partRowDiscount: detail.partRowDiscount || 0,
                  comment: detail.comment || {
                      comment: undefined,
                      positions: []
                  }
              };
    };

    /**
     * Called by search: fetch and display parts in labor (commented logic but kept for reference)
     */
    getPartsInLabor = async () => {
        // const {
        //     filterByOrdNum,
        //     filterByCode,
        //     filterByBrandName,
        //     filterBySupplierId,
        //     filterByPrice,
        //     filterByPurchasePrice,
        //     filterByCount,
        //     filterBySum,
        //     filterByAgreement,
        //     filterByStatus
        // } = this.state;
        // await fetchAPI(
        //     'GET',
        //     'orders/details',
        //     {
        //         page: this.props.filters.page,
        //         pageSize: this.props.filters.pageSize,
        //         filterByOrdNum,
        //         filterByCode,
        //         filterByBrandName,
        //         filterBySupplierId,
        //         filterByPrice,
        //         filterByPurchasePrice,
        //         filterByCount,
        //         filterBySum,
        //         filterByAgreement: filterByAgreement || null,
        //         filterByStatus
        //     },
        //     null,
        //     { handleErrorInternally: true }
        // );
    };

    /**
     * Provide filter props based on dataIndex
     * This dynamically generates the filter UI for the Table column
     */
    getColumnSearchProps = dataIndex => {
        const { intl } = this.props;
        const placeholder = intl.formatMessage({
            id: dataIndex === 'filterByBarcode' ? 'receipt_document_modal.bar_qr_code' : 'search'
        });

        /**
         * Default filter component
         */
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.setState({ [dataIndex]: e.target.value });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={placeholder}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.state[dataIndex]}
            />
        );

        /**
         * filterByCode => additional barcode input
         */
        if (dataIndex === 'filterByCode') {
            filterComponent = (confirm, clearFilters) => (
                <React.Fragment>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        onChange={e => this.setState({ [dataIndex]: e.target.value })}
                        onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                        placeholder={placeholder}
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={this.state[dataIndex]}
                    />
                    <Input
                        onChange={e => {
                            this.setState({ filterByBarcode: e.target.value });
                        }}
                        onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                        placeholder={intl.formatMessage({
                            id: 'receipt_document_modal.bar_qr_code'
                        })}
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={this.state.filterByBarcode}
                    />
                </React.Fragment>
            );
        }

        /**
         * filterByStatus => statuses menu
         */
        if (dataIndex === 'filterByStatus') {
            filterComponent = confirm => (
                <Menu>
                    {this.state.statuses.map(({ status }) => (
                        <Menu.Item
                            key={status}
                            onClick={async () => {
                                await this.setState({ [dataIndex]: status });
                                this.handleSearch(confirm, dataIndex);
                            }}
                        >
                            <FormattedMessage id={`status.${status}`} />
                        </Menu.Item>
                    ))}
                </Menu>
            );
        }

        /**
         * filterByAgreement => menu with states
         */
        if (dataIndex === 'filterByAgreement') {
            filterComponent = confirm => (
                <Menu>
                    <Menu.Item
                        key='undefined'
                        onClick={async () => {
                            await this.setState({ [dataIndex]: 'UNDEFINED' });
                            this.handleSearch(confirm, dataIndex);
                        }}
                    >
                        <QuestionCircleOutlined style={{ fontSize: 18, marginRight: 8 }} />
                        <FormattedMessage id='agreement.undefined' />
                    </Menu.Item>
                    <Menu.Item
                        key='agreed'
                        onClick={async () => {
                            await this.setState({ [dataIndex]: 'AGREED' });
                            this.handleSearch(confirm, dataIndex);
                        }}
                        style={{ color: 'var(--green)' }}
                    >
                        <CheckCircleOutlined
                            style={{
                                fontSize: 18,
                                verticalAlign: 'sub',
                                marginRight: 8,
                                color: 'var(--green)'
                            }}
                        />
                        <FormattedMessage id='agreement.agreed' />
                    </Menu.Item>
                    <Menu.Item
                        key='rejected'
                        onClick={async () => {
                            await this.setState({ [dataIndex]: 'REJECTED' });
                            this.handleSearch(confirm, dataIndex);
                        }}
                        style={{ color: 'rgb(255, 126, 126)' }}
                    >
                        <CloseCircleOutlined style={{ fontSize: 18, marginRight: 8 }} />
                        <FormattedMessage id='agreement.rejected' />
                    </Menu.Item>
                </Menu>
            );
        }

        /**
         * filterBySupplierId => select of supplier name
         */
        if (dataIndex === 'filterBySupplierId') {
            const { suppliers } = this.props.sparePartsData;
            const uniqueOptions = (suppliers || []).filter(
                (elem, index, self) => index === self.findIndex(t => t.supplierName === elem.supplierName)
            );

            filterComponent = confirm => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({ [dataIndex]: value });
                    }}
                    optionFilterProp='children'
                    placeholder={this.props.intl.formatMessage({ id: 'search' })}
                    showSearch
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                    value={this.state[dataIndex]}
                >
                    {uniqueOptions
                        .filter(elem => elem.supplierName !== null)
                        .map(supplier => (
                            <Option key={supplier.supplierId} value={supplier.supplierId}>
                                {`${supplier.supplierName}`}
                            </Option>
                        ))}
                </Select>
            );
        }

        /**
         * filterByReserve => numeric input
         */
        if (dataIndex === 'filterByReserve') {
            filterComponent = confirm => (
                <InputNumber
                    allowClear
                    onChange={value => {
                        this.setState({ [dataIndex]: value });
                    }}
                    placeholder={this.props.intl.formatMessage({ id: 'search' })}
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                />
            );
        }

        /**
         * filterByCount => numeric input
         */
        if (dataIndex === 'filterByCount') {
            filterComponent = confirm => (
                <InputNumber
                    allowClear
                    decimalSeparator=','
                    onChange={value => {
                        this.setState({ [dataIndex]: value });
                    }}
                    placeholder={this.props.intl.formatMessage({ id: 'search' })}
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                />
            );
        }

        /**
         * filterByBrandName => brand name select
         */
        if (dataIndex === 'filterByBrandName') {
            filterComponent = confirm => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({ [dataIndex]: value });
                    }}
                    optionFilterProp='children'
                    placeholder={this.props.intl.formatMessage({ id: 'search' })}
                    showSearch
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                    value={this.state[dataIndex]}
                >
                    {this.props.brands
                        .filter(({ brandName }) => brandName)
                        .map(({ brandId, brandName }) => (
                            <Option key={brandId} value={brandName}>
                                {brandName}
                            </Option>
                        ))}
                </Select>
            );
        }

        /**
         * Return final column filter definition
         */
        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    {dataIndex !== 'dateRange' &&
                        dataIndex !== 'filterCreatedDate' &&
                        dataIndex !== 'filtertDoneDate' && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    marginTop: 4
                                }}
                            >
                                <Button
                                    icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                    onClick={() => this.handleSearch(confirm, dataIndex)}
                                    size='small'
                                    type='primary'
                                >
                                    <FormattedMessage id='search' />
                                </Button>
                                <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                    <FormattedMessage id='reset' />
                                </Button>
                            </div>
                        )}
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color:
                            this.state[dataIndex] || (dataIndex === 'filterByCode' && this.state.filterByBarcode)
                                ? 'var(--primary)'
                                : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput?.select(), 100);
                }
            }
        };
    };

    /**
     * Handle search in getColumnSearchProps
     */
    handleSearch = (confirm, dataIndex) => {
        confirm();
        this.changeSparePartsWorkplaceDataFilters({
            [dataIndex]: this.state[dataIndex],
            ...(dataIndex === 'filterByCode' ? { filterByBarcode: this.state.filterByBarcode } : {})
        });
    };

    /**
     * Handle reset in getColumnSearchProps
     */
    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({
            [dataIndex]: null,
            ...(dataIndex === 'filterByCode' ? { filterByBarcode: null } : {})
        });
        this.changeSparePartsWorkplaceDataFilters({
            [dataIndex]: undefined,
            ...(dataIndex === 'filterByCode' ? { filterByBarcode: null } : {})
        });
    };

    /**
     * Change filters for SparePartsWorkplace
     */
    changeSparePartsWorkplaceDataFilters = data => {
        const { activeKey } = this.state;
        this.setState({ selectedRowKeys: [], selectedRows: [] });

        if (activeKey === 'fromOrders') {
            this.props.changeSparePartsWorkplaceDataFilters(data);
        } else if (activeKey === 'storageOrders') {
            this.props.changeSparePartsWorkplaceStorageDataFilters(data);
            this.fetchSparePartsWorkplaceData();
        }
    };

    /**
     * Change filters for Orders Surpluses
     */
    changeOrdersSurplusesDataFilters = data => {
        this.setState({ selectedRowKeys: [], selectedRows: [] });
        this.props.changeOrdersSurplusesDataFilters(data);
        this.getOrdersForSurpluses();
    };

    /**
     * Update SparePartsWorkplace Data
     */
    updateSparePartsWorkplaceData = data => {
        this.setState({ selectedRowKeys: [], selectedRows: [] });
        this.props.updateSparePartsWorkplaceData(data);
    };

    /**
     * Update Orders Surpluses
     */
    updateOrdersSurpluses = async details => {
        this.setState({ selectedRowKeys: [], selectedRows: [] });
        await fetchAPI('PUT', 'orders/details', undefined, { details }, { handleErrorInternally: true });
        this.getOrdersForSurpluses();
    };

    /**
     * Fetch Spare Parts Workplace Data
     */
    fetchSparePartsWorkplaceData = () => {
        this.setState({ selectedRowKeys: [], selectedRows: [] });
        this.props.fetchSparePartsWorkplaceData(this.state.activeKey);
    };

    /**
     * Get Orders for Surpluses
     */
    getOrdersForSurpluses = async () => {
        const { page, pageSize } = this.state;
        const ordersSurpluses = await fetchAPI(
            'GET',
            'orders/details',
            {
                orderStatuses: '["not_completed_supplier_expense"]',
                page: page || 1,
                pageSize: pageSize || 25
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            detailsSurpluses: ordersSurpluses.details,
            count: ordersSurpluses?.statistics?.totalCount
        });
    };

    /**
     * Group details by the specified field
     */
    groupDetailsByField = (details, field) => {
        const grouped = [];
        details.forEach(detail => {
            let fieldValue;
            let fieldName;

            switch (field) {
                case 'ORDER':
                    fieldValue = detail.orderId;
                    fieldName = detail.orderNum;
                    break;
                case 'SUPPLIER':
                    fieldValue = detail.supplierId;
                    fieldName = detail.supplierName;
                    break;
                case 'TIME':
                    fieldValue = dayjs(detail.orderBeginDatetime).format('DD-MM-YYYY');
                    fieldName = dayjs(detail.orderBeginDatetime).format('DD-MM-YYYY');
                    break;
                case 'STATUSES':
                    fieldValue = detail.status;
                    fieldName = this.props.intl.formatMessage({ id: `status.${detail.status}` });
                    break;
                default:
                    break;
            }

            const index = grouped.findIndex(group => group.id === fieldValue);
            if (index === -1) {
                grouped.push({
                    key: grouped.length,
                    id: fieldValue,
                    name: fieldName,
                    childs: [detail]
                });
            } else {
                grouped[index].childs.push(detail);
            }
        });

        return grouped;
    };

    /**
     * Fetch suppliers options
     */
    fetchSuppliersOptions = async (query, all = true) => {
        const suppliers = await fetchAPI('GET', 'business_suppliers', { showHidden: false, query, all }, null, {
            handleErrorInternally: true
        });
        this.setState({ suppliers });
    };

    /**
     * Debounce for supplier search
     */
    handleSearchSuppliers = _.debounce(value => {
        this.fetchSuppliersOptions(value, true);
    }, 1000);

    /**
     * Render method
     */
    render() {
        const {
            modal,
            sparePartsData,
            ordersToStorageData,
            filters,
            changeSparePartsWorkplaceTab,
            brands,
            resetModal,
            location,
            user,
            intl: { formatMessage }
        } = this.props;
        const { details, statistics } = sparePartsData;
        const { orderBy, filterBy, fromBeginDate, toBeginDate } = filters;

        // State destructuring
        const {
            selectedRowKeys,
            selectedRows,
            detailModalVisibleMode,
            detailModalSelectedRow,
            storageModalSelectedRow,
            supplierModalSelectedRow,
            reserveModalData,
            warehousesModalSelectedRow,
            priceRecalculationModalSelectedRow,
            loading,
            supplierId,
            setSupplierModalVisible,
            detailsTreeData,
            allDetails,
            activeKey,
            displayType,
            visibleSuppliersIncomeModal,
            suppliersIncomeModalRow,
            visibleReturnSurplusesModal,
            detailsSurpluses,
            units,
            disableSparePartsWorkPlace,
            suppliers,
            page,
            pageSize,
            count,
            openDrawer
        } = this.state;

        const isWarehouse = activeKey === 'storageOrders';

        // Calculate some aggregated values
        let filtredDetailsCount = 0;
        let filtredDetailsSum = 0;
        let selectedRowsDetailsSum = 0;
        let selectedRowsDetailsCount = 0;

        selectedRows.forEach(({ purchasePrice, count }) => {
            selectedRowsDetailsSum += purchasePrice * count;
            selectedRowsDetailsCount += count;
        });
        details.forEach(({ purchasePrice, count }) => {
            filtredDetailsSum += purchasePrice * count;
            filtredDetailsCount += count;
        });

        // Group details by `orderBy` parameter
        const groupedDetails = this.groupDetailsByField(details, orderBy);

        /**
         * Group-by menu
         */
        const menu = (
            <Menu>
                <Menu.Item disabled={orderBy === 'ORDER'}>
                    <div
                        onClick={() => {
                            if (activeKey === 'storageOrders' || activeKey === 'fromOrders') {
                                this.changeSparePartsWorkplaceDataFilters({ orderBy: 'ORDER' });
                            } else {
                                this.changeOrdersSurplusesDataFilters({ orderBy: 'ORDER' });
                            }
                        }}
                    >
                        <FormattedMessage id='profile.spare_parts_workplace.group_by_orders' />
                    </div>
                </Menu.Item>
                <Menu.Item disabled={orderBy === 'SUPPLIER'}>
                    <div
                        onClick={() => {
                            if (activeKey === 'storageOrders' || activeKey === 'fromOrders') {
                                this.changeSparePartsWorkplaceDataFilters({ orderBy: 'SUPPLIER' });
                            } else {
                                this.changeOrdersSurplusesDataFilters({ orderBy: 'SUPPLIER' });
                            }
                        }}
                    >
                        <FormattedMessage id='profile.spare_parts_workplace.group_by_suppliers' />
                    </div>
                </Menu.Item>
                <Menu.Item disabled={orderBy === 'TIME'}>
                    <div
                        onClick={() => {
                            if (activeKey === 'storageOrders' || activeKey === 'fromOrders') {
                                this.changeSparePartsWorkplaceDataFilters({ orderBy: 'TIME' });
                            } else {
                                this.changeOrdersSurplusesDataFilters({ orderBy: 'TIME' });
                            }
                        }}
                    >
                        <FormattedMessage id='profile.spare_parts_workplace.group_by_time' />
                    </div>
                </Menu.Item>
                <Menu.Item disabled={orderBy === 'STATUSES'}>
                    <div
                        onClick={() => {
                            if (activeKey === 'storageOrders' || activeKey === 'fromOrders') {
                                this.changeSparePartsWorkplaceDataFilters({ orderBy: 'STATUSES' });
                            } else {
                                this.changeOrdersSurplusesDataFilters({ orderBy: 'STATUSES' });
                            }
                        }}
                    >
                        <FormattedMessage id='profile.spare_parts_workplace.group_by_status' />
                    </div>
                </Menu.Item>
            </Menu>
        );

        /**
         * Row selection config
         */
        const rowSelection = {
            selectedRowKeys,
            hideDefaultSelections: true,
            onChange: (keys, rows) => {
                this.setState({
                    selectedRowKeys: keys,
                    selectedRows: rows
                });
            },
            getCheckboxProps: record => ({
                disabled: !record.id
            })
        };

        /**
         * Expanded row render for grouped table
         */
        const expandedRowRender = record => (
            <div style={{ margin: '4px 0 0 -4px' }}>
                <Table
                    bordered
                    columns={this.columns()}
                    dataSource={record.childs}
                    expandable={{
                        expandedRowRender: childRecord => (
                            <Table
                                bordered
                                columns={this.columns()}
                                dataSource={childRecord.variations}
                                pagination={false}
                                rowSelection={{ getCheckboxProps: () => ({ disabled: true }) }}
                                showHeader={false}
                                size='small'
                            />
                        ),
                        rowExpandable: ({ variations }) => variations && variations.length
                    }}
                    loading={loading}
                    pagination={false}
                    rowClassName={Styles.detailsTableRow}
                    rowKey='id'
                    rowSelection={rowSelection}
                    size='small'
                />
            </div>
        );

        /**
         * Pagination for surpluses
         */
        const pagination = {
            pageSize,
            size: 'large',
            total: Math.ceil(count / pageSize) * pageSize,
            hideOnSinglePage: true,
            current: page,
            onChange: async (newPage, newPageSize) => {
                await this.setState({ page: newPage, pageSize: newPageSize });
                // this.getOrdersForSurpluses(); (uncomment if needed)
            }
        };

        // If there's a specificTab from location
        const specificTab = location?.state?.specificTab || 'fromOrders';

        return (
            <Layout
                controls={
                    <React.Fragment>
                        {activeKey === 'fromOrders' && (
                            <Radio.Group
                                buttonStyle='solid'
                                onChange={event => {
                                    this.setState({ displayType: event.target.value });
                                    localStorage.setItem('_my.carbook.spare_parts_displayType', event.target.value);
                                }}
                                style={{ marginRight: 8 }}
                                value={displayType}
                            >
                                <Radio.Button value='list'>
                                    <UnorderedListOutlined style={{ fontSize: 18, verticalAlign: 'middle' }} />
                                </Radio.Button>
                                <Radio.Button value='grid'>
                                    <GridIcon style={{ fontSize: 18, verticalAlign: 'middle' }} />
                                </Radio.Button>
                            </Radio.Group>
                        )}

                        {/* Icon: Storage Orders Hint */}
                        <Tooltip
                            title={<FormattedMessage id='spare_parts_workplace.hint_storage_orders' />}
                            zIndex={2001}
                        >
                            {activeKey !== 'fromOrders' &&
                                activeKey !== 'returnSurpluses' &&
                                !isForbidden(user, permissions.ACCESS_ORDER_DETAILS) &&
                                !isForbidden(user, permissions.SHOW_ORDERS) && (
                                    <CheckCircleOutlined
                                        className={Styles.menuIcon}
                                        onClick={async () => {
                                            await fetchAPI('POST', 'orders_ord');
                                            await this.fetchSparePartsWorkplaceData();
                                            await notification.success({
                                                message: formatMessage({ id: 'barcode.success' })
                                            });
                                        }}
                                        style={{ marginRight: 8, color: 'var(--green)' }}
                                    />
                                )}
                        </Tooltip>

                        {/* Icon: Storage Surplus Hint */}
                        <Tooltip title={<FormattedMessage id='spare_parts_workplace.hint_storage_surplus' />}>
                            {activeKey === 'returnSurpluses' &&
                                !isForbidden(user, permissions.ACCESS_ORDER_DETAILS) &&
                                !isForbidden(user, permissions.SHOW_ORDERS) && (
                                    <CheckCircleOutlined
                                        className={Styles.menuIcon}
                                        disabled
                                        onClick={async () => this.setState({ visibleReturnSurplusesModal: true })}
                                        style={{ marginRight: 8, color: 'var(--green)' }}
                                    />
                                )}
                        </Tooltip>

                        {/* Date Range Picker */}
                        <DateRangePicker
                            dateRange={[dayjs(fromBeginDate), dayjs(toBeginDate)]}
                            minimize
                            onDateChange={async ([startDate, endDate]) => {
                                if (activeKey === 'fromOrders' || activeKey === 'storageOrders') {
                                    this.changeSparePartsWorkplaceDataFilters({
                                        fromBeginDate: startDate.format('YYYY-MM-DD'),
                                        toBeginDate: endDate.format('YYYY-MM-DD')
                                    });
                                } else {
                                    this.changeOrdersSurplusesDataFilters({
                                        fromBeginDate: startDate.format('YYYY-MM-DD'),
                                        toBeginDate: endDate.format('YYYY-MM-DD')
                                    });
                                }
                            }}
                            style={{ float: 'right', margin: '0px 6px 0 0' }}
                        />

                        {/* Group-By Dropdown */}
                        <Dropdown overlay={menu}>
                            <SortAscendingOutlined className={Styles.menuIcon} />
                        </Dropdown>
                    </React.Fragment>
                }
                title={<FormattedMessage id='profile.spare_parts_workplace.title' />}
            >
                <Catcher>
                    <Tabs
                        activeKey={activeKey}
                        defaultActiveKey={specificTab}
                        onChange={key => {
                            this.setState({
                                activeKey: key,
                                selectedRowKeys: [],
                                selectedRows: [],
                                filterByBrandName: undefined,
                                filterByCode: undefined,
                                filterByBarcode: undefined,
                                filterByPurchasePrice: undefined,
                                filterByCount: undefined,
                                filterByReserve: undefined,
                                filterBySum: undefined,
                                filterBySupplierId: undefined
                            });
                            changeSparePartsWorkplaceTab(key);
                        }}
                    >
                        {isGrantAccessed(user, grants.OPERATIONS_JOB_DETAILS_ORDER_PARTS) && (
                            <TabPane key='fromOrders' tab={formatMessage({ id: 'spare_parts_workplace.from_orders' })}>
                                {/* FROM ORDERS TAB */}
                                <div
                                    style={{
                                        padding: '18px 0 6px 6px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-end'
                                    }}
                                >
                                    {/* Left block: filtered vs total counts */}
                                    <div>
                                        <div style={{ padding: '0 0 8px' }}>
                                            <FormattedMessage
                                                id='spare_parts_workplace.filtred_elements'
                                                values={{
                                                    filtredRowsCount: String(details.length).replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ' '
                                                    ),
                                                    totalRowsCount: String(statistics.totalCount).replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ' '
                                                    ),
                                                    filtredDetailsCount: filtredDetailsCount
                                                        .toFixed(1)
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
                                                    filtredDetailsSum: filtredDetailsSum
                                                        .toFixed(2)
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                                }}
                                            />
                                        </div>
                                        <div style={{ cursor: 'pointer', display: 'inline-block' }}>
                                            <FormattedMessage
                                                id='spare_parts_workplace.elements_selected'
                                                values={{
                                                    selectedRowsCount: String(selectedRows.length).replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ' '
                                                    ),
                                                    filtredRowsCount: String(details.length).replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ' '
                                                    ),
                                                    selectedRowsDetailsCount: selectedRowsDetailsCount
                                                        .toFixed(1)
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
                                                    selectedRowsDetailsSum: selectedRowsDetailsSum
                                                        .toFixed(2)
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/* Right block: priorities */}
                                    <div>
                                        <div>
                                            <FormattedMessage id='spare_parts_workplace.priorities' />
                                        </div>
                                        <div className={Styles.statsBlock}>
                                            <Tooltip
                                                title={<FormattedMessage id='profile.spare_parts_workplace.critical' />}
                                                zIndex={2001}
                                            >
                                                <div
                                                    onClick={() => {
                                                        if (
                                                            activeKey === 'storageOrders' ||
                                                            activeKey === 'fromOrders'
                                                        ) {
                                                            this.props.changeSparePartsWorkplaceDataFilters({
                                                                filterBy: 'CRITICAL'
                                                            });
                                                        } else {
                                                            this.changeOrdersSurplusesDataFilters({
                                                                filterBy: 'CRITICAL'
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {statistics.critical || 0}
                                                </div>
                                            </Tooltip>

                                            <Tooltip
                                                title={<FormattedMessage id='profile.spare_parts_workplace.new' />}
                                                zIndex={2001}
                                            >
                                                <div
                                                    onClick={() => {
                                                        if (
                                                            activeKey === 'storageOrders' ||
                                                            activeKey === 'fromOrders'
                                                        ) {
                                                            this.props.changeSparePartsWorkplaceDataFilters({
                                                                filterBy: 'NEW'
                                                            });
                                                        } else {
                                                            this.changeOrdersSurplusesDataFilters({ filterBy: 'NEW' });
                                                        }
                                                    }}
                                                >
                                                    {statistics.new || 0}
                                                </div>
                                            </Tooltip>

                                            <Tooltip
                                                title={<FormattedMessage id='profile.spare_parts_workplace.overdue' />}
                                                zIndex={2001}
                                            >
                                                <div
                                                    onClick={() => {
                                                        if (
                                                            activeKey === 'storageOrders' ||
                                                            activeKey === 'fromOrders'
                                                        ) {
                                                            this.props.changeSparePartsWorkplaceDataFilters({
                                                                filterBy: 'OVERDUE'
                                                            });
                                                        } else {
                                                            this.changeOrdersSurplusesDataFilters({
                                                                filterBy: 'OVERDUE'
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {statistics.overdue || 0}
                                                </div>
                                            </Tooltip>

                                            <Tooltip
                                                title={<FormattedMessage id='profile.spare_parts_workplace.ending' />}
                                                zIndex={2001}
                                            >
                                                <div
                                                    onClick={() => {
                                                        if (
                                                            activeKey === 'storageOrders' ||
                                                            activeKey === 'fromOrders'
                                                        ) {
                                                            this.props.changeSparePartsWorkplaceDataFilters({
                                                                filterBy: 'ENDING'
                                                            });
                                                        } else {
                                                            this.changeOrdersSurplusesDataFilters({
                                                                filterBy: 'ENDING'
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {statistics.ending || 0}
                                                </div>
                                            </Tooltip>

                                            <Tooltip
                                                title={<FormattedMessage id='spare_parts_workplace.clear_filters' />}
                                                zIndex={2001}
                                            >
                                                <div
                                                    onClick={() => {
                                                        if (
                                                            activeKey === 'storageOrders' ||
                                                            activeKey === 'fromOrders'
                                                        ) {
                                                            this.props.changeSparePartsWorkplaceDataFilters({
                                                                filterBy: undefined
                                                            });
                                                        } else {
                                                            this.changeOrdersSurplusesDataFilters({
                                                                filterBy: undefined
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <CloseCircleOutlined />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>

                                {/* Table: List or Grid display */}
                                {displayType === 'list' ? (
                                    <Table
                                        bordered
                                        className={Styles.detailsTable}
                                        columns={this.columns()}
                                        dataSource={details}
                                        expandable={{
                                            expandedRowRender: record => (
                                                <Table
                                                    bordered
                                                    columns={this.columns()}
                                                    dataSource={record.variations}
                                                    pagination={false}
                                                    rowSelection={{
                                                        getCheckboxProps: () => ({ disabled: true })
                                                    }}
                                                    showHeader={false}
                                                    size='small'
                                                />
                                            ),
                                            rowExpandable: ({ variations }) => variations && variations.length
                                        }}
                                        loading={loading}
                                        pagination={{
                                            pageSize: filters.pageSize,
                                            showSizeChanger: true,
                                            total:
                                                Math.ceil(_.get(statistics, 'totalCount', 0) / filters.pageSize) *
                                                filters.pageSize,
                                            current: filters.page,
                                            onChange: async (page, pageSize) => {
                                                await this.props.changeSparePartsWorkplaceDataFilters({
                                                    page,
                                                    pageSize
                                                });
                                            }
                                        }}
                                        rowClassName={Styles.detailsTableRow}
                                        rowKey='id'
                                        rowSelection={rowSelection}
                                        size='small'
                                    />
                                ) : (
                                    <Table
                                        bordered
                                        className={Styles.detailsTable}
                                        columns={this.groupedColumns}
                                        dataSource={groupedDetails}
                                        expandedRowRender={expandedRowRender}
                                        loading={loading}
                                        pagination={{
                                            pageSize: filters.pageSize,
                                            showSizeChanger: true,
                                            total:
                                                Math.ceil(_.get(statistics, 'totalCount', 0) / filters.pageSize) *
                                                filters.pageSize,
                                            current: filters.page,
                                            onChange: async (page, pageSize) => {
                                                await this.props.changeSparePartsWorkplaceDataFilters({
                                                    page,
                                                    pageSize
                                                });
                                            }
                                        }}
                                        rowKey='id'
                                    />
                                )}
                            </TabPane>
                        )}

                        {isGrantAccessed(user, grants.OPERATIONS_JOB_DETAILS_WAREHOUSE_ORDER) && (
                            <TabPane
                                key='storageOrders'
                                tab={formatMessage({ id: 'spare_parts_workplace.storage_orders' })}
                            >
                                <Table
                                    bordered
                                    className={Styles.detailsTable}
                                    columns={this.columns().filter(({ key }) => !warehouseSkipColumns.includes(key))}
                                    dataSource={ordersToStorageData.details}
                                    loading={loading}
                                    pagination={{
                                        pageSize: this.props.storageFilters.pageSize,
                                        showSizeChanger: true,
                                        total:
                                            Math.ceil(
                                                _.get(ordersToStorageData.statistics, 'totalCount', 0) /
                                                    this.props.storageFilters.pageSize
                                            ) * this.props.storageFilters.pageSize,
                                        current: this.props.storageFilters.page,
                                        onChange: async (page, pageSize) => {
                                            await this.props.changeSparePartsWorkplaceStorageDataFilters({
                                                page,
                                                pageSize
                                            });
                                            this.fetchSparePartsWorkplaceData();
                                        }
                                    }}
                                    rowClassName={Styles.detailsTableRow}
                                    rowKey='id'
                                    rowSelection={rowSelection}
                                    scroll={{ x: 1200 }}
                                    size='small'
                                />
                            </TabPane>
                        )}

                        {isGrantAccessed(user, grants.OPERATIONS_JOB_DETAILS_RETURN_OF_SURPLUS) && (
                            <TabPane
                                key='returnSurpluses'
                                tab={formatMessage({ id: 'spare_parts_workplace.return_surpluses' })}
                            >
                                <Table
                                    bordered
                                    className={Styles.detailsTable}
                                    columns={this.columns()}
                                    dataSource={detailsSurpluses}
                                    loading={loading}
                                    pagination={pagination}
                                    rowClassName={Styles.detailsTableRow}
                                    rowKey='id'
                                    rowSelection={rowSelection}
                                    scroll={{ x: 1200 }}
                                    size='small'
                                />
                            </TabPane>
                        )}
                    </Tabs>

                    {/* Modals and Overlays */}
                    <OrderDetailModal
                        allDetails={allDetails}
                        brands={brands}
                        clientVehicleVin={_.get(detailModalSelectedRow, 'vehicleVin')}
                        disableSparePartsWorkPlace={disableSparePartsWorkPlace}
                        handleSearchSuppliers={this.handleSearchSuppliers}
                        hideModal={() =>
                            this.setState({
                                detailModalVisibleMode: 0,
                                detailModalSelectedRow: {}
                            })
                        }
                        isWarehouseOrder={isWarehouse}
                        modificationId={_.get(detailModalSelectedRow, 'tecdocId')}
                        onFinish={detail => {
                            if (activeKey === 'storageOrders' || activeKey === 'fromOrders') {
                                this.updateSparePartsWorkplaceData([
                                    {
                                        id: detailModalSelectedRow.id,
                                        storeGroupId: detail.storeGroupId,
                                        name: detail.detailName,
                                        productCode: detail.detailCode,
                                        supplierId: detail.supplierId,
                                        supplierBrandId: detail.supplierBrandId || detail.brandId,
                                        supplierOriginalCode: detail.supplierOriginalCode,
                                        supplierProductNumber: detail.supplierProductNumber,
                                        supplierPartNumber: detail.supplierPartNumber,
                                        purchasePrice: (detail.purchasePrice * 10) / 10 || 0,
                                        count: detail.count || 1,
                                        price: detail.price ? (detail.price * 10) / 10 : 1,
                                        comment: detail.comment || { comment: undefined, positions: [] }
                                    }
                                ]);
                            } else {
                                this.updateOrdersSurpluses([
                                    {
                                        id: detailModalSelectedRow.id,
                                        storeGroupId: detail.storeGroupId,
                                        name: detail.detailName,
                                        productCode: detail.detailCode,
                                        supplierId: detail.supplierId,
                                        supplierBrandId: detail.supplierBrandId || detail.brandId,
                                        supplierOriginalCode: detail.supplierOriginalCode,
                                        supplierProductNumber: detail.supplierProductNumber,
                                        supplierPartNumber: detail.supplierPartNumber,
                                        purchasePrice: (detail.purchasePrice * 10) / 10 || 0,
                                        count: detail.count || 1,
                                        price: detail.price ? (detail.price * 10) / 10 : 1,
                                        comment: detail.comment || { comment: undefined, positions: [] }
                                    }
                                ]);
                            }
                        }}
                        orderId={_.get(detailModalSelectedRow, 'orderId')}
                        product={detailModalSelectedRow}
                        resetModal={resetModal}
                        suppliers={suppliers}
                        treeData={detailsTreeData}
                        units={units}
                        updateDataSource={this.updateDataSource}
                        visible={detailModalVisibleMode}
                    />

                    <DetailSupplierModal
                        brandId={_.get(supplierModalSelectedRow, 'supplierBrandId')}
                        detailCode={_.get(supplierModalSelectedRow, 'detailCode')}
                        hideButton
                        hideModal={() => this.setState({ supplierModalSelectedRow: undefined })}
                        onSelect={({
                            businessSupplierId,
                            purchasePrice,
                            id,
                            price,
                            supplierOriginalCode,
                            supplierPartNumber
                        }) => {
                            if (activeKey === 'storageOrders' || activeKey === 'fromOrders') {
                                this.updateSparePartsWorkplaceData([
                                    {
                                        id: supplierModalSelectedRow.id,
                                        supplierId: businessSupplierId,
                                        purchasePrice,
                                        price,
                                        supplierOriginalCode,
                                        supplierPartNumber
                                    }
                                ]);
                            } else {
                                this.updateOrdersSurpluses([
                                    {
                                        id: supplierModalSelectedRow.id,
                                        supplierId: businessSupplierId,
                                        purchasePrice,
                                        price,
                                        supplierOriginalCode,
                                        supplierPartNumber
                                    }
                                ]);
                            }
                        }}
                        storeGroupId={_.get(supplierModalSelectedRow, 'storeGroupId')}
                        user={user}
                        visible={Boolean(supplierModalSelectedRow)}
                    />

                    <VinCodeModal
                        detailsTreeData={detailsTreeData}
                        disabled={
                            isForbidden(user, permissions.ACCESS_ORDER_DETAILS_VIN) ||
                            isForbidden(user, permissions.ACCESS_ORDER_DETAILS_CRUD)
                        }
                        modal={modal}
                        resetModal={resetModal}
                        vin={_.get(detailModalSelectedRow, 'vehicleVin')}
                    />

                    <StoreProductModal />

                    <DetailStorageModal
                        codeFilter={_.get(storageModalSelectedRow, 'detailCode')}
                        codeSearch
                        hideButton
                        hideModal={() => this.setState({ storageModalSelectedRow: undefined })}
                        onSelect={(...args) => {
                            if (activeKey === 'storageOrders') {
                                this.updateSparePartsWorkplaceData([
                                    {
                                        id: storageModalSelectedRow.id,
                                        productCode: args[0],
                                        supplierBrandId: args[1],
                                        name: args[5],
                                        supplierOriginalCode: args[6],
                                        supplierPartNumber: args[8]
                                    }
                                ]);
                            } else if (activeKey === 'fromOrders') {
                                this.updateSparePartsWorkplaceData([
                                    {
                                        id: storageModalSelectedRow.id,
                                        productCode: args[0],
                                        supplierBrandId: args[1],
                                        name: args[5],
                                        supplierOriginalCode: args[6],
                                        supplierPartNumber: args[8]
                                    }
                                ]);
                            } else {
                                this.updateOrdersSurpluses([
                                    {
                                        id: storageModalSelectedRow.id,
                                        productCode: args[0],
                                        supplierBrandId: args[1],
                                        name: args[5],
                                        supplierOriginalCode: args[6],
                                        supplierPartNumber: args[8]
                                    }
                                ]);
                            }
                        }}
                        setSupplier={(...args) => {
                            if (activeKey === 'storageOrders') {
                                this.updateSparePartsWorkplaceData([
                                    {
                                        id: storageModalSelectedRow.id,
                                        supplierId: args[0],
                                        purchasePrice: args[3],
                                        price: args[4],
                                        supplierOriginalCode: args[6],
                                        supplierPartNumber: args[8]
                                    }
                                ]);
                            } else if (activeKey === 'fromOrders') {
                                this.updateSparePartsWorkplaceData([
                                    {
                                        id: storageModalSelectedRow.id,
                                        supplierId: args[0],
                                        purchasePrice: args[3],
                                        price: args[4],
                                        supplierOriginalCode: args[6],
                                        supplierPartNumber: args[8]
                                    }
                                ]);
                            } else {
                                this.updateOrdersSurpluses([
                                    {
                                        id: storageModalSelectedRow.id,
                                        supplierId: args[0],
                                        purchasePrice: args[3],
                                        price: args[4],
                                        supplierOriginalCode: args[6],
                                        supplierPartNumber: args[8]
                                    }
                                ]);
                            }
                        }}
                        stockMode={false}
                        storeGroupId={_.get(storageModalSelectedRow, 'storeGroupId')}
                        user={user}
                        visible={Boolean(storageModalSelectedRow)}
                    />

                    <StoreProductTrackingModal
                        hideModal={() => this.setState({ reserveModalData: undefined })}
                        productId={reserveModalData}
                        visible={Boolean(reserveModalData)}
                    />

                    <DetailWarehousesCountModal
                        count={_.get(warehousesModalSelectedRow, 'count')}
                        hideButton
                        hideModal={() => this.setState({ warehousesModalSelectedRow: undefined })}
                        onSelect={(address, warehouseId) => {
                            if (activeKey === 'storageOrders' || activeKey === 'fromOrders') {
                                this.updateSparePartsWorkplaceData([
                                    {
                                        id: warehousesModalSelectedRow.id,
                                        cellAddress: address,
                                        warehouseId,
                                        supplierId: 0
                                    }
                                ]);
                            } else {
                                this.updateOrdersSurpluses([
                                    {
                                        id: warehousesModalSelectedRow.id,
                                        cellAddress: address,
                                        warehouseId,
                                        supplierId: 0
                                    }
                                ]);
                            }
                        }}
                        orderId={_.get(warehousesModalSelectedRow, 'orderId')}
                        productId={_.get(warehousesModalSelectedRow, 'productId')}
                        productUnit={_.get(warehousesModalSelectedRow, 'measureUnit')}
                        visible={Boolean(warehousesModalSelectedRow)}
                    />

                    <UpdatePriceModal
                        handleClose={() => this.setState({ priceRecalculationModalSelectedRow: undefined })}
                        handleOk={() => {
                            activeKey === 'fromOrders'
                                ? this.fetchSparePartsWorkplaceData()
                                : this.getOrdersForSurpluses();
                        }}
                        hideSellingPrice={activeKey === 'storageOrders'}
                        open={priceRecalculationModalSelectedRow}
                        ordersAppurtenanciesIds={
                            priceRecalculationModalSelectedRow?.id
                                ? [priceRecalculationModalSelectedRow.id]
                                : selectedRows.map(({ id }) => id)
                        }
                    />

                    <SuppliersIncomeModal
                        hideModal={() =>
                            this.setState({
                                visibleSuppliersIncomeModal: false,
                                suppliersIncomeModalRow: undefined
                            })
                        }
                        row={suppliersIncomeModalRow}
                        selectedRows={selectedRows}
                        updateDataSource={() => {
                            activeKey === 'storageOrders' || activeKey === 'fromOrders'
                                ? this.fetchSparePartsWorkplaceData()
                                : this.getOrdersForSurpluses();
                        }}
                        visible={visibleSuppliersIncomeModal}
                    />

                    <Modal
                        maskClosable={false}
                        onCancel={() => this.setState({ setSupplierModalVisible: false })}
                        onOk={() => {
                            if (activeKey === 'storageOrders' || activeKey === 'fromOrders') {
                                this.updateSparePartsWorkplaceData(
                                    selectedRows
                                        .filter(
                                            ({ reservedCount, status, agreement }) =>
                                                status !== 'OK' &&
                                                status !== 'READY' &&
                                                agreement !== 'AGREED' &&
                                                agreement !== 'REJECTED' &&
                                                !reservedCount
                                        )
                                        .map(({ id }) => ({ id, supplierId }))
                                );
                            } else {
                                this.updateOrdersSurpluses(selectedRows.map(({ id }) => ({ id, supplierId })));
                            }
                            this.setState({
                                supplierId: undefined,
                                setSupplierModalVisible: false
                            });
                        }}
                        title={<FormattedMessage id='order_form_table.supplier' />}
                        visible={setSupplierModalVisible}
                    >
                        <div style={{ padding: 8, background: 'white' }}>
                            <Select
                                dropdownStyle={{ zIndex: 9999 }}
                                onChange={value => {
                                    this.setState({ supplierId: value });
                                }}
                                onSearch={this.fetchSuppliersOptions}
                                optionFilterProp='children'
                                placeholder={formatMessage({ id: 'order_form_table.supplier' })}
                                showSearch
                                style={{ minWidth: 180 }}
                                value={supplierId}
                            >
                                {suppliers.map(({ name, id }) => (
                                    <Option key={id} value={id}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </Modal>
                </Catcher>

                {/* Return Surpluses Modal */}
                <AddRowsReturnSurplusesModal
                    detailsTreeData={detailsTreeData}
                    getOrdersForSurpluses={this.getOrdersForSurpluses}
                    hideModal={() => this.setState({ visibleReturnSurplusesModal: false })}
                    visible={visibleReturnSurplusesModal}
                />

                {/* Drawer */}
                <OrderDrawerContainer onClose={() => this.setState({ openDrawer: undefined })} open={openDrawer} />
            </Layout>
        );
    }
}
