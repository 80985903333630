import {
    CaretDownOutlined,
    CaretUpOutlined,
    DownloadOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    MailOutlined,
    MessageOutlined,
    PrinterOutlined
} from '@ant-design/icons';
import { Button, Popconfirm, Popover } from 'antd';
import { Numeral } from 'commons';
import { FormattedDatetime } from 'components';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { isForbidden, permissions } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

// Width of each column
const defWidth = {
    numberCol: 'auto',
    cashOrderCol: '6%',
    dateCol: '8%',
    conterpartyCol: '8%',
    orderCol: '8%',
    activityCol: '8%',
    sumCol: '5%',
    analyticsCol: '15%',
    descriptionCol: '8%',
    rstCodeCol: '5%',
    actionsCol: 'auto',

    orderAndConterpartyCol: 'auto'
};

function renderCounterparty(cashOrder) {
    switch (true) {
        case Boolean(cashOrder.clientId):
            return (
                <Link className={Styles.breakWord} to={`${book.client}/${cashOrder.clientId}`}>
                    {cashOrder.clientName} {cashOrder.clientSurname}
                </Link>
            );

        case Boolean(cashOrder.employeeId):
            return (
                <Link className={Styles.breakWord} to={`${book.employeesPage}/${cashOrder.employeeId}`}>
                    {cashOrder.employeeName} {cashOrder.employeeSurname}
                </Link>
            );

        case Boolean(cashOrder.businessSupplierId):
            return (
                <Link className={Styles.breakWord} to={`${book.supplier}/${cashOrder.businessSupplierId}`}>
                    {cashOrder.businessSupplierName}
                </Link>
            );

        case Boolean(cashOrder.otherCounterparty):
            return <div className={Styles.breakWord}>{cashOrder.otherCounterparty}</div>;

        default:
            return <FormattedMessage id='no_data' />;
    }
}

/* eslint-disable complexity */
export function columnsConfig(props) {
    const {
        handlePrintReceiptOpen,
        onRepeatRegistrationInCashdesk,
        openPrint,
        openEdit,
        onSendEmail,
        onSendSms,
        downloadReceipt,
        isMobile,
        user,
        getColumnSearchProps,
        getColumnDaterangeProps,
        getColumnTypeProps
    } = props;
    const numberCol = {
        title: <FormattedMessage id='cash-table.cashbox_num' />,
        dataIndex: 'cashBoxId',
        render: (cashBoxId, { cashBoxName }) => (
            <div className={Styles.breakWord}>
                <div>{cashBoxName}</div>
                <div> {`#${cashBoxId}`}</div>
            </div>
        ),
        ...getColumnSearchProps('cashBoxName')
    };

    const cashOrderCol = {
        title: <FormattedMessage id='cash-table.order_num' />,
        dataIndex: 'id'
        // ...getColumnSearchProps('id')
    };

    const dateCol = {
        title: <FormattedMessage id='cash-table.date' />,
        dataIndex: 'datetime',
        render: date => (
            <div style={{ wordBreak: 'keep-all' }}>
                <FormattedDatetime datetime={date} format='DD.MM.YY' />
            </div>
        ),
        ...getColumnDaterangeProps('datetime')
    };

    const conterpartyCol = {
        title: <FormattedMessage id='cash-table.conterparty' />,
        render: (key, cashOrder) => renderCounterparty(cashOrder),
        ...getColumnSearchProps('counterPartName')
    };

    const orderCol = {
        title: <FormattedMessage id='cash-table.order' />,
        render: ({ orderId, storeDocId, orderNum, documentNumber, salaryDocId }) => {
            if (salaryDocId) {
                return (
                    <Link style={{ color: 'var(--link)' }} to={`${book.salariesPage}/${salaryDocId}`}>
                        {`SAL-${user.businessId}-${salaryDocId}`}
                    </Link>
                );
            }

            return orderId && orderNum && !orderNum.startsWith('RTL') && !orderNum.startsWith('RTN') ? (
                <Link style={{ color: 'var(--link' }} to={`${book.order}/${orderId}`}>
                    {orderNum}
                </Link>
            ) : orderNum && orderNum.startsWith('RTL') ? (
                <Link style={{ color: 'var(--link' }} to={`${book.rtl}/${orderId}`}>
                    {orderNum}
                </Link>
            ) : orderNum && orderNum.startsWith('RTN') ? (
                <Link style={{ color: 'var(--link' }} to={`${book.rtn}/${orderId}`}>
                    {orderNum}
                </Link>
            ) : isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT) ? (
                <Link style={{ color: 'var(--link' }} to={`${book.storageDocument}/${storeDocId}`}>
                    {documentNumber}
                </Link>
            ) : (
                documentNumber
            );
        },
        ...getColumnSearchProps(['orderId'])
    };

    const activityCol = {
        title: <FormattedMessage id='cash-table.activity' />,
        dataIndex: 'type',
        ...getColumnTypeProps('type'),
        render: type => (
            <div className={Styles.noBreak}>
                <FormattedMessage id={`cash-order-form.type.${type}`} />
            </div>
        )
    };

    const sumCol = {
        title: <FormattedMessage id='cash-table.sum' />,
        dataIndex: 'sum',
        render: (key, { increase, decrease }) =>
            increase ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }}
                >
                    + <Numeral mask='0,0.00'>{increase}</Numeral>
                    <CaretUpOutlined style={{ color: 'var(--enabled)' }} />
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }}
                >
                    - <Numeral mask='0,0.00'>{decrease}</Numeral>
                    <CaretDownOutlined style={{ color: 'var(--disabled)' }} />
                </div>
            ),
        ...getColumnSearchProps('increase')
    };

    const analyticsCol = {
        title: <FormattedMessage id='cash-table.analytics' />,
        dataIndex: 'analyticsName',
        ...getColumnSearchProps('analyticsName')
    };

    const descriptionCol = {
        title: <FormattedMessage id='cash-table.comment' />,
        dataIndex: 'description',
        ...getColumnSearchProps('description')
    };

    /** RST is a special device, it has fiscal code */
    const rstCodeCol = {
        title: 'PPO',
        dataIndex: 'fiscalNumber',
        ...getColumnSearchProps('fiscalNumber')
    };

    const actionsCol = {
        key: 'actions',
        align: 'center',
        render: (key, cashOrder) => {
            const rstActionsAccess = !isForbidden(user, permissions.ACCESS_OTHER_OPERATION_RST);

            /** Creates an icon with styles and popup.
             * @param popMessage - popup hint when hovered
             * @param options - Icon options (type, className ...)
             */
            // const iconWithPop = ({ popMessage, options }) => {
            //     return (
            //         <Popover content={popMessage}>
            //             <LegacyIcon {...options} />
            //         </Popover>
            //     );
            // };

            /** When cashOrder was successfully registered in cashdesk api service those are available */
            const cashOrderWithRST = (
                <span>
                    <Popover content={<FormattedMessage id='cash-table.hint_send_sms' />}>
                        <Button
                            onClick={() => onSendSms({ cashOrderId: cashOrder.id })}
                            style={{
                                padding: '0px 8px',
                                fontSize: 18
                            }}
                        >
                            <MessageOutlined
                                className={_.join([Styles.sendSMS, rstActionsAccess ? '' : Styles.disabled], ' ')}
                            />
                        </Button>
                    </Popover>
                    {/* { iconWithPop({
                        popMessage: (<FormattedMessage id='cash-table.hint_send_sms' />),
                        options:    {
                            type:      'message',
                            className: _.join([ Styles.sendSMS, rstActionsAccess? '': Styles.disabled ], ' '), //Disable access if no scope
                            onClick:   () => onSendSms({cashOrderId: cashOrder.id}),
                        },
                    }) } */}
                    <Popover content={<FormattedMessage id='cash-table.hint_send_email' />}>
                        <Button
                            onClick={() => onSendEmail({ cashOrderId: cashOrder.id })}
                            style={{
                                padding: '0px 8px',
                                fontSize: 18
                            }}
                        >
                            <MailOutlined
                                className={_.join([Styles.sendMailIcon, rstActionsAccess ? '' : Styles.disabled], ' ')}
                            />
                        </Button>
                    </Popover>
                    {/* { iconWithPop({
                        popMessage: (<FormattedMessage id='cash-table.hint_send_email' />),
                        options:    {
                            type:      'mail',
                            className: _.join([ Styles.sendMailIcon, rstActionsAccess? '': Styles.disabled ], ' '), //Disable access if no scope
                            onClick:   () => onSendEmail({cashOrderId: cashOrder.id}),
                        },
                    }) } */}
                    <Popover content={<FormattedMessage id='cash-table.hint_download_receipt' />}>
                        {/* <Button
                            onClick={() => downloadReceipt({ cashOrderId: cashOrder.id })}
                            style={{
                                padding: '0px 8px',
                                fontSize: 18
                            }}
                        >
                            <DownloadOutlined
                                className={_.join([Styles.downloadIcon, rstActionsAccess ? '' : Styles.disabled], ' ')}
                            />
                        </Button> */}
                        <Button
                            onClick={() => handlePrintReceiptOpen(cashOrder)}
                            style={{
                                padding: '0px 8px',
                                fontSize: 18
                            }}
                        >
                            <DownloadOutlined
                                className={_.join([Styles.downloadIcon, rstActionsAccess ? '' : Styles.disabled], ' ')}
                            />
                        </Button>
                    </Popover>
                    {/* { iconWithPop({
                        popMessage: (<FormattedMessage id='cash-table.hint_download_receipt' />),
                        options:    {
                            type:      'download',
                            className: _.join([ Styles.downloadIcon, rstActionsAccess? '': Styles.disabled ], ' '), //Disable access if no scope
                            onClick:   () => downloadReceipt({cashOrderId: cashOrder.id}),
                        },
                    }) } */}
                </span>
            );

            /** When cashOrder was not registered in cashdesk api service those icons are visible */
            const cashOrderWithFailedRST = (
                <span>
                    <Popconfirm
                        cancelText={<FormattedMessage id='no' />}
                        okText={<FormattedMessage id='yes' />}
                        onConfirm={() => onRepeatRegistrationInCashdesk({ cashOrder })}
                        title={<FormattedMessage id='cash-table.confirm' />}
                    >
                        <Popover content={<FormattedMessage id='cash-table.hint_repeat_registration' />}>
                            <Button
                                style={{
                                    padding: '0px 8px',
                                    marginLeft: '8px',
                                    fontSize: 18
                                }}
                            >
                                <ExclamationCircleOutlined
                                    className={_.join(
                                        [Styles.unregisteredIcon, rstActionsAccess ? '' : Styles.disabled],
                                        ' '
                                    )}
                                />
                            </Button>
                        </Popover>
                        {/* { iconWithPop({
                        popMessage: (<FormattedMessage id='cash-table.hint_repeat_registration' />),
                        options:    {
                            type:      'exclamation-circle',
                            className: _.join([ Styles.unregisteredIcon, rstActionsAccess? '': Styles.disabled ], ' '), //Disable access if no scope
                        },
                    }) } */}
                    </Popconfirm>
                </span>
            );

            return (
                <div>
                    <span>
                        <Popover content={<FormattedMessage id='cash-table.hint_print_cash_order' />}>
                            <Button
                                disabled={!isGrantAccessed(user, grants.ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_PRINT)}
                                onClick={() => openPrint(cashOrder)}
                                style={{
                                    padding: '0px 8px',
                                    fontSize: 18,
                                    marginRight: 8
                                }}
                            >
                                <PrinterOutlined />
                            </Button>
                        </Popover>
                        <Popover content={<FormattedMessage id='cash-table.hint_edit_cash_order' />}>
                            <Button
                                onClick={() => openEdit(cashOrder)}
                                style={{
                                    padding: '0px 8px',
                                    fontSize: 18
                                }}
                            >
                                <EditOutlined />
                            </Button>
                        </Popover>
                        {/*                         { iconWithPop({
                            popMessage: (<FormattedMessage id='cash-table.hint_edit_cash_order' />),
                            options:    {type: 'edit', className: Styles.editIcon, onClick: () => openEdit(cashOrder)},
                        }) } */}
                    </span>
                    {cashOrder.rst ? (cashOrder.isRegisteredWithRst ? cashOrderWithRST : cashOrderWithFailedRST) : null}
                </div>
            );
        }
    };

    const orderAndConterpartyCol = {
        title: <FormattedMessage id='cash-table.order' />,
        render: cashOrder => {
            const conterparty = renderCounterparty(cashOrder);

            return cashOrder.orderId ? (
                <div>
                    <Link style={{ color: 'var(--link' }} to={`${book.order}/${cashOrder.orderId}`}>
                        {cashOrder.orderNum}
                    </Link>
                    <div>{conterparty}</div>
                </div>
            ) : (
                <div>
                    <Link style={{ color: 'var(--link' }} to={`${book.storageDocument}/${cashOrder.storeDocId}`}>
                        {cashOrder.documentNumber}
                    </Link>
                    <div>{conterparty}</div>
                </div>
            );
        }
    };

    return !isMobile
        ? [
              numberCol,
              cashOrderCol,
              dateCol,
              conterpartyCol,
              orderCol,
              activityCol,
              sumCol,
              analyticsCol,
              descriptionCol,
              rstCodeCol,
              actionsCol
          ]
        : [dateCol, orderAndConterpartyCol, sumCol];
}
