import { Col, Row, Select, Tooltip } from 'antd';
import { CashboxRSTIcon } from 'commons';
import _, { get } from 'lodash';
import { MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    fetchCashboxes,
    selectCashboxes,
    selectFetchingCashboxes,
    selectSelectedCashboxId,
    setSelectedCashboxId
} from 'modals/RefactoredCashOrderModal/redux/cashboxes/duck';
import {
    selectMode,
    selectSelectedOrder,
    selectSelectedStoreDoc,
    selectSelectedSupplierStoreDoc,
    setRequisites
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    selectedCashboxId: selectSelectedCashboxId(state),
    selectedStoreDoc: selectSelectedStoreDoc(state),
    selectedSupplierStoreDoc: selectSelectedSupplierStoreDoc(state),
    selectedOrder: selectSelectedOrder(state),
    cashboxes: selectCashboxes(state),
    fetchingCashboxes: selectFetchingCashboxes(state),
    mode: selectMode(state)
});

const mapDispatchToProps = {
    fetchCashboxes,
    setSelectedCashboxId,
    setRequisites
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CashboxSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.fetchCashboxes();
    }

    componentDidUpdate(prevProps) {
        const { cashboxes, selectedCashboxId, mode } = this.props;
        const businessRequisiteId =
            get(this.props, 'selectedSupplierStoreDoc.businessRequisiteId') ||
            get(this.props, 'selectedOrder.order.businessRequisiteId') ||
            get(this.props, 'selectedStoreDoc.businessRequisiteId');
        if (
            mode == MODES.ADD &&
            cashboxes &&
            cashboxes.length &&
            selectedCashboxId &&
            businessRequisiteId &&
            !this.state.businessRequisiteId
        ) {
            const filtered = this.props.cashboxes.filter(
                cashbox =>
                    !businessRequisiteId ||
                    !cashbox.businessRequisiteId ||
                    cashbox.businessRequisiteId == businessRequisiteId
            );

            if (filtered.length) {
                this.props.setSelectedCashboxId(selectedCashboxId || get(filtered, '[0].id'));
            }
            this.setState({
                businessRequisiteId
            });
        }
    }

    render() {
        const {
            cashboxes,
            selectedCashboxId,
            fetchingCashboxes,
            setRequisites,
            mode,
            disabled: dsbl,

            setSelectedCashboxId,
            editable,

            intl: { formatMessage }
        } = this.props;

        const disabled = Boolean(mode != MODES.ADD) || dsbl;

        const findunactiveSelectedCashbox = cashboxes.find(({ id }) => id === selectedCashboxId);

        return (
            <div>
                <Tooltip
                    placement='top'
                    title={<FormattedMessage id='refactored_cash_order_modal.hint_cashbox' />}
                    zIndex={2001}
                >
                    <Select
                        className={Styles.select}
                        data-qa='select_credit_card'
                        disabled={!editable && (disabled || fetchingCashboxes)}
                        getPopupContainer={trigger => trigger.parentNode}
                        loading={fetchingCashboxes}
                        onSelect={(cashboxId, option) => {
                            setSelectedCashboxId(cashboxId);
                            setRequisites(option.businessRequisiteId || undefined);
                        }}
                        optionFilterProp='name'
                        showSearch
                        value={
                            _.get(findunactiveSelectedCashbox, 'active')
                                ? selectedCashboxId
                                : _.get(findunactiveSelectedCashbox, 'name')
                        }
                    >
                        {cashboxes &&
                            _.map(
                                cashboxes.filter(
                                    cashbox =>
                                        !this.state.businessRequisiteId ||
                                        !cashbox.businessRequisiteId ||
                                        cashbox.businessRequisiteId == this.state.businessRequisiteId
                                ),
                                (cashbox, index) => {
                                    const {
                                        id,
                                        name,
                                        type,
                                        rst,
                                        isShiftOpen,
                                        onlineCashdeskState,
                                        businessRequisiteId
                                    } = cashbox;

                                    return (
                                        cashbox.active && (
                                            <Option
                                                key={index}
                                                businessRequisiteId={businessRequisiteId}
                                                name={`${name} ${type}`}
                                                value={id}
                                            >
                                                <Row>
                                                    <Col span={12}>{name}</Col>
                                                    <Col className={Styles.cashboxType} span={6}>
                                                        {type}
                                                    </Col>
                                                    <Col span={6}>
                                                        <div className={Styles.cashboxRST}>
                                                            {rst ? (
                                                                <CashboxRSTIcon
                                                                    isShiftOpen={isShiftOpen}
                                                                    onlineCashdeskState={onlineCashdeskState}
                                                                />
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Option>
                                        )
                                    );
                                }
                            )}
                    </Select>
                </Tooltip>
            </div>
        );
    }
}
