/* eslint-disable no-undefined */
/* eslint-disable no-plusplus */
/* eslint-disable id-length */
/* eslint-disable no-extra-parens */
/* eslint-disable no-underscore-dangle */
import {
    CreditCardOutlined,
    DashboardOutlined,
    QuestionCircleOutlined,
    RedoOutlined,
    WalletOutlined
} from '@ant-design/icons';
import {
    Checkbox,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Select,
    Slider,
    TimePicker,
    Tooltip,
    notification
} from 'antd';
import classNames from 'classnames/bind';
import { Numeral } from 'commons';
import dayjs from 'dayjs';
import _ from 'lodash';
import { VehicleLocationModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import book from 'routes/book';
import {
    addDuration,
    fetchAPI,
    getCurrency,
    getDateTimeConfig,
    goTo,
    mergeDateTime,
    numeralFormatter,
    numeralParser,
    orderTypes
} from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { formHeaderItemLayout } from '../layouts';
import RecalculateSettingsModal from './components/RecalculateSettingsModal';
import Styles from './styles.m.css';

const { Option } = Select;

const cx = classNames.bind(Styles);

// TODO: move it into utils
// blocks hours for time picker
const getDisabledHours = (startTime = 0, endTime = 23) => {
    const availableHours = [];
    for (let i = Number(startTime); i <= Number(endTime); i++) {
        availableHours.push(i);
    }

    return _.difference(
        Array(24)
            .fill(1)
            .map((value, index) => index),
        availableHours
    );
};

@injectIntl
export default class OrderFormHeader extends Component {
    constructor(props) {
        super(props);

        const {
            intl: { formatMessage }
        } = props;

        // reusable validation rule
        this.requiredRule = [
            {
                required: true,
                message: formatMessage({
                    id: 'required_field'
                })
            }
        ];

        const stationsOptions = this._getStationsOptions();
        const managersOptions = this._getManagersOptions();
        const employeesOptions = this._getEmployeesOptions();
        const employeesOptionsSpareParts = this._getEmployeesOptionsSpareParts();
        const businessLocationsOptions = this._getBusinessLocationsOptions();

        const businessLocationsLabel = this._getBusinessLocationsLabel();

        const paymentMethodOptions = [
            <Option key='noncash' value='noncash'>
                <CreditCardOutlined style={{ marginRight: 4 }} />
                <FormattedMessage id='add_order_form.non-cash' />
            </Option>,
            <Option key='cash' value='cash'>
                <WalletOutlined style={{ marginRight: 4 }} />
                <FormattedMessage id='add_order_form.cash' />
            </Option>,
            <Option key='visa' value='visa'>
                <CreditCardOutlined style={{ marginRight: 4 }} />
                <FormattedMessage id='add_order_form.visa' />
            </Option>
        ];
        // TODO: move into utils
        // <FormatMessage id=''> triggers re-render cuz it is creating new obj
        // use formatMassage({id: }) instead
        this._localizationMap = {};

        const deliveryDatetimeConfig = this._getDeliveryDatetimeConfig();
        const beginDatetimeConfig = this._getBeginDatetimeConfig();
        // we write all data to state to handle updates correctly
        this.state = {
            deliveryDatetimeConfig,
            beginDatetimeConfig,
            stationsOptions,
            employeesOptions,
            employeesOptionsSpareParts,
            managersOptions,
            paymentMethodOptions,
            businessLocationsOptions,
            businessLocationsLabel
        };

        this.stationRef = React.createRef();
        this.employeeRef = React.createRef();
        this.requisitesRef = React.createRef();
        this.locationRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.stations !== this.props.stations) {
            const stationsOptions = this._getStationsOptions();
            this.setState({ stationsOptions });
        }

        if (prevProps.managers !== this.props.managers || prevProps.retailManagers !== this.props.retailManagers) {
            const managersOptions = this._getManagersOptions();
            this.setState({ managersOptions });
        }

        if (prevProps.employees !== this.props.employees) {
            const employeesOptions = this._getEmployeesOptions();
            const employeesOptionsSpareParts = this._getEmployeesOptionsSpareParts();

            this.setState({ employeesOptions, employeesOptionsSpareParts });
        }

        // check all fields related for deliveryDatetime
        const deliveryFields = [
            'schedule',
            'zeroStationLoadBeginDate',
            'zeroStationLoadBeginTime',
            'zeroStationLoadDuration',
            'deliveryDate'
        ];
        // check deliveryDatetime depended properties changes
        // if dayjs -> toISOString to check dayjs objects as strings to prevent re-renders
        const deliveryConfigUpdate = deliveryFields.reduce((prev, cur) => {
            const parsedThisProps = dayjs.isDayjs(this.props[cur]) ? this.props[cur].toISOString() : this.props[cur];
            const parsedPrevProps = dayjs.isDayjs(prevProps[cur]) ? prevProps[cur].toISOString() : prevProps[cur];

            return prev || parsedThisProps !== parsedPrevProps;
        }, false);
        // if deliveryDatetime fields have been updated
        // get new config and set it to local state to trigger componentUpdate with new config
        if (deliveryConfigUpdate) {
            this.setState({
                deliveryDatetimeConfig: this._getDeliveryDatetimeConfig()
            });
        }
        // update check for beginDatetime
        const currentZeroStationLoadBeginDate = this.props.zeroStationLoadBeginDate
            ? this.props.zeroStationLoadBeginDate.toISOString()
            : void 0;
        const prevZeroStationLoadBeginDate = prevProps.zeroStationLoadBeginDate
            ? prevProps.zeroStationLoadBeginDate.toISOString()
            : void 0;

        if (
            this.props.schedule !== prevProps.schedule ||
            currentZeroStationLoadBeginDate !== prevZeroStationLoadBeginDate
        ) {
            this.setState({
                beginDatetimeConfig: this._getBeginDatetimeConfig()
            });
        }

        if (prevProps.focusedRef !== this.props.focusedRef) {
            if (this.props.focusedRef === 'HEADER_STATION') {
                this.stationRef.current.focus();
                this.props.focusOnRef();
            }
            if (this.props.focusedRef === 'HEADER_EMPLOYEE') {
                this.employeeRef.current.focus();
                this.props.focusOnRef();
            }
            if (this.props.focusedRef === 'HEADER_REQUISITES') {
                this.requisitesRef.current.focus();
                this.props.focusOnRef();
            }
            if (this.props.focusedRef === 'HEADER_LOCATION_ACTION') {
                const businessLocationsLabel = this._getBusinessLocationsLabel();
                this.setState({ businessLocationsLabel });
                this.locationRef.current.focus();
                this.props.focusOnRef();
            }
        }
    }

    _renderDateBlock = () => {
        const {
            location,
            fetchedOrder,
            schedule,
            fields,
            user,
            totalHours,
            zeroStationLoadDuration,
            isClosedStatus,
            orderId,
            orderBloke,
            form
        } = this.props;

        const {
            disabledMinutes,
            disabledSeconds,
            initialDeliveryDatetime: calculatedDeliveryDatetime
        } = this.state.deliveryDatetimeConfig;

        const initialDeliveryDatetime = _.get(fetchedOrder, 'order.deliveryDatetime');

        const initialDeliveryDate = initialDeliveryDatetime
            ? dayjs(initialDeliveryDatetime)
            : calculatedDeliveryDatetime;

        const beginDatetime =
            _.get(fetchedOrder, 'order.beginDatetime') ||
            (this.bodyUpdateIsForbidden() ? void 0 : _.get(location, 'state.beginDatetime'));

        const momentBeginDatetime = beginDatetime ? dayjs(beginDatetime) : void 0;

        const dayNumber = dayjs(_.get(fields, 'stationLoads[0].beginDate')).day();
        let disabledHours = null;
        if (schedule && dayNumber) {
            let index = 0;
            switch (dayNumber) {
                case 6:
                    index = 1;
                    break;
                case 7:
                    index = 2;
                    break;
                default:
                    index = 0;
            }

            disabledHours = getDisabledHours(
                schedule[index] && schedule[index].beginTime ? schedule[index].beginTime.split(/[.:]/)[0] - 2 : 9,
                schedule[index] && schedule[index].endTime ? schedule[index].endTime.split(/[.:]/)[0] + 2 : 20
            );
        }

        return (
            <div className={Styles.headerCol}>
                <Form.Item
                    initialValue={orderBloke || _.get(user, 'orderType') || 'VEHICLE'}
                    label={this._getLocalization('storage_funds.order_type')}
                    name='orderBloke'
                    {...formHeaderItemLayout}
                >
                    <Select
                        data-qa='select_type_order_page'
                        disabled={
                            isClosedStatus ||
                            !isGrantAccessed(
                                user,
                                grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_REPAIR_TYPE,
                                accesses.ROWO
                            )
                        }
                    >
                        {orderTypes.map(type => (
                            <Option key={`${type}-aggr`} value={type}>
                                <FormattedMessage id={`aggregate.${type}`} />
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    initialValue={momentBeginDatetime}
                    label={this._getLocalization('add_order_form.enrollment_date')}
                    name={['stationLoads', 0, 'beginDate']}
                    {...formHeaderItemLayout}
                >
                    <DatePicker
                        allowClear={false}
                        className={Styles.datePanelItem}
                        data-qa='select_date_order_page'
                        disabled={this.bodyUpdateIsForbidden() || isClosedStatus}
                        format='DD-MM-YYYY'
                        placeholder={this._getLocalization('add_order_form.select_date')}
                        showTime={false}
                    />
                </Form.Item>

                <Form.Item
                    initialValue={
                        _.get(fetchedOrder, 'order.stationNum', undefined) ||
                        _.get(location, 'state.stationNum', undefined) ||
                        undefined
                    }
                    label={
                        <span>
                            {this._getLocalization('add_order_form.station')}
                            {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_MODAL_PLANNER) && (
                                <Tooltip placement='bottom' title={<FormattedMessage id='navigation.planner' />}>
                                    <DashboardOutlined
                                        onClick={() => {
                                            this.props.setModal(this.props.modals.DASHBOARD);
                                        }}
                                        style={{
                                            padding: '0 0 0 8px',
                                            color: 'var(--primary)',
                                            fontSize: 18
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </span>
                    }
                    name={['stationLoads', 0, 'station']}
                    {...formHeaderItemLayout}
                >
                    <Select
                        ref={this.stationRef}
                        allowClear
                        className={Styles.datePanelItem}
                        data-qa='select_station_order_page'
                        disabled={this.bodyUpdateIsForbidden() || isClosedStatus}
                        onChange={(value, option) => {
                            const currentMechanic = _.get(this.props, 'employees').filter(
                                ({ posts, postref }) =>
                                    posts.find(({ postName }) => postName === 'MECHANIC') &&
                                    postref[0].postNum === value
                            );

                            if (currentMechanic[0]) {
                                form.setFieldValue('employee', currentMechanic[0].id);
                            }
                        }}
                        optionFilterProp='children'
                        placeholder={this._getLocalization('add_order_form.select_station')}
                        showAction={['focus', 'click']}
                        showSearch
                    >
                        {this.state.stationsOptions}
                    </Select>
                </Form.Item>

                <Form.Item
                    initialValue={momentBeginDatetime}
                    label={this._getLocalization('add_order_form.applied_on')}
                    name={['stationLoads', 0, 'beginTime']}
                    rules={this.requiredRule}
                    {...formHeaderItemLayout}
                >
                    <TimePicker
                        allowClear={false}
                        className={Styles.datePanelItem}
                        data-qa='provide_time_order_page'
                        disabled={
                            this.bodyUpdateIsForbidden() ||
                            !_.get(fields, 'stationLoads[0].beginDate') ||
                            isClosedStatus
                        }
                        disabledHours={() => disabledHours || []}
                        format='H:mm'
                        hideDisabledOptions
                        minuteStep={30}
                        placeholder={this._getLocalization('add_order_form.provide_time')}
                    />
                </Form.Item>

                <Form.Item
                    initialValue={
                        _.get(fields, 'stationLoads[0].duration') || _.get(fetchedOrder, 'order.duration') || totalHours
                    }
                    label={
                        <React.Fragment>
                            <span>
                                {`${this._getLocalization('time')} (${
                                    zeroStationLoadDuration || 0.5
                                }${this._getLocalization('add_order_form.hours_shortcut')})`}
                            </span>
                            {orderId && (
                                <span style={{ marginLeft: 10 }}>
                                    <Tooltip
                                        placement='bottom'
                                        title={<FormattedMessage id='order_form_table.recalculation' />}
                                    >
                                        <RedoOutlined
                                            className={Styles.updateDurationIcon}
                                            onClick={() => this.setState({ recalculateSettingsOpen: true })}
                                        />
                                    </Tooltip>
                                </span>
                            )}
                        </React.Fragment>
                    }
                    name={['stationLoads', 0, 'duration']}
                    {...formHeaderItemLayout}
                >
                    <Slider
                        className={Styles.datePanelItem}
                        data-qa='hours_shortcut_order_page'
                        disabled={this.bodyUpdateIsForbidden() || isClosedStatus}
                        max={8}
                        min={0}
                        step={0.5}
                    />
                </Form.Item>
                <Form.Item
                    initialValue={_.get(fetchedOrder, 'order.businessLocationId')}
                    label={this.state.businessLocationsLabel}
                    name='businessLocationId'
                    {...formHeaderItemLayout}
                >
                    <Select
                        ref={this.locationRef}
                        className={`${Styles.location} ${
                            _.get(fetchedOrder, 'order.businessLocationId') ? Styles.disableLoctionsSelectData : null
                        }`}
                        data-qa='select_business_location_order_page'
                        disabled
                        placeholder={this._getLocalization('location')}
                    >
                        {this.state.businessLocationsOptions}
                    </Select>
                </Form.Item>

                <Form.Item hidden name='empty'>
                    <Input data-qa='input_station_loads_order_page' />
                </Form.Item>
                <Form.Item hidden name='recalculateDuration' valuePropName='checked'>
                    <Checkbox />
                </Form.Item>
            </div>
        );
    };

    _renderMasterBlock = () => {
        const { fetchedOrder, location, user, isClosedStatus, fields, schedule, deliveryDate, form, orderId } =
            this.props;

        const onlySpareParts = _.get(this.props, 'employees').filter(({ posts }) =>
            posts.find(({ postName }) => postName === 'SPARE_PARTS_SPECIALIST')
        );

        if (onlySpareParts.length === 1 && !_.get(fetchedOrder, 'order.appurtenanciesResponsibleId') && !orderId) {
            form.setFieldValue('appurtenanciesResponsible', _.get(onlySpareParts, '[0].id'));
        }

        const {
            disabledMinutes,
            disabledSeconds,
            initialDeliveryDatetime: calculatedDeliveryDatetime
        } = this.state.deliveryDatetimeConfig;

        const initialDeliveryDatetime = _.get(fetchedOrder, 'order.deliveryDatetime');

        const initialDeliveryDate = initialDeliveryDatetime
            ? dayjs(initialDeliveryDatetime)
            : calculatedDeliveryDatetime;

        const beginDatetime =
            _.get(fetchedOrder, 'order.beginDatetime') ||
            (this.bodyUpdateIsForbidden() ? void 0 : _.get(location, 'state.beginDatetime'));

        const momentBeginDatetime = beginDatetime ? dayjs(beginDatetime) : void 0;

        const dayNumber = dayjs(_.get(fields, 'stationLoads[0].beginDate')).day();
        let disabledHours = null;
        if (schedule && dayNumber) {
            let index = 0;
            switch (dayNumber) {
                case 6:
                    index = 1;
                    break;
                case 7:
                    index = 2;
                    break;
                default:
                    index = 0;
            }

            disabledHours = getDisabledHours(
                schedule[index] && schedule[index].beginTime ? schedule[index].beginTime.split(/[.:]/)[0] : 9,
                schedule[index] && schedule[index].endTime ? schedule[index].endTime.split(/[.:]/)[0] : 20
            );
        }

        return (
            <div className={Styles.headerCol}>
                <Form.Item
                    initialValue={_.get(fetchedOrder, 'order.managerId') || _.get(user, 'id')}
                    label={this._getLocalization('add_order_form.manager')}
                    name='manager'
                    rules={this.requiredRule}
                    {...formHeaderItemLayout}
                >
                    <Select
                        className={Styles.datePanelItem}
                        data-qa='select_manager_order_page'
                        disabled={
                            this.bodyUpdateIsForbidden() ||
                            isClosedStatus ||
                            !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_RESPONSIBLE)
                        }
                        optionFilterProp='children'
                        placeholder={this._getLocalization('add_order_form.select_manager')}
                        showSearch
                    >
                        {this.state.managersOptions}
                    </Select>
                </Form.Item>
                {!this.props.isRetailOrder && (
                    <Form.Item
                        initialValue={
                            _.get(fetchedOrder, 'order.employeeId') ||
                            (location.state && location.state.employeeId) ||
                            undefined
                        }
                        label={this._getLocalization('order_form_table.master')}
                        name='employee'
                        {...formHeaderItemLayout}
                    >
                        <Select
                            ref={this.employeeRef}
                            allowClear
                            className={Styles.durationPanelItem}
                            data-qa='select_master_order_page'
                            disabled={this.bodyUpdateIsForbidden() || isClosedStatus}
                            onChange={(value, option) => {
                                if (option.post) {
                                    form.setFieldValue(['stationLoads', 0, 'station'], option.post);
                                }
                            }}
                            optionFilterProp='children'
                            placeholder={this._getLocalization('order_form_table.select_master')}
                            showAction={['focus', 'click']}
                            showSearch
                        >
                            {this.state.employeesOptions}
                        </Select>
                    </Form.Item>
                )}
                {!this.props.isRTN && (
                    <Form.Item
                        initialValue={_.get(fetchedOrder, 'order.appurtenanciesResponsibleId')}
                        label={this._getLocalization('order_form_table.appurtenancies_responsible')}
                        name='appurtenanciesResponsible'
                        {...formHeaderItemLayout}
                    >
                        <Select
                            allowClear
                            className={Styles.durationPanelItem}
                            data-qa='select_appurtenancies_responsible_order_page'
                            disabled={this.bodyUpdateIsForbidden() || isClosedStatus}
                            optionFilterProp='children'
                            placeholder={this._getLocalization('add_order_form.select_appurtenancies_responsible')}
                            showSearch
                        >
                            {this.state.employeesOptionsSpareParts}
                        </Select>
                    </Form.Item>
                )}
                {!this.props.isRetailOrder ? (
                    <Form.Item
                        initialValue={initialDeliveryDate || dayjs()}
                        label={this._getLocalization('add_order_form.delivery_date')}
                        name='deliveryDate'
                        rules={this.requiredRule}
                        {...formHeaderItemLayout}
                    >
                        <DatePicker
                            allowClear={false}
                            data-qa='select_delivery_date_order_page'
                            disabled={this.bodyUpdateIsForbidden() || isClosedStatus}
                            format='DD-MM-YYYY'
                            placeholder={this._getLocalization('add_order_form.select_date')}
                            showTime={false}
                        />
                    </Form.Item>
                ) : (
                    <Form.Item
                        initialValue={
                            _.get(fetchedOrder, 'order.deliveryDatetime') !== null
                                ? dayjs(_.get(fetchedOrder, 'order.deliveryDatetime'))
                                : dayjs()
                        }
                        label={this._getLocalization('add_order_form.delivery_date')}
                        name='deliveryDatetime'
                        rules={this.requiredRule}
                        {...formHeaderItemLayout}
                    >
                        <DatePicker
                            allowClear={false}
                            data-qa='select_delivery_date_order_page'
                            disabled={this.bodyUpdateIsForbidden() || isClosedStatus}
                            format='DD-MM-YYYY'
                            placeholder={this._getLocalization('add_order_form.select_date')}
                            showTime={false}
                        />
                    </Form.Item>
                )}
                {!this.props.isRetailOrder && (
                    <Form.Item
                        initialValue={initialDeliveryDate || dayjs()}
                        label={this._getLocalization('add_order_form.delivery_time')}
                        name='deliveryTime'
                        rules={this.requiredRule}
                        {...formHeaderItemLayout}
                    >
                        <TimePicker
                            allowClear={false}
                            className={Styles.deliveryDatetime}
                            data-qa='select_provide_time_order_page'
                            disabled={Boolean(this.bodyUpdateIsForbidden() || !deliveryDate) || isClosedStatus}
                            // disabledHours={() => disabledHours || []}
                            // disabledMinutes={disabledMinutes}
                            // disabledSeconds={disabledSeconds}
                            format='H:mm'
                            minuteStep={30}
                            placeholder={this._getLocalization('add_order_form.provide_time')}
                        />
                    </Form.Item>
                )}
                {!this.props.isRetailOrder && (
                    <Form.Item
                        initialValue={_.get(fetchedOrder, 'order.discount')}
                        label={this._getLocalization('total_discount_or_markup')}
                        name='discount'
                        {...formHeaderItemLayout}
                    >
                        <InputNumber
                            className={Styles.durationPanelItem}
                            data-qa='input_total_discount_or_markup_order_page'
                            decimalSeparator=','
                            disabled={this.bodyUpdateIsForbidden() || isClosedStatus}
                            placeholder={this._getLocalization('total_discount_or_markup')}
                        />
                    </Form.Item>
                )}

                <Form.Item hidden initialValue='TO_DO' name={['stationLoads', 0, 'status']}>
                    <Input data-qa='input_station_loads_order_page' />
                </Form.Item>
            </div>
        );
    };

    _renderMasterBlockRtl = () => {
        const { fetchedOrder, requisites, selectedClient, isClosedStatus, user, isRetailOrder, isRTN } = this.props;
        const mask = '0,0.00';

        return (
            <div className={Styles.headerCol}>
                <Form.Item
                    initialValue={
                        _.get(fetchedOrder, 'order.businessRequisiteId', undefined) ||
                        (!this.props.orderId && _.get(user, 'businessRequisitesId', undefined))
                    }
                    label={
                        <div>
                            {this._getLocalization('add_order_form.service_requisites')}
                            {isRetailOrder ? '/' : ''}
                            {isRetailOrder && this._getLocalization('car_shop')}
                        </div>
                    }
                    name='requisite'
                    placeholder={this._getLocalization('add_order_form.select_payment_method')}
                    {...formHeaderItemLayout}
                >
                    <Select
                        ref={this.requisitesRef}
                        allowClear
                        data-qa='select_business_requisites_order_page'
                        disabled={this.bodyUpdateIsForbidden() || isClosedStatus || isRTN}
                        onSelect={async () => {
                            const { order } = await fetchAPI(
                                'GET',
                                isRTN ? `/return/${this.props.orderId}` : `/retail/${this.props.orderId}`,
                                null,
                                null
                            );
                            if (order && order.status === 'success') {
                                notification.error({
                                    message: this.props.intl.formatMessage({
                                        id: 'order_form_table.closed_order_notific'
                                    })
                                });

                                return setTimeout(() => window.location.reload(), 2000);
                            }
                            if (this.props.orderStatus) {
                                this.props.onStatusChange(this.props.orderStatus);
                            }
                        }}
                        optionFilterProp='name'
                        placeholder={this._getLocalization('add_order_form.select_requisites_business')}
                        showAction={['focus', 'click']}
                        showSearch
                    >
                        {requisites
                            .filter(({ enabled }) => enabled)
                            .map(rq => (
                                <Option key={`req-${rq.id}`} value={rq.id}>
                                    <Tooltip title={rq.name}>
                                        {rq.name.length > 40 ? `${rq.name.substring(0, 40)}...` : rq.name}
                                        {rq.isTaxPayer && (
                                            <span
                                                style={{
                                                    marginLeft: 8,
                                                    color: 'var(--text2)'
                                                }}
                                            >
                                                (<FormattedMessage id='with_VAT' />)
                                            </span>
                                        )}
                                    </Tooltip>
                                </Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    initialValue={_.get(fetchedOrder, 'order.clientRequisiteId')}
                    label={this._getLocalization('add_order_form.client_requisites')}
                    name='clientRequisite'
                    {...formHeaderItemLayout}
                >
                    <Select
                        allowClear
                        className={Styles.deliveryDatetime}
                        data-qa='select_client_requisites_order_page'
                        disabled={this.bodyUpdateIsForbidden() || isClosedStatus || isRTN}
                        optionFilterProp='name'
                        placeholder={this._getLocalization('add_order_form.select_requisites')}
                        showSearch
                    >
                        {_.get(selectedClient, 'requisites', [])
                            .filter(({ enabled }) => enabled)
                            .map(rq => (
                                <Option key={`selCl-req-${rq.id}`} value={rq.id}>
                                    <Tooltip title={rq.name}>
                                        {rq.name.length > 40 ? `${rq.name.substring(0, 40)}...` : rq.name}
                                        {rq.isTaxPayer && (
                                            <span
                                                style={{
                                                    marginLeft: 8,
                                                    color: 'var(--text2)'
                                                }}
                                            >
                                                (<FormattedMessage id='with_VAT' />)
                                            </span>
                                        )}
                                    </Tooltip>
                                </Option>
                            ))}
                    </Select>
                </Form.Item>

                {!isRTN && (
                    <Form.Item
                        initialValue={_.get(fetchedOrder, 'order.discount')}
                        label={
                            <div>
                                {this._getLocalization('discount_amount')}
                                <Tooltip
                                    placement='topRight'
                                    title={this._getLocalization('client_container.sum_discounts.info')}
                                >
                                    <QuestionCircleOutlined style={{ marginLeft: 4, color: 'var(--primary)' }} />
                                </Tooltip>
                            </div>
                        }
                        name='discount'
                        {...formHeaderItemLayout}
                    >
                        <InputNumber
                            className={Styles.durationPanelItem}
                            data-qa='input_total_discount_amount_rtl_page'
                            decimalSeparator=','
                            disabled={this.bodyUpdateIsForbidden() || isClosedStatus}
                            placeholder={this._getLocalization('discount_amount')}
                        />
                    </Form.Item>
                )}
            </div>
        );
    };

    _renderTotalBlock = () => {
        const { fetchedOrder, requisites, selectedClient, isClosedStatus, user } = this.props;
        const { totalPrice, cashSum, remainPrice, totalSumWithTax, isTaxPayer, isRetailOrder } = this.props;
        const mask = '0,0.00';

        return (
            <div className={Styles.headerCol}>
                <div className={Styles.sumBlock}>
                    <div className={Styles.sum}>
                        <span className={Styles.sumWrapper} data-qa='numeral_total_sum_order_page'>
                            <FormattedMessage id='sum' />
                            <Numeral className={Styles.sumNumeral} currency={getCurrency()} mask={mask} nullText='0'>
                                {totalPrice}
                            </Numeral>
                        </span>
                        {isTaxPayer && (
                            <span className={Styles.sumWrapper} data-qa='numeral_total_sum_with_tax_order_page'>
                                <FormattedMessage id='with' /> <FormattedMessage id='VAT' />
                                <Numeral
                                    className={Styles.sumNumeral}
                                    currency={getCurrency()}
                                    mask={mask}
                                    nullText='0'
                                >
                                    {totalSumWithTax}
                                </Numeral>
                            </span>
                        )}
                        <span className={Styles.sumWrapper} data-qa='numaral_cash_sum_order_page'>
                            <FormattedMessage id='paid' />
                            <Numeral className={Styles.sumNumeral} currency={getCurrency()} mask={mask} nullText='0'>
                                {cashSum}
                            </Numeral>
                        </span>
                    </div>
                    <div
                        className={this._totalStyles(
                            !isGrantAccessed(user, grants.ACCOUNTING_SETTLEMENT_JOURNAL, accesses.ROWO)
                        )}
                        data-qa='numeral_remain_price_order_page'
                        onClick={() => this._redirectToCashFlow()}
                    >
                        <FormattedMessage id='remain' />
                        <Numeral className={Styles.totalSum} currency={getCurrency()} mask={mask} nullText='0'>
                            {remainPrice || 0}
                        </Numeral>
                    </div>
                </div>
                <Form.Item
                    initialValue={
                        _.get(fetchedOrder, 'order.paymentMethod') || _.get(user, 'orderPaymentMethod') || 'noncash'
                    }
                    label={this._getLocalization('add_order_form.payment_method')}
                    name='paymentMethod'
                    placeholder={this._getLocalization('add_order_form.select_payment_method')}
                    {...formHeaderItemLayout}
                >
                    <Select
                        data-qa='select_payment_method_order_page'
                        disabled={this.bodyUpdateIsForbidden() || isClosedStatus}
                        onSelect={async () => {
                            if (this.props.orderId) {
                                const { status: st } = await fetchAPI(
                                    'GET',
                                    'orders/status',
                                    { orderId: this.props.orderId },
                                    null
                                );
                                if (st === 'success') {
                                    notification.error({
                                        message: this.props.intl.formatMessage({
                                            id: 'order_form_table.closed_order_notific'
                                        })
                                    });

                                    return setTimeout(() => window.location.reload(), 2000);
                                }
                                if (this.props.orderStatus) {
                                    this.props.onStatusChange(this.props.orderStatus);
                                }
                            }
                        }}
                        placeholder={this._getLocalization('add_order_form.select_payment_method')}
                    >
                        {this.state.paymentMethodOptions}
                    </Select>
                </Form.Item>

                {!isRetailOrder && (
                    <Form.Item
                        initialValue={
                            _.get(fetchedOrder, 'order.businessRequisiteId', undefined) ||
                            (!this.props.orderId && _.get(user, 'businessRequisitesId', undefined)) ||
                            undefined
                        }
                        label={
                            <div>
                                {this._getLocalization('add_order_form.service_requisites')}
                                {isRetailOrder ? '/' : ''}
                                {isRetailOrder && this._getLocalization('car_shop')}
                            </div>
                        }
                        name='requisite'
                        placeholder={this._getLocalization('add_order_form.select_payment_method')}
                        {...formHeaderItemLayout}
                    >
                        <Select
                            ref={this.requisitesRef}
                            allowClear
                            data-qa='select_business_requisites_order_page'
                            disabled={this.bodyUpdateIsForbidden() || isClosedStatus}
                            onSelect={async () => {
                                if (this.props.orderId && !this.props.isRetailOrder) {
                                    const { status: st } = await fetchAPI(
                                        'GET',
                                        'orders/status',
                                        { orderId: this.props.orderId },
                                        null
                                    );
                                    if (st === 'success') {
                                        notification.error({
                                            message: this.props.intl.formatMessage({
                                                id: 'order_form_table.closed_order_notific'
                                            })
                                        });

                                        return setTimeout(() => window.location.reload(), 2000);
                                    }
                                    if (this.props.orderStatus) {
                                        this.props.onStatusChange(this.props.orderStatus);
                                    }
                                }
                            }}
                            optionFilterProp='name'
                            placeholder={this._getLocalization('add_order_form.select_requisites_business')}
                            showAction={['focus', 'click']}
                            showSearch
                        >
                            {requisites
                                .filter(({ enabled }) => enabled)
                                .map(rq => (
                                    <Option key={`req-${rq.id}`} value={rq.id}>
                                        <Tooltip title={rq.name}>
                                            {rq.name.length > 40 ? `${rq.name.substring(0, 40)}...` : rq.name}
                                            {rq.isTaxPayer && (
                                                <span
                                                    style={{
                                                        marginLeft: 8,
                                                        color: 'var(--text2)'
                                                    }}
                                                >
                                                    (<FormattedMessage id='with_VAT' />)
                                                </span>
                                            )}
                                        </Tooltip>
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                )}

                {!isRetailOrder && (
                    <Form.Item
                        initialValue={_.get(fetchedOrder, 'order.clientRequisiteId')}
                        label={this._getLocalization('add_order_form.client_requisites')}
                        name='clientRequisite'
                        {...formHeaderItemLayout}
                    >
                        <Select
                            allowClear
                            className={Styles.deliveryDatetime}
                            data-qa='select_client_requisites_order_page'
                            disabled={this.bodyUpdateIsForbidden() || isClosedStatus}
                            optionFilterProp='name'
                            placeholder={this._getLocalization('add_order_form.select_requisites')}
                            showSearch
                        >
                            {_.get(selectedClient, 'requisites', [])
                                .filter(({ enabled }) => enabled)
                                .map(rq => (
                                    <Option key={`selCl-req-${rq.id}`} value={rq.id}>
                                        <Tooltip title={rq.name}>
                                            {rq.name.length > 40 ? `${rq.name.substring(0, 40)}...` : rq.name}
                                            {rq.isTaxPayer && (
                                                <span
                                                    style={{
                                                        marginLeft: 8,
                                                        color: 'var(--text2)'
                                                    }}
                                                >
                                                    (<FormattedMessage id='with_VAT' />)
                                                </span>
                                            )}
                                        </Tooltip>
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                )}
                {!this.props.isRetailOrder && (
                    <Form.Item
                        initialValue={_.get(fetchedOrder, 'order.odometerValue')}
                        label={this._getLocalization('add_order_form.odometr')}
                        name='odometerValue'
                        {...formHeaderItemLayout}
                    >
                        <InputNumber
                            ref={this.milageRef}
                            className={Styles.odometr}
                            data-qa='input_number_client_provide_odometr_order_page'
                            decimalSeparator=','
                            disabled={
                                this.bodyUpdateIsForbidden() ||
                                (isClosedStatus &&
                                    !isGrantAccessed(
                                        user,
                                        grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_CLOSED_ORDER_EDITING_MILEAGE
                                    ))
                            }
                            formatter={value => numeralFormatter(value)}
                            min={0}
                            parser={value => numeralParser(value)}
                            placeholder={this._getLocalization('add_order_form.provide_odometr')}
                        />
                    </Form.Item>
                )}
            </div>
        );
    };

    _getBusinessLocationsOptions = () => {
        return (_.get(this.props, 'businessLocations') || []).map(({ id, name }) => {
            return (
                <Option key={`businessLocations-${name}-${id}`} value={id}>
                    {name}
                </Option>
            );
        });
    };

    _getStationsOptions = () => {
        return _.get(this.props, 'stations', []).map(({ name, num }) => {
            return (
                <Option key={String(num)} value={num}>
                    {name || String(num)}
                </Option>
            );
        });
    };

    _getManagersOptions = () => {
        const { fetchedOrder, user, isRetailOrder } = this.props;

        return !isRetailOrder
            ? _.get(this.props, 'managers', [])
                  .filter(
                      ({ posts, id }) =>
                          posts.findIndex(({ postName }) => postName === 'MASTER_RECEIVER') !== -1 ||
                          _.get(fetchedOrder, 'order.managerId') === id ||
                          _.get(user, 'id') === id
                  )
                  .sort((a, b) => a.managerSurname.localeCompare(b.managerSurname))
                  .map(manager => (
                      <Option key={`manager-${manager.id}`} disabled={manager.disabled} value={manager.id}>
                          {`${manager.managerSurname} ${manager.managerName}`}
                      </Option>
                  ))
            : _.get(this.props, 'retailManagers', [])
                  .filter(
                      ({ posts, id }) =>
                          posts.findIndex(({ postName }) => postName === 'MASTER_RECEIVER') !== -1 ||
                          _.get(fetchedOrder, 'order.managerId') === id ||
                          _.get(user, 'id') === id
                  )
                  .sort((a, b) => a.managerSurname.localeCompare(b.managerSurname))
                  .map(manager => (
                      <Option key={`manager-${manager.id}`} disabled={manager.disabled} value={manager.id}>
                          {`${manager.managerSurname} ${manager.managerName}`}
                      </Option>
                  ));
    };

    _getEmployeesOptions = () => {
        const { fetchedOrder } = this.props;

        return _.get(this.props, 'employees', [])
            .filter(
                ({ disabled, posts, id }) =>
                    (!disabled && posts.findIndex(({ postName }) => postName === 'MECHANIC') !== -1) ||
                    _.get(fetchedOrder, 'order.employeeId') === id
            )
            .sort((a, b) => a.surname.localeCompare(b.surname))
            .map(employee => (
                <Option
                    key={`employee-${employee.id}`}
                    disabled={employee.disabled}
                    post={_.get(employee, 'postref[0].postNum')}
                    value={employee.id}
                >
                    {`${employee.surname} ${employee.name}`}
                </Option>
            ));
    };

    _getEmployeesOptionsSpareParts = () => {
        const { fetchedOrder, isRetailOrder } = this.props;

        return _.get(this.props, !isRetailOrder ? 'employees' : 'retailEmployees', [])
            .filter(
                ({ disabled, posts, id }) =>
                    (!disabled && posts.findIndex(({ postName }) => postName === 'SPARE_PARTS_SPECIALIST') !== -1) ||
                    _.get(fetchedOrder, 'order.appurtenanciesResponsibleId') === id
            )
            .sort((a, b) => a.surname.localeCompare(b.surname))
            .map(employee => (
                <Option key={`employee-${employee.id}`} disabled={employee.disabled} value={employee.id}>
                    {`${employee.surname} ${employee.name}`}
                </Option>
            ));
    };

    _redirectToCashFlow = () => {
        if (isGrantAccessed(this.props.user, grants.ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER)) {
            goTo(book.storageCalculations, {
                documentNumber: this.props.orderId
            });
        }
    };

    _totalStyles = disabled =>
        cx({
            totalDisabled: disabled,
            total: true
        });

    bodyUpdateIsForbidden() {
        return !isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER, accesses.RAWO);
    }

    _getBeginDatetimeConfig() {
        const { schedule } = this.props;
        const { disabledDate } = getDateTimeConfig(void 0, schedule);
        const { beginTime } =
            getDateTimeConfig(void 0, schedule) ||
            dayjs()
                .add(30 - (dayjs().minute() % 30), 'minutes')
                .format('YYYY-MM-DD HH:00');

        return {
            disabledDate,
            beginTime
        };
    }

    _getDeliveryDatetimeConfig() {
        const { schedule, zeroStationLoadBeginDate, zeroStationLoadBeginTime, zeroStationLoadDuration, deliveryDate } =
            this.props;

        const excludeConfig = [
            {
                momentDate: zeroStationLoadBeginDate,
                momentTime: zeroStationLoadBeginTime,
                duration: zeroStationLoadDuration
            }
        ];

        const {
            disabledHours,
            disabledMinutes,
            disabledSeconds,
            disabledDate: dateTimeDisabledDate,
            beginTime
        } = getDateTimeConfig(dayjs(deliveryDate), schedule, excludeConfig);

        const initialBeginDatetime = dayjs(zeroStationLoadBeginDate).set({
            hours: 0,
            minutes: 0,
            milliseconds: 0,
            seconds: 0
        });

        const sameOfBeforeDisabledDate = date =>
            dateTimeDisabledDate(date) || (date && date.isSameOrBefore(initialBeginDatetime));

        const initialDeliveryDatetime =
            zeroStationLoadBeginDate && zeroStationLoadBeginTime && zeroStationLoadDuration
                ? addDuration(
                      mergeDateTime(zeroStationLoadBeginDate, zeroStationLoadBeginTime),
                      zeroStationLoadDuration
                  )
                : void 0;

        return {
            disabledHours,
            disabledMinutes,
            disabledSeconds,
            disabledDate: sameOfBeforeDisabledDate,
            beginTime,
            initialDeliveryDatetime
        };
    }

    _getLocalization(key) {
        if (!this._localizationMap[key]) {
            this._localizationMap[key] = this.props.intl.formatMessage({
                id: key
            });
        }

        return this._localizationMap[key];
    }

    _getBusinessLocationsLabel() {
        const { fields, fetchedOrder, orderId, updateOrderField, user } = this.props;
        const businessLocationId =
            _.get(fields, 'businessLocationId') || _.get(fetchedOrder, 'order.businessLocationId');
        const orderSuccess = _.get(this.props, 'order.status') === 'success';

        return (
            <div>
                <FormattedMessage id='location' />
                {orderId &&
                    fetchedOrder &&
                    isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_LOCATION) && (
                        <VehicleLocationModal
                            clientId={_.get(fetchedOrder, 'order.clientId')}
                            currentLocation={businessLocationId}
                            disabled={
                                (!businessLocationId && orderSuccess) ||
                                !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_LOCATION)
                            }
                            hideModal={() => {
                                const businessLocationsLabel = this._getBusinessLocationsLabel();
                                this.setState({ businessLocationsLabel });
                            }}
                            onConfirm={businessLocationId =>
                                updateOrderField({ businessLocationId: businessLocationId || null })
                            }
                            orderId={orderId}
                            receiveMode={!businessLocationId}
                            returnMode={businessLocationId && orderSuccess}
                            showIcon
                            showModal={this.props.focusedRef === 'HEADER_LOCATION_ACTION'}
                            style={{ marginLeft: 4 }}
                            transferMode={businessLocationId && !orderSuccess}
                            vehicleId={_.get(fetchedOrder, 'order.clientVehicleId')}
                        />
                    )}
            </div>
        );
    }

    render() {
        const dateBlock = this._renderDateBlock();
        const masterBlock = this._renderMasterBlock();
        const rtlMasterBlock = this._renderMasterBlockRtl();
        const totalBlock = this._renderTotalBlock();

        return (
            <div className={Styles.formHeader} id='OrderFormHeader'>
                <div className={Styles.headerColumns}>
                    {!this.props.isRetailOrder && dateBlock} {masterBlock} {this.props.isRetailOrder && rtlMasterBlock}{' '}
                    {totalBlock}
                </div>
                <RecalculateSettingsModal
                    close={() => this.setState({ recalculateSettingsOpen: false })}
                    open={this.state.recalculateSettingsOpen}
                    updateOrderField={this.props.updateOrderField}
                />
            </div>
        );
    }
}
