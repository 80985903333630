/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import { Form, message, Modal, notification } from 'antd';
import {
    clearTecdocDetailsSuggestions,
    clearTecdocSuggestions,
    fetchTecdocDetailsSuggestions,
    fetchTecdocSuggestions,
    onChangeClientSearchQuery,
    onChangeOrderForm,
    selectCashFlowFilters,
    selectCashSum,
    selectOrderTasksStats,
    setClientSelection
} from 'core/forms/orderForm/duck';
import { resetModal } from 'core/modals/duck';
import { selectStoreProductsByQuery, setStoreProductsSearchQuery } from 'core/search/duck';
import { fetchRecommendedPrice, selectRecommendedPrice, selectRecommendedPriceLoading } from 'core/storage/products';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
    AddClientModal,
    EmployeeSubTasksModal,
    OrderClientChangeModal,
    RecommendationsToServicesModal,
    ToSuccessModal
} from 'modals';
import React, { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import OrderFormBody from './OrderFormBody';
import OrderFormHeader from './OrderFormHeader';
import OrderFormTabs from './OrderFormTabs';
import RetailCheckTabs from './RetailCheckTabs';
import { servicesStats } from './stats';
import Styles from './styles.m.css';

@injectIntl
@connect(
    state => ({
        // modal: state.modals.modal,
        // user: state.auth,
        addClientFormData: state.forms.addClientForm.data,
        authentificatedManager: state.auth.id,
        cashFlowFilters: selectCashFlowFilters(state),
        cashSum: selectCashSum(state),
        detailsSuggestionsFetching: state.ui.detailsSuggestionsFetching,
        schedule: state.forms.orderForm.schedule,
        stationLoads: state.forms.orderForm.stationLoads,
        suggestionsFetching: state.ui.suggestionsFetching,
        storeProducts: selectStoreProductsByQuery(state),
        recommendedPrice: selectRecommendedPrice(state),
        recommendedPriceLoading: selectRecommendedPriceLoading(state),
        orderTasksStats: selectOrderTasksStats(state),
        ...state.forms.orderForm
    }),
    {
        setClientSelection,
        onChangeOrderForm,
        resetModal,
        fetchTecdocSuggestions,
        clearTecdocSuggestions,
        fetchTecdocDetailsSuggestions,
        clearTecdocDetailsSuggestions,
        setStoreProductsSearchQuery,
        fetchRecommendedPrice,
        onChangeClientSearchQuery
    }
)
class OrderFormFields extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            formValues: {},
            labors: [],
            details: undefined,
            fetchedOrder: props.fetchedOrder || props.fetchedRetailCheck,
            unitDefault: []
        };
    }

    _fetchLaborsAndDetails = async () => {
        const details = await fetchAPI('GET', '/store_groups', undefined, undefined, {
            handleErrorInternally: true
        });
        this.setState({ details });
    };

    _reloadOrderForm = async callback => {
        if (!this.props.isRetailOrder) {
            const fetchedOrder = await fetchAPI(
                'GET',
                `/orders/${this.props.orderId}`,
                {
                    onlyLabors: true,
                    onlyDetails: true
                },
                undefined,
                { handleErrorInternally: true }
            );
            await this.setState({ fetchedOrder });
            if (callback) callback(fetchedOrder);
        } else {
            const fetchedOrder = await fetchAPI(
                'GET',
                this.props.isRTN ? `/return/${this.props.orderId}` : `/retail/${this.props.orderId}`,
                null,
                undefined,
                { handleErrorInternally: true }
            );
            await this.setState({ fetchedOrder });

            if (callback) await callback(fetchedOrder);
        }
    };

    _updateOrderField = async (field, recalculateDuration) => {
        const status = _.get(this.state.fetchedOrder, 'order.status');
        const fetchedOrder = this.state.fetchedOrder || this.props.fetchedOrder;
        if (recalculateDuration) {
            this.props.form.setFieldsValue({
                recalculateDuration: true
            });
        }
        if (field === 'duration') {
            const orderServices = _.get(fetchedOrder, 'orderServices', []);
            let hours = 0;
            orderServices.forEach(elem => {
                if (elem.agreement !== 'REJECTED') hours += elem.count;
            });

            if (hours > 8) {
                message.warning('Кількість годин більше 8. ');
                hours = 8;
            }

            const formValues = this.props.form.getFieldsValue();
            const beginDate = _.get(formValues, 'stationLoads[0].beginDate');
            const duration = Math.round(hours * 100) / 100;
            const deliveryDate = beginDate.add(duration, 'h');
            this.props.form.setFieldValue(['stationLoads', 0, 'duration'], duration);
            this.props.form.setFieldsValue({
                deliveryDate,
                deliveryTime: deliveryDate
            });
            this.props.onStatusChange(status);
        } else {
            this.props.onStatusChange(status);
        }
    };

    _openNotification = ({ make, model }) => {
        const params = {
            message: this.props.intl.formatMessage({
                id: 'order-form.warning'
            }),
            description: (
                <div>
                    <div>
                        {this.props.intl.formatMessage({
                            id: 'order-form.update_modification_info'
                        })}
                    </div>
                    <div>
                        {make} {model}
                    </div>
                </div>
            ),
            placement: 'topLeft',
            duration: 7
        };
        notification.open(params);
    };

    fetchUnitsSettings = async () => {
        const unitDefault = await fetchAPI('GET', 'business/measurement/units/settings', undefined, undefined);
        this.setState({
            unitDefault: unitDefault.map(({ measurementUnits }) => measurementUnits).flat()
        });
    };

    componentDidMount() {
        this._isMounted = true;
        if ((this._isMounted && this.props.fetchedOrder) || (this._isMounted && this.state.fetchedOrder)) {
            this._fetchLaborsAndDetails();
            // this._reloadOrderForm();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedClient, form, addOrderForm, clientsAggregates, fetchedRetailCheck } = this.props;
        const { formValues: prevFormValues, fetchedOrder } = this.state;
        const { fetchedOrder: prevFetchedOrder } = prevState;

        const formValues = this.props.form.getFieldsValue();
        const newClientVehicleId = formValues.clientVehicle;
        const oldClientVehicleId = prevFormValues.clientVehicle;

        if (this.props.fetchedRetailCheck && prevProps.fetchedRetailCheck !== this.props.fetchedRetailCheck) {
            this.setState({
                fetchedOrder: this.props.fetchedRetailCheck
            });
            this.props.form.setFieldsValue({
                clientVehicle: _.get(this.props.fetchedRetailCheck, 'order.clientVehicleId'),
                requisite: _.get(this.props.fetchedRetailCheck, 'order.businessRequisiteId') || false,
                discount: _.get(this.props.fetchedRetailCheck, 'order.discount') || 0
            });
        }

        if (!_.isEqual(selectedClient, prevProps.selectedClient)) {
            // this.props.form.setFieldsValue({
            //     // clientVehicle: _.get(selectedClient, 'vehicles[0].id'),
            //     clientPhone: _.get(fetchedOrder, 'clientPhone')
            // });
            if (addOrderForm && newClientVehicleId !== oldClientVehicleId && newClientVehicleId) {
                this.checkExisting(newClientVehicleId);
                this.checkExisting(_.get(selectedClient, 'aggregates[0].clientAggregateId'));
            }
        }

        if (!_.isEqual(fetchedOrder, prevState.fetchedOrder) && !prevState.fetchedOrder) {
            if (_.get(fetchedOrder, 'order.clientVehicleId')) {
                this.props.form.setFieldsValue({
                    clientVehicle: _.get(fetchedOrder, 'order.clientVehicleId')
                });
            }
        }

        if (!_.isEqual(fetchedOrder, prevState.fetchedOrder) && !prevState.fetchedOrder) {
            if (_.get(fetchedOrder, 'order.clientAggregateId')) {
                this.props.form.setFieldsValue({
                    clientAggregateId: _.get(fetchedOrder, 'order.clientAggregateId')
                });
            }
        }

        if (!_.isEqual(formValues.searchClientQuery, prevFormValues.searchClientQuery)) {
            this.props.onChangeClientSearchQuery(
                _.get(formValues, 'searchClientQuery', ''),
                _.get(formValues, 'orderBloke') === 'UNIT_REPAIR' ||
                    _.get(formValues, 'orderBloke') === 'WARRANTY_UNIT_REPAIR'
            );
        }

        if (!prevFetchedOrder && fetchedOrder) {
            this.props.form.setFieldsValue({
                'stationLoads[0].duration': _.get(fetchedOrder, 'order.duration'),
                servicesDiscount: _.get(fetchedOrder, 'order.servicesDiscount') || 0,
                detailsDiscount: _.get(fetchedOrder, 'order.detailsDiscount') || 0
            });
        }

        if (newClientVehicleId !== oldClientVehicleId && newClientVehicleId) {
            const newClientVehicle = this._getClientVehicle(newClientVehicleId);
            if (newClientVehicle) {
                if (!newClientVehicle.modificationId && !newClientVehicle.tecdocId) {
                    this._openNotification(newClientVehicle);
                }
                // } else if (newClientVehicle.bodyType ) {
                //     this._openNotification(newClientVehicle);
                // }
            }
        }

        if (!_.isEqual(formValues, prevFormValues)) {
            // this.props.onChangeOrderForm({ formValues });
            this.setState({ formValues });
        }

        const beginDate = _.get(formValues, 'stationLoads[0].beginDate');
        const prevBeginDate = _.get(prevFormValues, 'stationLoads[0].beginDate');
        const deliveryDate = _.get(formValues, 'deliveryDate');
        if (beginDate && prevBeginDate && !beginDate.isSame(prevBeginDate) && beginDate > deliveryDate) {
            this.props.form.setFieldsValue({
                deliveryDate: beginDate
            });
        }

        if (
            !_.isEqual(
                _.get(this.props, 'fields.searchClientQuery.value'),
                _.get(prevProps, 'fields.searchClientQuery.value')
            )
        ) {
            form.setFieldsValue({
                searchClientQuery: _.get(this.props, 'fields.searchClientQuery.value')
            });
        }
    }

    _saveFormRef = formRef => {
        this.formRef = formRef;
    };

    _bodyUpdateIsForbidden = () => !isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER);

    _getClientVehicle = clientVehicleId => {
        const { vehicles } = this.state;

        return clientVehicleId && _.isArray(vehicles) ? _.chain(vehicles).find({ id: clientVehicleId }).value() : null;
    };

    checkExisting = async id => {
        const {
            intl: { formatMessage }
        } = this.props;
        const { orders } = await fetchAPI(
            'GET',
            'orders',
            {
                vehicleId: id,
                status: 'not_complete,required,call,reserve,approve,progress,review,invite'
            },
            null,
            { handleErrorInternally: true }
        );

        if (orders) {
            const index = orders.findIndex(({ vehicleId }) => id === vehicleId);
            if (index >= 0) {
                Modal.confirm({
                    title: formatMessage({
                        id: 'add_client_form.already_exist_order'
                    }),
                    onOk() {
                        goTo(`${book.order}/${orders[index].id}`);
                    },
                    okType: 'default',
                    onCancel() {}
                });
            }
        }
    };

    _getTecdocId = () => {
        const { form } = this.props;

        const clientVehicleId = form.getFieldValue('clientVehicle');
        const vehicles = _.get(this.props, 'vehicles');

        return clientVehicleId && _.isArray(vehicles)
            ? _.chain(vehicles).find({ id: clientVehicleId }).get('modificationId', null).value()
            : null;
    };

    render() {
        const {
            authentificatedManager,
            form,
            orderId,
            selectedClient,
            searchClientsResult,
            setClientSelection,
            cashSum,
            cashFlowFilters,
            setAddClientModal,
            schedule,
            stations,
            businessLocations,
            managers,
            employees,
            requisites,
            orderTasksStats,
            user,
            location,
            errors,
            focusOnRef,
            focusedRef,
            setModal,
            modals,
            fetchOrderForm,
            isClosedStatus,
            vehicles,
            handleSearchClientVehicles,
            fetchVehicles,
            isRetailOrder,
            retailManagers,
            retailEmployees,
            fetchedRetailCheck,
            retailCashSum,
            isRTN
        } = this.props;

        const formFieldsValues = form.getFieldsValue() || this.state.updatedValues;

        const { totalHours } = servicesStats(this.orderServices);
        const clientVehicle = _.get(formFieldsValues, 'clientVehicle');
        const clientPhone = _.get(formFieldsValues, 'clientPhone');
        const clientAggregateId = _.get(formFieldsValues, 'clientAggregateId');
        const clientEmail = _.get(formFieldsValues, 'clientEmail');
        const searchClientQuery = _.get(formFieldsValues, 'searchClientQuery');

        const zeroStationLoadBeginDate = _.get(formFieldsValues, 'stationLoads[0].beginDate');
        const zeroStationLoadBeginTime = _.get(formFieldsValues, 'stationLoads[0].beginTime');
        const zeroStationLoadStation = _.get(formFieldsValues, 'stationLoads[0].station');
        const zeroStationLoadDuration = _.get(formFieldsValues, 'stationLoads[0].duration');
        const deliveryDate = _.get(formFieldsValues, 'deliveryDate');
        const paymentMethod = _.get(formFieldsValues, 'paymentMethod');

        const orderFormBodyFields = _.pick(formFieldsValues, [
            'comment',
            'odometerValue',
            'discount',
            'clientVehicle',
            'clientAggregateId',
            'clientRequisite',
            'clientEmail',
            'clientPhone',
            'searchClientQuery',
            'businessLocationId'
        ]);

        const orderFormHeaderFields = _.pick(formFieldsValues, [
            'stationLoads[0].beginTime',
            'stationLoads[0].station',
            'stationLoads[0].beginDate',
            'stationLoads[0].duration',
            'deliveryDate',
            'deliveryTime',
            'manager',
            'employee',
            'appurtenanciesResponsible',
            'paymentMethod',
            'requisite',
            'clientRequisite',
            'recalculateDuration'
        ]);

        const tabs = this._renderTabs(formFieldsValues);

        const fetchedOrder = this.state.fetchedOrder || fetchedRetailCheck || this.props.fetchedOrder;
        const order = _.get(fetchedOrder, 'order', {});

        const orderBloke = _.get(formFieldsValues, 'orderBloke') || _.get(fetchedOrder, 'order.orderBloke');

        const {
            totalSum,
            totalSumWithTax,
            isTaxPayer,
            servicesDiscount,
            servicesSum,
            servicesSumDiscount,
            servicesTotalSum,
            detailsDiscount,
            detailsSum,
            detailsSumDiscount,
            detailsTotalSum
        } = order;

        const remainPrice = isTaxPayer
            ? ((totalSumWithTax - (retailCashSum || cashSum)) * 100) / 100
            : ((totalSum - (retailCashSum || cashSum)) * 100) / 100;

        return (
            <React.Fragment>
                <OrderFormHeader
                    authentificatedManager={authentificatedManager}
                    businessLocations={businessLocations}
                    cashFlowFilters={cashFlowFilters}
                    cashSum={retailCashSum || cashSum}
                    deliveryDate={deliveryDate}
                    detailsDiscount={detailsDiscount}
                    employees={employees}
                    errors={errors}
                    fetchedOrder={fetchedOrder}
                    fields={orderFormHeaderFields}
                    focusedRef={focusedRef}
                    focusOnRef={focusOnRef}
                    form={form}
                    isClosedStatus={isClosedStatus}
                    isRetailOrder={isRetailOrder}
                    isRTN={isRTN}
                    isTaxPayer={isTaxPayer}
                    location={location}
                    managers={managers}
                    modals={modals}
                    onStatusChange={this.props.onStatusChange}
                    orderBloke={orderBloke}
                    orderId={orderId}
                    orderStatus={this.props.order.status || this.props.orderStatus}
                    remainPrice={remainPrice}
                    requisites={requisites}
                    retailEmployees={retailEmployees}
                    retailManagers={retailManagers}
                    schedule={schedule}
                    selectedClient={selectedClient}
                    servicesDiscount={servicesDiscount}
                    setModal={setModal}
                    stations={stations}
                    totalHours={totalHours}
                    totalPrice={totalSum}
                    totalSumWithTax={totalSumWithTax}
                    updateOrderField={this._updateOrderField}
                    user={user}
                    zeroStationLoadBeginDate={zeroStationLoadBeginDate}
                    zeroStationLoadBeginTime={zeroStationLoadBeginTime}
                    zeroStationLoadDuration={zeroStationLoadDuration}
                    zeroStationLoadStation={zeroStationLoadStation}
                />
                <OrderFormBody
                    businessLocations={businessLocations}
                    clientAggregateId={clientAggregateId}
                    clientEmail={clientEmail}
                    clientPhone={clientPhone}
                    clientsAggregates={this.props.clientsAggregates}
                    clientVehicle={clientVehicle}
                    createOrder={this.props.createOrder}
                    createStatus={this.props.createStatus}
                    errors={errors}
                    fetchedOrder={fetchedOrder}
                    fetchVehicles={fetchVehicles}
                    fields={orderFormBodyFields}
                    focusedRef={focusedRef}
                    focusOnRef={focusOnRef}
                    form={form}
                    handleSearchClientVehicles={handleSearchClientVehicles}
                    isClosedStatus={isClosedStatus}
                    isRetailOrder={isRetailOrder}
                    isRTN={isRTN}
                    location={location}
                    modals={modals}
                    onStatusChange={this.props.onStatusChange}
                    order={order}
                    orderBloke={orderBloke}
                    orderId={orderId}
                    orderStatus={this.props.order.status || this.props.orderStatus}
                    searchClientQuery={searchClientQuery}
                    searchClientsResult={searchClientsResult}
                    selectedClient={selectedClient}
                    setAddClientModal={setAddClientModal}
                    setClientSelection={cl => {
                        this.props.form.setFieldsValue({
                            clientVehicle: _.get(cl, 'id'),
                            clientPhone: _.get(cl, 'phones[0]'),
                            clientAggregateId: _.get(cl, 'clientAggregateId')
                        });
                        setClientSelection(cl);
                    }}
                    setModal={setModal}
                    updateOrderField={this._updateOrderField}
                    user={user}
                    vehicles={vehicles}
                />
                <div id='OrderTabs'>{tabs}</div>
                <AddClientModal
                    addClientFormData={this.props.addClientFormData}
                    resetModal={this.props.resetModal}
                    searchQuery={searchClientQuery}
                    visible={this.props.modal}
                    wrappedComponentRef={this._saveFormRef}
                />
                <ToSuccessModal
                    businessRequisiteId={order.businessRequisiteId}
                    clientId={selectedClient.clientId}
                    isRTN={isRTN}
                    onStatusChange={this.props.onStatusChange}
                    orderId={orderId}
                    precision={paymentMethod === 'cash' ? 1 : 2}
                    remainPrice={remainPrice}
                    resetModal={this.props.resetModal}
                    visible={this.props.modal}
                    wrappedComponentRef={this._saveFormRef}
                />
                <EmployeeSubTasksModal />
                <OrderClientChangeModal onSubmit={() => fetchOrderForm(orderId)} orderId={orderId} />
                <RecommendationsToServicesModal
                    labors={[]}
                    modal={this.props.modal}
                    orderId={orderId}
                    recommendations={_.get(fetchedOrder, 'orderRecommendations', [])}
                    reloadOrderForm={this._reloadOrderForm}
                    resetModal={this.props.resetModal}
                    unitDefault={this.state.unitDefault}
                    vehicleId={_.get(formFieldsValues, 'clientVehicle')}
                />
            </React.Fragment>
        );
    }

    _renderTabs = formFieldsValues => {
        const fetchedOrder = this.state.fetchedOrder || this.props.fetchedOrder;
        if (!fetchedOrder || !this.props.allDetails) {
            return;
        }
        const { form, orderTasks, schedule, orderId, stationLoads } = this.props;
        const { formatMessage } = this.props.intl;
        const { getFieldDecorator } = this.props.form;

        const tecdocId = this._getTecdocId();

        // _.values(value).some(_.isNil) gets only filled rows
        const stationsCount = _.get(formFieldsValues, 'stationLoads', [])
            .filter(Boolean)
            .filter(value => !_.values(value).some(_.isNil));

        const comments = _.pick(formFieldsValues, ['comment', 'businessComment', 'vehicleCondition', 'recommendation']);

        const commentsCollection = _.values(comments);
        const commentsCount = commentsCollection.filter(Boolean).length;

        const clientVehicleId = _.get(formFieldsValues, 'clientVehicle');

        const {
            setModal,
            fetchOrderForm,
            resetModal,
            modal,
            // fetchOrderTask,
            orderTasksStats,
            fetchTecdocSuggestions,
            fetchTecdocDetailsSuggestions,
            clearTecdocSuggestions,

            addOrderForm,
            detailsSuggestionsFetching,
            suggestionsFetching,

            orderCalls,
            selectedClient,
            orderDiagnostic,
            allServices,
            allDetails,
            employees,
            detailsSuggestions,
            suggestions,
            user,
            stations,

            changeModalStatus,
            errors,
            location,

            storeProducts,
            setStoreProductsSearchQuery,

            normHourPrice,

            showOilModal,
            oilModalData,
            clearOilData,

            modals,
            scrollToMapId,
            scrollToMap,
            fetchRepairMapData,
            focusOnRef,
            showCahOrderModal,
            managers,
            selectedVehicle,
            isRetailOrder,
            isRTN,
            retailEmployees
        } = this.props;

        const orderBloke =
            _.get(formFieldsValues, 'orderBloke') || _.get(fetchedOrder, 'order.orderBloke') || 'VEHICLE';

        const orderFormTabsFields = _.pick(formFieldsValues, [
            'comment',
            'vehicleCondition',
            'businessComment',
            'recommendation',
            'stationLoads',
            'services',
            'clientVehicle',
            'employee',
            'details',
            'servicesDiscount',
            'detailsDiscount'
        ]);

        const beginDatetime =
            _.get(fetchedOrder, 'order.beginDatetime') ||
            (this._bodyUpdateIsForbidden() ? void 0 : _.get(location, 'state.beginDatetime'));

        const initialBeginDatetime = beginDatetime ? dayjs(beginDatetime).toISOString() : void 0;

        const firstStations = _.get(stations, '[0].num');

        const initialStation =
            _.get(fetchedOrder, 'order.stationNum') ||
            firstStations ||
            (this._bodyUpdateIsForbidden() ? void 0 : _.get(location, 'state.stationNum'));

        const order = _.get(fetchedOrder, 'order', {});
        const {
            totalSum,
            totalSumWithTax,
            isTaxPayer,
            servicesDiscount,
            servicesSum,
            servicesSumDiscount,
            servicesTotalSum,
            detailsDiscount,
            detailsSum,
            detailsSumDiscount,
            detailsTotalSum,
            laborTimeMultiplier,
            bodyId,
            status
        } = order;

        const orderServices = _.get(fetchedOrder, 'orderServices', []);
        const orderRecommendations = _.get(fetchedOrder, 'orderRecommendations', []);
        const orderLaborsRequest = _.get(fetchedOrder, 'orderLaborsRequest', []);
        const orderDetails = _.get(fetchedOrder, 'orderDetails', []);
        let totalDetailsProfit = detailsTotalSum;
        let totalServicesProfit = servicesTotalSum;

        orderDetails.forEach(({ purchasePrice, count, agreement }) => {
            if (agreement !== 'REJECTED') totalDetailsProfit -= purchasePrice * count;
        });
        orderServices.forEach(({ purchasePrice, count, agreement }) => {
            if (agreement !== 'REJECTED') totalServicesProfit -= purchasePrice * count;
        });

        // const selectedVehicle = _.get(selectedClient, 'vehicles', []).find(({ id }) => id === clientVehicleId);

        const clientVehicleVin = this.props.order.clientVehicleVin || _.get(selectedVehicle, 'vin');

        return !isRetailOrder ? (
            <OrderFormTabs
                addOrderForm={addOrderForm}
                allDetails={allDetails}
                bodyId={bodyId}
                changeModalStatus={changeModalStatus}
                clearOilData={clearOilData}
                clearTecdocDetailsSuggestions={clearTecdocDetailsSuggestions}
                clearTecdocSuggestions={clearTecdocSuggestions}
                clientId={selectedClient.clientId}
                clientVehicleId={clientVehicleId}
                clientVehicleVin={clientVehicleVin}
                commentsCount={commentsCount}
                countDetails={orderDetails.length}
                countServices={orderServices.length}
                defaultEmployeeId={order.employeeId}
                defaultResponsibleId={order.appurtenanciesResponsibleId}
                detailsSuggestionsFetching={detailsSuggestionsFetching}
                employees={employees}
                errors={errors}
                fetchedOrder={fetchedOrder}
                fetchOrderForm={fetchOrderForm}
                fetchRecommendedPrice={this.props.fetchRecommendedPrice}
                fetchRepairMapData={fetchRepairMapData}
                fetchTecdocDetailsSuggestions={fetchTecdocDetailsSuggestions}
                fetchTecdocSuggestions={fetchTecdocSuggestions}
                fields={orderFormTabsFields}
                focusOnRef={focusOnRef}
                form={form}
                formatMessage={formatMessage}
                getFieldDecorator={getFieldDecorator}
                initialBeginDatetime={initialBeginDatetime}
                initialStation={initialStation}
                isRetailOrder={isRetailOrder}
                laborTimeMultiplier={laborTimeMultiplier}
                managers={managers}
                modal={modal}
                modals={modals}
                normHourPrice={normHourPrice}
                oilModalData={oilModalData}
                onStatusChange={this.props.onStatusChange}
                orderBloke={orderBloke}
                orderCalls={orderCalls}
                orderDetails={orderDetails}
                orderDiagnostic={orderDiagnostic}
                orderId={orderId}
                orderLaborsRequest={orderLaborsRequest}
                orderRecommendations={orderRecommendations}
                orderServices={orderServices}
                orderStatus={this.props.order.status}
                orderTasks={orderTasks}
                orderTasksStats={orderTasksStats}
                priceDetails={detailsSum}
                priceServices={servicesSum}
                recommendedPrice={this.props.recommendedPrice}
                recommendedPriceLoading={this.props.recommendedPriceLoading}
                reloadOrderForm={this._reloadOrderForm}
                reloadOrderPageComponents={this.props.reloadOrderPageComponents}
                resetModal={resetModal}
                schedule={schedule}
                scrollToMap={scrollToMap}
                scrollToMapId={scrollToMapId}
                selectedClient={selectedClient}
                selectedVehicle={selectedVehicle}
                services={orderFormTabsFields.services || []}
                setModal={setModal}
                setStoreProductsSearchQuery={setStoreProductsSearchQuery}
                showCahOrderModal={showCahOrderModal}
                showOilModal={showOilModal}
                stationLoads={stationLoads}
                stations={stations}
                stationsCount={stationsCount}
                storeProducts={storeProducts}
                suggestions={suggestions}
                suggestionsFetching={suggestionsFetching}
                tecdocId={tecdocId}
                totalDetailsProfit={totalDetailsProfit}
                totalServicesProfit={totalServicesProfit}
                user={user}
                details={this.state.details}
                // fetchOrderTask={fetchOrderTask}
                detailsSuggestions={detailsSuggestions}
            />
        ) : (
            <RetailCheckTabs
                addOrderForm={addOrderForm}
                allDetails={allDetails}
                bodyId={bodyId}
                changeModalStatus={changeModalStatus}
                clearOilData={clearOilData}
                clearTecdocDetailsSuggestions={clearTecdocDetailsSuggestions}
                clearTecdocSuggestions={clearTecdocSuggestions}
                clientId={selectedClient.clientId}
                clientVehicleId={clientVehicleId}
                clientVehicleVin={clientVehicleVin}
                commentsCount={commentsCount}
                countDetails={orderDetails.length}
                countServices={orderServices.length}
                defaultEmployeeId={order.employeeId}
                defaultResponsibleId={order.appurtenanciesResponsibleId}
                detailsSuggestionsFetching={detailsSuggestionsFetching}
                employees={retailEmployees || employees}
                errors={errors}
                fetchedOrder={fetchedOrder}
                fetchOrderForm={fetchOrderForm}
                fetchRecommendedPrice={this.props.fetchRecommendedPrice}
                fetchRepairMapData={fetchRepairMapData}
                fetchTecdocDetailsSuggestions={fetchTecdocDetailsSuggestions}
                fetchTecdocSuggestions={fetchTecdocSuggestions}
                fields={orderFormTabsFields}
                focusOnRef={focusOnRef}
                form={form}
                formatMessage={formatMessage}
                getFieldDecorator={getFieldDecorator}
                initialBeginDatetime={initialBeginDatetime}
                initialStation={initialStation}
                isRetailOrder={isRetailOrder}
                isRTN={isRTN}
                laborTimeMultiplier={laborTimeMultiplier}
                managers={managers}
                modal={modal}
                modals={modals}
                normHourPrice={normHourPrice}
                oilModalData={oilModalData}
                onStatusChange={this.props.onStatusChange}
                orderBloke={orderBloke}
                orderCalls={orderCalls}
                orderDetails={orderDetails}
                orderDiagnostic={orderDiagnostic}
                orderId={orderId}
                orderLaborsRequest={orderLaborsRequest}
                orderRecommendations={orderRecommendations}
                orderServices={orderServices}
                orderStatus={status || this.props.order.status}
                orderTasks={orderTasks}
                orderTasksStats={orderTasksStats}
                priceDetails={detailsSum}
                priceServices={servicesSum}
                recommendedPrice={this.props.recommendedPrice}
                recommendedPriceLoading={this.props.recommendedPriceLoading}
                reloadOrderForm={this._reloadOrderForm}
                reloadOrderPageComponents={this.props.reloadOrderPageComponents}
                resetModal={resetModal}
                schedule={schedule}
                scrollToMap={scrollToMap}
                scrollToMapId={scrollToMapId}
                selectedClient={selectedClient}
                selectedVehicle={selectedVehicle}
                services={orderFormTabsFields.services || []}
                setModal={setModal}
                setStoreProductsSearchQuery={setStoreProductsSearchQuery}
                showCahOrderModal={showCahOrderModal}
                showOilModal={showOilModal}
                stationLoads={stationLoads}
                stations={stations}
                stationsCount={stationsCount}
                storeProducts={storeProducts}
                suggestions={suggestions}
                suggestionsFetching={suggestionsFetching}
                tecdocId={tecdocId}
                totalDetailsProfit={totalDetailsProfit}
                totalServicesProfit={totalServicesProfit}
                user={user}
                details={this.state.details}
                // fetchOrderTask={fetchOrderTask}
                detailsSuggestions={detailsSuggestions}
            />
        );
    };
}

export const OrderForm = props => {
    const [form] = Form.useForm();
    const [values, setValues] = useState({});

    const changeHandler = () => {
        setValues(form.getFieldsValue());
    };

    const debouncedChangeHandler = useMemo(() => {
        return _.debounce(changeHandler, 300);
    }, []);

    return (
        <Form
            ref={props.wrappedComponentRef}
            className={Styles.form}
            form={form}
            layout='horizontal'
            name='orderForm'
            onValuesChange={debouncedChangeHandler}
        >
            <OrderFormFields form={form} values={values} {...props} />
        </Form>
    );
};
