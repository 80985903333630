import {
    CommentOutlined,
    DeleteOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    SaveOutlined,
    SettingOutlined,
    UndoOutlined
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Drawer,
    Image,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Select,
    Space,
    Switch,
    Table,
    Tree,
    TreeSelect,
    message
} from 'antd';
import { Layout } from 'commons';
import { Barcode } from 'components';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { AddCommetModal, AddLaborModal } from './modals';
import Styles from './styles.m.css';

const { Option } = Select;

// Utility to build storeGroups tree
function buildStoreGroupsTree(storeGroups) {
    if (!Array.isArray(storeGroups)) return [];
    const loop = groups =>
        groups.map(g => {
            const children = g.childGroups?.length ? loop(g.childGroups) : [];

            return {
                key: g.id,
                title: `${g.name} (#${g.id})`,
                children
            };
        });

    return loop(storeGroups);
}

// Utility to build the masterLabors tree
function buildMasterLaborsTree(masterLabors) {
    if (!Array.isArray(masterLabors)) return [];

    const loop = groups =>
        groups.map(g => {
            const children = g.childGroups?.length ? loop(g.childGroups) : [];

            return {
                key: g.masterLaborId,
                title: `${g.defaultMasterLaborName} (#${g.masterLaborId})`,
                children
            };
        });

    return loop(masterLabors);
}

const LaborsPage = ({ location }) => {
    const intl = useIntl();
    const user = useSelector(state => state.auth);
    const isMobile = useSelector(state => state.ui.views.isMobile);

    // State for data and loading
    const [loading, setLoading] = useState(false);
    const [labors, setLabors] = useState([]);
    const [masterLabors, setMasterLabors] = useState([]);
    const [storeGroups, setStoreGroups] = useState([]);

    // For tree data
    const [masterLaborsTree, setMasterLaborsTree] = useState([]);
    const [storeGroupsTree, setStoreGroupsTree] = useState([]);

    // Pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [count, setCount] = useState(0);

    // Tree selected
    const [selectedMasterLaborId, setSelectedMasterLaborId] = useState(null);
    const [selectedStoreGroupId, setSelectedStoreGroupId] = useState(null);

    // Table row selection
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [allLinks, setAllLinks] = useState([]);
    const [helperDrawerOpen, setHelperDrawerOpen] = useState(false);

    // Modals
    const [visibleCommentModal, setVisibleCommentModal] = useState(false);
    const [laborInfo, setLaborInfo] = useState(null);
    const [visibleAddLaborModal, setVisibleAddLaborModal] = useState(false);

    // Additional references
    const nameInputRef = useRef(null);

    // Additional data (specifications, units, etc.)
    const [specifications, setSpecifications] = useState([]);
    const [units, setUnits] = useState([]);
    const [laborUnitId, setLaborUnitId] = useState([]);

    // Column filters
    const [filterSearch, setFilterSearch] = useState('');
    const [filterCrossId, setFilterCrossId] = useState('');
    const [filterPrice, setFilterPrice] = useState('');
    const [filterId, setFilterId] = useState(''); // for masterLaborId
    const [filterDetail, setFilterDetail] = useState(''); // for storeGroupId
    const [filterSpecification, setFilterSpecification] = useState('');
    const [filterServiceType, setFilterServiceType] = useState(''); // e.g. masterLaborName
    const [filters, setFilters] = useState();

    const [laborExist, setLaborExist] = useState(undefined);
    const [loadingModal, setLoadingModal] = useState(false);
    const [unitDefault, setUnitDefault] = useState([]);

    const disabled = !isGrantAccessed(user, grants.DIRECTORIES_JOBS, accesses.ROWO);

    const fetchLabors = async (overridePage = page, overridePageSize = pageSize) => {
        try {
            setLoading(true);
            const queryParams = {
                page: overridePage,
                pageSize: overridePageSize,
                all: true,
                masterLaborId: selectedMasterLaborId || undefined,
                storeGroupId: selectedStoreGroupId || undefined,
                search: filterSearch || undefined,
                crossId: filterCrossId || undefined,
                ...filters
                // We'll do the rest client-side for demonstration
            };
            const response = await fetchAPI('GET', 'labors', queryParams, null, {
                handleErrorInternally: true
            });

            response.labors.sort((a, b) =>
                a.masterLaborId < b.masterLaborId ? -1 : a.masterLaborId > b.masterLaborId ? 1 : 0);

            const enriched = response.labors.map((elem, index) => {
                return {
                    ...elem,
                    key: index,
                    laborCode: `${elem.masterLaborId}-${elem.storeGroupId}`,
                    price: elem.laborPrice?.price,
                    fixed: elem.laborPrice?.fixed,
                    normHours: elem.laborPrice?.normHours,
                    customName: elem.customName || elem.name,
                    laborUnitId: elem.laborUnitId || null
                };
            });

            setLabors(enriched);
            setCount(response.laborsCount);

            if (location?.state?.showForm && nameInputRef.current) {
                nameInputRef.current.focus();
            }
        } catch {
            // handle error
        } finally {
            setLoading(false);
            setSelectedRowKeys([]);
            setSelectedRows([]);
        }
    };

    const fetchData = useCallback(async () => {
        try {
            const masterLaborsResp = await fetchAPI('GET', 'labors/master');
            const masterLaborsTree = await fetchAPI('GET', 'labors/master', { makeTree: true });
            const storeGroupsResp = await fetchAPI('GET', 'store_groups');

            setMasterLabors(masterLaborsResp.masterLabors);
            setStoreGroups(storeGroupsResp);

            setMasterLaborsTree(buildMasterLaborsTree(masterLaborsTree.masterLabors));
            setStoreGroupsTree(buildStoreGroupsTree(storeGroupsResp));

            // fetchLabors(1, pageSize);

            const specs = await fetchAPI('GET', 'salary/specifications', { presetId: 4 });
            const allUnits = await fetchAPI('GET', 'business/measurement/units');

            const unitsSettings = await fetchAPI('GET', 'business/measurement/units/settings');
            setUnitDefault(unitsSettings || []);
            setSpecifications(specs);
            setUnits(allUnits);
        } catch (error) {
            // handle
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (location?.state && location?.state?.laborId) {
            setFilters(prev => ({
                ...prev,
                id: location.state.laborId.replace(/-/g, '')
            }));
        }
    }, [location.state]);

    useEffect(() => {
        const debounceFetch = _.debounce(() => {
            fetchLabors(1, pageSize);
        }, 500);
        debounceFetch();

        return () => debounceFetch.cancel();
    }, [filters, pageSize]);

    useEffect(() => {
        // re-fetch when user selects tree node
        fetchLabors(1, pageSize);
  }, [selectedMasterLaborId, selectedStoreGroupId]); // eslint-disable-line

    // useEffect(() => {
    //     // watch text filters
    //     const debounceFetch = _.debounce(() => {
    //         fetchLabors(1, pageSize);
    //     }, 500);
    //     debounceFetch();

    //     return () => debounceFetch.cancel();
    // }, [filterSearch, filterCrossId, pageSize]);

    const pagination = {
        current: page,
        pageSize,
        total: Math.ceil(count / pageSize) * pageSize,
        onChange: (newPage, newPageSize) => {
            setPage(newPage);
            setPageSize(newPageSize);
            fetchLabors(newPage, newPageSize);
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (keys, rows) => {
            setSelectedRowKeys(keys);
            setSelectedRows(rows);
        }
    };

    // const handleSearch = async confirm => {
    //     confirm();
    //     fetchLabors(1, pageSize);
    // };

    const getColumnSearchProps = useCallback(
        (dataIndex, placeholder) => {
            let filterComponent = (confirm, clearFilters) => (
                <div style={{ padding: 8 }}>
                    <Input
                        allowClear
                        onChange={e => {
                            // setSelectedKeys(e.target.value ? [e.target.value] : []);
                            if (dataIndex === 'id') {
                                setFilters(prev => ({
                                    ...prev,
                                    [dataIndex]: e.target.value.replace(/-/g, '')
                                }));
                            } else {
                                setFilters(prev => ({
                                    ...prev,
                                    [dataIndex]: e.target.value
                                }));
                            }
                        }}
                        // onPressEnter={() => handleSearch(confirm, dataIndex)}
                        placeholder={placeholder}
                        style={{ marginBottom: 8, display: 'block' }}
                        value={filters && filters[dataIndex]}
                    />
                </div>
            );

            if (dataIndex === 'specificationId') {
                filterComponent = (confirm, clearFilters) => (
                    <Select
                        allowClear
                        onChange={value => {
                            setFilters(prev => ({
                                ...prev,
                                [dataIndex]: value
                            }));
                        }}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={filters && filters[dataIndex]}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={intl.formatMessage({
                            id: 'search'
                        })}
                    >
                        {specifications?.map(sp => (
                            <Option key={sp.specificationId} value={sp.specificationId}>
                                {sp.specificationName}
                            </Option>
                        ))}
                    </Select>
                );
            }

            return {
                filterDropdown: ({ confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>{filterComponent(confirm, clearFilters)}</div>
                ),
                filtered: filters && filters[dataIndex],
                onFilter: (value, record) => {
                    if (dataIndex === 'price') {
                        return record.price?.toString().includes(value);
                    }
                    if (dataIndex === 'crossId') {
                        return record.crossId?.toString().toLowerCase().includes(value.toLowerCase());
                    }
                    if (dataIndex === 'customName') {
                        return record.customName?.toLowerCase().includes(value.toLowerCase());
                    }
                    if (dataIndex === 'masterLaborId') {
                        return record.masterLaborId?.toString().includes(value);
                    }
                    if (dataIndex === 'storeGroupId') {
                        return record.storeGroupId?.toString().includes(value);
                    }
                    if (dataIndex === 'specificationId') {
                        return (record.specificationId || '').toString().includes(value);
                    }
                    if (dataIndex === 'masterLaborName') {
                        return (record.masterLaborName || '').toLowerCase().includes(value.toLowerCase());
                    }

                    return false;
                }
            };
        },
        [filters, intl, specifications]
    );

    // const getColumnSearchProps = (dataIndex, placeholder) => ({
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    //         let filterComponent = (confirm, clearFilters) => (
    //             <div style={{ padding: 8 }}>
    //                 <Input
    //                     allowClear
    //                     onChange={e => {
    //                         setSelectedKeys(e.target.value ? [e.target.value] : []);
    //                         setFilters(prev => ({
    //                             ...prev,
    //                             [dataIndex]: e.target.value
    //                         }));
    //                     }}
    //                     onPressEnter={() => handleSearch(confirm, dataIndex)}
    //                     placeholder={placeholder}
    //                     style={{ marginBottom: 8, display: 'block' }}
    //                     value={filters && filters[dataIndex]}
    //                 />
    //             </div>
    //         );

    //         if (dataIndex === 'specificationId') {
    //             filterComponent = (confirm, clearFilters) => (
    //                 <Select
    //                     allowClear
    //                     alue={filters && filters[dataIndex]}
    //                     mode='multiple'
    //                     onChange={value => {
    //                         setFilters(prev => ({
    //                             ...prev,
    //                             [dataIndex]: value
    //                         }));
    //                     }}
    //                     showSearch
    //                     style={{ marginBottom: 8, display: 'block', width: 180 }}
    //                     value={filters[dataIndex]}
    //                     optionFilterProp='children'
    //                     // mode='multiple'
    //                     placeholder={intl.formatMessage({
    //                         id: 'search'
    //                     })}
    //                 >
    //                     {specifications?.map(sp => (
    //                         <Option key={sp.specificationId} value={sp.specificationId}>
    //                             {sp.specificationName}
    //                         </Option>
    //                     ))}
    //                 </Select>
    //             );
    //         }

    //         return filterComponent;
    //     },
    //     // filterIcon: filtered => renderFilterIcon(filtered),
    //     filtered: filters && filters[dataIndex],
    //     onFilter: (value, record) => {
    //         if (dataIndex === 'price') {
    //             return record.price?.toString().includes(value);
    //         }
    //         if (dataIndex === 'crossId') {
    //             return record.crossId?.toString().toLowerCase().includes(value.toLowerCase());
    //         }
    //         if (dataIndex === 'customName') {
    //             return record.customName?.toLowerCase().includes(value.toLowerCase());
    //         }
    //         if (dataIndex === 'masterLaborId') {
    //             return record.masterLaborId?.toString().includes(value);
    //         }
    //         if (dataIndex === 'storeGroupId') {
    //             return record.storeGroupId?.toString().includes(value);
    //         }
    //         if (dataIndex === 'specificationId') {
    //             return (record.specificationId || '').toString().includes(value);
    //         }
    //         if (dataIndex === 'masterLaborName') {
    //             return (record.masterLaborName || '').toLowerCase().includes(value.toLowerCase());
    //         }

    //         return false;
    //     }
    // });

    const handleSaveLabors = async () => {
        const toUpdate = labors
            .filter(l => l.changed && !l.new)
            .map(elem => ({
                id: elem.id,
                masterLaborId: elem.masterLaborId,
                storeGroupId: elem.storeGroupId,
                disabled: Boolean(elem.disabled),
                crossId: elem.crossId || null,
                name: elem.customName,
                fixed: Boolean(elem.fixed),
                price: elem.price || 1,
                normHours: elem.normHours || 1,
                specificationId: elem.specificationId || null,
                laborUnitId: elem.laborUnitId || null
            }));

        const toCreate = labors
            .filter(l => l.new && l.masterLaborId && l.storeGroupId)
            .map(elem => ({
                id: `${elem.masterLaborId}${elem.storeGroupId}`,
                masterLaborId: elem.masterLaborId,
                storeGroupId: elem.storeGroupId,
                disabled: Boolean(elem.disabled),
                crossId: elem.crossId || null,
                name: elem.customName,
                fixed: Boolean(elem.fixed),
                price: elem.price || 1,
                normHours: elem.normHours || 1,
                specificationId: elem.specificationId || null,
                laborUnitId: elem.laborUnitId || null
            }));

        if (toCreate.length) {
            await fetchAPI('PUT', '/labors', null, toCreate);
        }
        if (toUpdate.length) {
            await fetchAPI('PUT', '/labors', null, toUpdate);
        }

        fetchLabors(page, pageSize);
    };

    const updateRowFields = useCallback(
        (id, field, value) => {
            if (!selectedRows.length) {
                setLabors(prevData => {
                    return prevData.map(row => (row.id === id ? { ...row, [field]: value, changed: true } : row));
                });
            } else {
                setLabors(prevData => {
                    const hasSelectedRows = selectedRows.length > 0;

                    return prevData.map(row =>
                        hasSelectedRows && selectedRows.some(selected => selected.id === row.id)
                            ? { ...row, [field]: value, changed: true }
                            : !hasSelectedRows && row.id === id
                            ? { ...row, [field]: value, changed: true }
                            : row
                    );
                });
            }
        },
        [setLabors, selectedRows]
    );

    const columns = useMemo(() => {
        return [
            {
                title: <FormattedMessage id='order_form_table.labors_code' />,
                dataIndex: 'laborCode',
                key: 'laborCode',
                ...getColumnSearchProps('id', intl.formatMessage({ id: 'order_form_table.labors_code' })),
                render: (data, row) => (
                    <div style={{ display: 'flex' }}>
                        <Barcode
                            disabled={disabled}
                            enableScanIcon
                            iconStyle={{ margin: '0 8px 0 0', fontSize: 18, verticalAlign: 'sub' }}
                            onConfirm={(code, pref, fullCode) => {
                                row.barcode = fullCode;
                            }}
                            prefix='LBS'
                            referenceId={row.id}
                            table='LABORS'
                            value={row.barcode}
                        />
                        {data}
                    </div>
                )
            },
            // {
            //     title: 'ID',
            //     dataIndex: 'masterLaborId',
            //     key: 'masterLaborId',
            //     ...getColumnSearchProps(
            //         'masterLaborId',
            //         intl.formatMessage({ id: 'order_form_table.id_placeholder', defaultMessage: 'ID' })
            //     ),
            //     filteredValue: filterId ? [filterId] : null,
            //     onFilter: (value, record) => record.masterLaborId?.toString().includes(value),
            //     render: (masterLaborId, row) => {
            //         if (!row.new) {
            //             return <span>{masterLaborId}</span>;
            //         }

            //         // If new labor, let user choose from <Select> or something.
            //         return (
            //             <Select
            //                 disabled={disabled}
            //                 onChange={val => {
            //                     row.changed = true;
            //                     row.masterLaborId = val;
            //                 }}
            //                 placeholder='ID'
            //                 style={{ width: '100px' }}
            //                 value={row.masterLaborId || undefined}
            //             >
            //                 {masterLabors?.map(ml => (
            //                     <Option key={ml.masterLaborId} value={ml.masterLaborId}>
            //                         {ml.defaultMasterLaborName}
            //                     </Option>
            //                 ))}
            //             </Select>
            //         );
            //     }
            // },
            {
                title: <FormattedMessage id='order_form_table.external_id' />,
                // dataIndex: 'crossId',
                key: 'crossId',
                ...getColumnSearchProps('crossId', intl.formatMessage({ id: 'order_form_table.external_id' })),
                filteredValue: filterCrossId ? [filterCrossId] : null,
                onFilter: (value, record) => record.crossId?.toString().toLowerCase().includes(value.toLowerCase()),
                render: (value, row) => (
                    <Input
                        disabled={disabled}
                        onChange={async e => {
                            await updateRowFields(row.id, 'crossId', e.target.value);
                            // row.changed = true;
                            // row.crossId = e.target.value;
                        }}
                        style={{ width: 100 }}
                        value={row.crossId}
                    />
                )
            },
            // {
            //     title: <FormattedMessage id='order_form_table.detail_code' />,
            //     dataIndex: 'storeGroupId',
            //     key: 'storeGroupId',
            //     ...getColumnSearchProps(
            //         'storeGroupId',
            //         intl.formatMessage({ id: 'order_form_table.detail_code', defaultMessage: 'Detail Code' })
            //     ),
            //     filteredValue: filterDetail ? [filterDetail] : null,
            //     onFilter: (value, record) => record.storeGroupId?.toString().includes(value),
            //     render: (storeGroupId, row) => {
            //         if (!row.new) {
            //             return <span>{storeGroupId}</span>;
            //         }

            //         return (
            //             <TreeSelect
            //                 disabled={disabled}
            //                 dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            //                 onChange={val => {
            //                     row.changed = true;
            //                     row.storeGroupId = val;
            //                 }}
            //                 placeholder={intl.formatMessage({ id: 'order_form_table.detail_code' })}
            //                 showSearch
            //                 style={{ width: 150 }}
            //                 treeData={storeGroupsTree}
            //                 value={row.storeGroupId || undefined}
            //             />
            //         );
            //     }
            // },
            {
                title: <FormattedMessage id='order_form_table.service_type' />,
                dataIndex: 'masterLaborName',
                key: 'masterLaborName',
                ...getColumnSearchProps(
                    'masterLaborName',
                    intl.formatMessage({ id: 'order_form_table.service_type', defaultMessage: 'Service Type' })
                ),
                filteredValue: filterServiceType ? [filterServiceType] : null,
                onFilter: (value, record) => (record.masterLaborName || '').toLowerCase().includes(value.toLowerCase()),
                render: val => <span>{val}</span>
            },
            {
                title: <FormattedMessage id='services_table.short_specification' />,
                dataIndex: 'specificationId',
                key: 'specificationId',
                ...getColumnSearchProps(
                    'specificationId',
                    intl.formatMessage({
                        id: 'services_table.specification_placeholder',
                        defaultMessage: 'Specification'
                    })
                ),
                filteredValue: filterSpecification ? [filterSpecification] : null,
                onFilter: (value, record) => (record.specificationId || '').toString().includes(value),
                render: (specId, row) => (
                    <Select
                        disabled={disabled}
                        onChange={val => {
                            row.changed = true;
                            row.specificationId = val;
                        }}
                        style={{ width: 120 }}
                        value={specId || undefined}
                    >
                        {specifications?.map(sp => (
                            <Option key={sp.specificationId} value={sp.specificationId}>
                                {sp.specificationName}
                            </Option>
                        ))}
                    </Select>
                )
            },
            {
                title: <FormattedMessage id='storage.name' />,
                // dataIndex: 'customName',
                key: 'customName',
                ...getColumnSearchProps('search', intl.formatMessage({ id: 'name' })),
                filteredValue: filterSearch ? [filterSearch] : null,
                onFilter: (value, record) => record.customName?.toLowerCase().includes(value.toLowerCase()),
                render: (text, row) => (
                    <Input
                        ref={row.key === 0 ? nameInputRef : null}
                        disabled={disabled}
                        onChange={async e => {
                            await updateRowFields(row.id, 'customName', e.target.value);
                        }}
                        style={{ width: 320 }}
                        value={row.customName}
                    />
                )
            },
            {
                title: () => {
                    return (
                        <div>
                            <p>
                                <FormattedMessage id='labor.show' />
                            </p>
                            <p>
                                <Switch
                                    disabled={disabled}
                                    onClick={async value => {
                                        await updateRowFields(_, 'disabled', value);
                                    }}
                                />
                            </p>
                        </div>
                    );
                },
                dataIndex: 'disabled',
                key: 'disabled',
                render: (val, row) => (
                    <Switch
                        checked={!row.disabled}
                        disabled={!disabled}
                        onClick={async value => {
                            await updateRowFields(row.id, 'disabled', value);
                            // row.disabled = value;
                            // row.changed = true;
                        }}
                    />
                )
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='services_table.short_units_placeholder' />
                            <Select
                                allowClear
                                disabled={disabled}
                                dropdownMatchSelectWidth={70}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={async value => {
                                    // await handleSaveSelectedRows(
                                    //     selectedRows.map(record => ({
                                    //         ...record,
                                    //         laborUnitId: value
                                    //     }))
                                    // );

                                    updateRowFields(_, 'laborUnitId', value);

                                    // await handleSaveLabors();
                                }}
                                optionFilterProp='children'
                                placeholder={intl.formatMessage({
                                    id: 'services_table.units'
                                })}
                                showSearch
                                style={{ maxWidth: '100px' }}
                                // value={laborUnitId}
                            >
                                {(units || []).map((elem, index) => (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.shortcut}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    );
                },
                dataIndex: 'laborUnitId',
                key: 'laborUnitId',
                render: (laborUnitId, row) => (
                    <Select
                        disabled={disabled}
                        onChange={val => {
                            updateRowFields(row.id, 'laborUnitId', val);
                        }}
                        style={{ width: 90 }}
                        value={laborUnitId}
                    >
                        {units?.map(u => (
                            <Option key={u.id} value={u.id}>
                                {u.shortcut}
                            </Option>
                        ))}
                    </Select>
                )
            },
            {
                title: () => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <FormattedMessage id='order_form_table.fixed' />
                        <Checkbox
                            onChange={event => {
                                updateRowFields(_, 'fixed', event.target.checked);
                            }}
                        />
                    </div>
                ),
                // dataIndex: 'fixed',
                key: 'fixed',
                align: 'center',
                render: (val, row) => (
                    <Checkbox
                        checked={row.fixed}
                        disabled={disabled}
                        onChange={async event => {
                            await updateRowFields(row.id, 'fixed', !row.fixed);
                        }}
                    />
                )
            },
            {
                title: () => {
                    return (
                        <div>
                            <p>
                                <FormattedMessage id='hours' />
                            </p>
                            <p>
                                <InputNumber
                                    decimalSeparator=','
                                    defaultValue={1}
                                    disabled={disabled}
                                    min={0.1}
                                    onChange={async val => {
                                        await updateRowFields(_, 'normHours', val);
                                    }}
                                    step={0.2}
                                    style={{ color: 'var(--text)' }}
                                />
                            </p>
                        </div>
                    );
                },
                dataIndex: 'normHours',
                key: 'normHours',
                render: (val, row) => (
                    <InputNumber
                        // defaultValue={val || 1}
                        decimalSeparator=','
                        disabled={row.fixed || disabled}
                        min={0.1}
                        onChange={async val => {
                            await updateRowFields(row.id, 'normHours', val);

                            // row.changed = true;
                            // row.normHours = value;
                        }}
                        step={0.2}
                        value={row.normHours}
                    />
                )
            },
            {
                title: () => {
                    return (
                        <div>
                            <p>
                                <FormattedMessage id='order_form_table.price' />
                            </p>
                            <p>
                                <InputNumber
                                    decimalSeparator=','
                                    defaultValue={1}
                                    disabled={disabled}
                                    min={1}
                                    onChange={async val => {
                                        await updateRowFields(_, 'price', val);
                                    }}
                                    style={{ color: 'var(--text)' }}
                                />
                            </p>
                        </div>
                    );
                },
                dataIndex: 'price',
                key: 'price',
                ...getColumnSearchProps('price', intl.formatMessage({ id: 'order_form_table.price' })),
                filteredValue: filterPrice ? [filterPrice] : null,
                onFilter: (value, record) => record.price?.toString().includes(value),
                render: (value, row) => (
                    <InputNumber
                        decimalSeparator=',' // example if needed
                        // defaultValue={value || 1}
                        disabled={!row.fixed || disabled}
                        min={1}
                        onChange={async val => {
                            await updateRowFields(row.id, 'price', val);
                            // row.changed = true;
                            // row.price = val;
                        }}
                        value={row.price}
                    />
                )
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'comment',
                render: row => (
                    <Button
                        disabled={disabled}
                        icon={<CommentOutlined />}
                        onClick={() => {
                            setVisibleCommentModal(true);
                            setLaborInfo(row);
                        }}
                    />
                )
            },
            {
                title: () => (
                    <div>
                        <Popconfirm
                            disabled={disabled}
                            onConfirm={async () => {
                                await fetchAPI(
                                    'PUT',
                                    `labors/reset?laborIds=[${selectedRows.map(({ id }) => id).join(',')}]`
                                );
                                fetchLabors();
                            }}
                            title={<FormattedMessage id='add_order_form.delete_confirm' />}
                        >
                            <Button disabled={disabled} style={{ padding: '0px 8px' }}>
                                <UndoOutlined />
                                <span style={{ marginLeft: 4 }}>|</span>
                                <DeleteOutlined style={{ color: 'red', marginLeft: 4 }} />
                            </Button>
                        </Popconfirm>
                    </div>
                ),
                key: 'delete',
                render: row => {
                    const buttonType = row.masterLaborId >= 9000 ? 'danger' : 'primary';

                    return (
                        <Popconfirm
                            disabled={disabled}
                            onConfirm={async () => {
                                await fetchAPI('PUT', `labors/reset?laborIds=[${row.id}]`);
                                fetchLabors(page, pageSize);
                            }}
                            title={<FormattedMessage id='add_order_form.delete_confirm' />}
                        >
                            <Button disabled={disabled} icon={<DeleteOutlined />} type={buttonType} />
                        </Popconfirm>
                    );
                }
            }
        ];
    }, [
        getColumnSearchProps,
        intl,
        filterCrossId,
        filterServiceType,
        filterSpecification,
        filterSearch,
        filterPrice,
        disabled,
        specifications,
        selectedRows,
        units,
        updateRowFields,
        fetchLabors,
        page,
        pageSize
    ]);

    // onSelect tree
    const onSelectMasterLabor = selectedKeys => {
        const key = selectedKeys[0] || null;
        setSelectedMasterLaborId(key);
    };

    const onSelectStoreGroup = selectedKeys => {
        const key = selectedKeys[0] || null;
        setSelectedStoreGroupId(key);
    };

    const updatePrice = async () => {
        try {
            const url = `${__API_URL__}/labors/recalc_prices`;
            const token = localStorage.getItem('_my.carbook.pro_token');

            await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: token
                }
            });

            message.success(intl.formatMessage({ id: 'barcode.success' }));
            fetchLabors();
        } catch (error) {
            message.error('Цена не задана!');
        }
    };

    const fetchHelperLinks = async () => {
        try {
            const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_labors' });
            setAllLinks(links);
        } catch {
            // handle
        }
    };

    const findExistingLabors = async (storeGrpId, masterLabId) => {
        try {
            setLoadingModal(true);
            const response = await fetchAPI(
                'GET',
                'labors',
                {
                    storeGroupId: storeGrpId,
                    masterLaborId: masterLabId
                },
                null,
                {
                    handleErrorInternally: true
                }
            );
            setLoadingModal(false);

            return response.labors;
        } catch (err) {
            setLoadingModal(false);

            return [];
        }
    };

    console.log(labors, 'labros');

    return (
        <Layout
            controls={
                isGrantAccessed(user, grants.DIRECTORIES_JOBS, accesses.ROWO) && (
                    <Space>
                        <Button icon={<PlusOutlined />} onClick={() => setVisibleAddLaborModal(true)} />
                        <NormHourPriceModal updatePrice={updatePrice} />
                        <Button onClick={updatePrice}>
                            <FormattedMessage id='update_price' />
                        </Button>
                        <Button onClick={handleSaveLabors} type='primary'>
                            {isMobile ? <SaveOutlined /> : <FormattedMessage id='save' />}
                        </Button>
                        <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={() => {
                                setHelperDrawerOpen(true);
                                fetchHelperLinks();
                            }}
                            type='text'
                        />
                    </Space>
                )
            }
            title={<FormattedMessage id='navigation.labors_page' />}
        >
            <div style={{ display: 'flex' }}>
                <div style={{ width: 250, marginRight: 16 }}>
                    <h3>{/* <FormattedMessage id='master_labor_tree' /> */}</h3>
                    <Tree defaultExpandAll onSelect={onSelectMasterLabor} treeData={masterLaborsTree} />
                    <h3 style={{ marginTop: 20 }}>{/* <FormattedMessage id='store_group_tree' /> */}</h3>
                    <Tree defaultExpandAll onSelect={onSelectStoreGroup} treeData={storeGroupsTree} />
                </div>
                <div style={{ flex: 1 }}>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={labors.filter(item => !item.deleted)}
                        loading={loading}
                        locale={{ emptyText: <FormattedMessage id='no_data' /> }}
                        pagination={pagination}
                        rowSelection={rowSelection}
                        scroll={{ x: 1400 }}
                        size='small'
                    />
                </div>
            </div>

            <AddLaborModal
                fetchLabors={() => fetchLabors(page, pageSize)}
                findExistingLabors={findExistingLabors}
                hideModal={() => setVisibleAddLaborModal(false)}
                laborExist={laborExist}
                loadingModal={loadingModal}
                location={location}
                unitDefault={unitDefault}
                visible={visibleAddLaborModal}
            />

            <AddCommetModal
                fetchLabors={() => fetchLabors(page, pageSize)}
                hideModal={() => setVisibleCommentModal(false)}
                laborInfo={laborInfo}
                visible={visibleCommentModal}
            />

            <Drawer
                onClose={() => setHelperDrawerOpen(false)}
                open={helperDrawerOpen}
                title={<FormattedMessage id='navigation.helper' />}
                width={420}
            >
                <div>
                    {allLinks.map(({ ogUrl, ogTitle, ogDescription, ogImage }, index) => {
                        const imageSrc = _.isArray(ogImage) ? _.get(ogImage, '[0].url', '') : _.get(ogImage, 'url', '');

                        return (
                            <div key={ogUrl} className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image src={imageSrc} />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button style={{ width: '100%' }} type='primary'>
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </Drawer>
        </Layout>
    );
};

export default LaborsPage;
/**
 * Modal component that allows users to update the norm hour price and region,
 * then recalculate labor prices.
 */
const NormHourPriceModal = ({ updatePrice }) => {
    const intl = useIntl();
    const isMobile = useSelector(state => state.ui.views.isMobile);

    const [normHourPrice, setNormHourPrice] = useState(300);
    const [visible, setVisible] = useState(false);
    const [region, setRegion] = useState(null);
    const [regions, setRegions] = useState([]);

    const headerIconStyle = {
        fontSize: 24,
        cursor: 'pointer'
    };

    const buildRegionsTree = regionsInput => {
        if (!regionsInput || !Array.isArray(regionsInput)) return [];

        return regionsInput.map(parent => {
            const childrenLevel1 =
                parent.childs?.map(child => {
                    const childrenLevel2 =
                        child.childs?.map(lastNode => {
                            const childrenLevel3 =
                                lastNode.childs?.map(elem => ({
                                    title: `${elem.name} (#${elem.id})`,
                                    name: elem.singleName,
                                    value: elem.id,
                                    key: elem.id
                                })) || [];

                            return {
                                title: `${lastNode.name} (#${lastNode.id})`,
                                name: lastNode.singleName,
                                value: lastNode.id,
                                key: lastNode.id,
                                children: childrenLevel3
                            };
                        }) || [];

                    return {
                        title: `${child.name} (#${child.id})`,
                        name: child.singleName,
                        value: child.id,
                        key: child.id,
                        children: childrenLevel2
                    };
                }) || [];

            return {
                title: `${parent.name} (#${parent.id})`,
                name: parent.name,
                value: parent.id,
                key: parent.id,
                children: childrenLevel1
            };
        });
    };

    const handleOk = async () => {
        await fetchAPI('PUT', 'businesses', undefined, {
            normHourPrice,
            region
        });
        handleCancel();
        updatePrice();
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const fetchInitialData = useCallback(async () => {
        try {
            const businessData = await fetchAPI('GET', 'business');
            if (businessData?.normHourPrice) {
                setNormHourPrice(Number(businessData.normHourPrice));
            }
            if (businessData?.region) {
                setRegion(businessData.region);
            }

            const regionTree = await fetchAPI('GET', 'regions_tree');
            setRegions(buildRegionsTree(regionTree));
        } catch (error) {
            // handle error if needed
        }
    }, []);

    useEffect(() => {
        fetchInitialData();
    }, [fetchInitialData]);

    return (
        <React.Fragment>
            <SettingOutlined
                onClick={() => {
                    setVisible(true);
                }}
                style={headerIconStyle}
                title={intl.formatMessage({ id: 'navigation.settings' })}
            />

            <Modal
                maskClosable={false}
                onCancel={handleCancel}
                onOk={handleOk}
                title={<FormattedMessage id='navigation.settings' />}
                visible={visible}
                width='fit-content'
            >
                <div className={isMobile ? Styles.formItemMobile : Styles.formItem}>
                    <FormattedMessage id='norm_hour_price' />
                    <InputNumber
                        decimalSeparator=','
                        min={0}
                        onChange={val => {
                            setNormHourPrice(val);
                        }}
                        style={{ marginLeft: 8 }}
                        value={normHourPrice}
                    />
                </div>
                <div className={isMobile ? Styles.formItemMobile : Styles.formItem}>
                    <FormattedMessage id='locations.region' />
                    <TreeSelect
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999' }}
                        filterTreeNode={(input, node) =>
                            node.title.toLowerCase().includes(input.toLowerCase()) ||
                            String(node.value).includes(input.toLowerCase())
                        }
                        onSelect={value => {
                            setRegion(value);
                        }}
                        showSearch
                        style={{ width: '60%' }}
                        treeData={regions}
                        treeDefaultExpandedKeys={[region]}
                        value={region}
                    />
                </div>
            </Modal>
        </React.Fragment>
    );
};
