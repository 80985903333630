import { Button, ColorPicker, Input, Select, Tabs, Upload, notification } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import imageCompression from 'browser-image-compression';
import { Layout } from 'commons';
import { updateUser } from 'core/auth/duck';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import { fetchAPI, getToken } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;

const defaultColors = {
    primary: 'rgb(155, 89, 182)',
    secondary: 'rgb(81, 205, 102)',
    layout: 'rgba(0, 21, 41, 1)',
    link: 'rgb(82, 179, 255)',
    moduleHeader: 'rgba(247, 247, 247, 1)',
    businessName: 'rgb(128, 128, 128)',
    not_complete: 'rgb(255, 126, 126)',
    required: 'rgb(255, 126, 126)',
    reserve: 'rgb(107, 99, 153)',
    approve: 'rgb(251, 158, 62)',
    progress: 'rgb(62, 186, 162)',
    success: 'rgb(26, 156, 234)',
    cancel: 'rgb(198, 198, 198)',
    invite: 'rgb(143, 56, 48)',
    disabled: 'rgb(255, 126, 126)',
    enabled: 'rgb(62, 186, 162)',
    db_comment: 'rgb(255, 255, 205)',
    db_not_complete: 'rgb(250, 175, 175)',
    db_required: 'rgb(250, 175, 175)',
    db_reserve: 'rgb(210, 190, 230)',
    db_approve: 'rgb(255, 240, 180)',
    db_progress: 'rgb(200, 225, 180)',
    db_success: 'rgb(180, 200, 230)',
    db_cancel: 'rgb(198, 198, 198)',
    db_invite: 'rgb(143, 56, 48)'
};

const colorSchemes = [
    {
        title: 'Carbook',
        palette: {
            ...defaultColors,
            primary: 'rgb(155, 89, 182)',
            secondary: 'rgb(81, 205, 102)',
            layout: 'rgba(0, 21, 41, 1)',
            link: 'rgb(82, 179, 255)',
            moduleHeader: 'rgba(247, 247, 247, 1)'
        }
    },
    {
        title: 'Autodoc',
        palette: {
            ...defaultColors,
            primary: '#0263b6',
            secondary: '#8bc34a',
            layout: '#548ade',
            link: 'rgba(255, 82, 85, 1)',
            moduleHeader: 'rgba(247, 247, 247, 1)'
        }
    },
    {
        title: 'Autodoc Germany',
        palette: {
            ...defaultColors,
            primary: '#0068d7',
            secondary: '#f85a00',
            layout: '#131c20',
            link: 'rgba(255, 186, 150, 1)',
            moduleHeader: '#ffffff'
        }
    },
    {
        title: 'Intercars',
        palette: {
            ...defaultColors,
            primary: '#ca4353',
            secondary: 'rgb(81, 205, 102)',
            layout: '#292f35',
            link: '#ca4353',
            moduleHeader: 'rgba(247, 247, 247, 1)'
        }
    },
    {
        title: 'Omega',
        palette: {
            ...defaultColors,
            primary: '#3f51b5',
            secondary: 'rgb(81, 205, 102)',
            layout: '#435784',
            link: '#03a9f4',
            moduleHeader: 'rgba(247, 247, 247, 1)'
        }
    },
    {
        title: 'ELIT',
        palette: {
            ...defaultColors,
            primary: 'rgb(204 84 78)',
            secondary: 'rgb(81, 205, 102)',
            layout: '#005591',
            link: '#2196f3',
            moduleHeader: 'rgba(247, 247, 247, 1)'
        }
    },
    {
        title: 'PRO-STO',
        palette: {
            ...defaultColors,
            primary: '#C21009',
            secondary: '#C21009',
            layout: '#272728',
            link: '#DF170F',
            moduleHeader: '#F7F7F7',
            not_complete: '#AC0A04',
            required: '#AC0A04',
            progress: '#48BA3E',
            disabled: '#E92626',
            enabled: '#4CA74A',
            db_not_complete: '#EF8585',
            db_required: '#EF8585'
        }
    },
    {
        title: 'PRO-STO (OLD)',
        palette: {
            ...defaultColors,
            primary: 'rgb(218 104 98)',
            secondary: 'rgb(218 104 98)',
            layout: '#373737',
            link: '#FF9800',
            moduleHeader: 'rgba(247, 247, 247, 1)'
        }
    }
];

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    updateUser
};

const ThemeSettingsPage = ({ intl, user, updateUser }) => {
    const [loading, setLoading] = useState(false);
    const [activeKey, setActiveKey] = useState('logo');
    const [palette, setPalette] = useState(user.palette);
    const [logo, setLogo] = useState([]);
    const [logoSecondary, setLogoSecondary] = useState([]);
    const [alphaName, setAlphaName] = useState('');
    const [telegramName, setTelegramName] = useState('');
    const [domain, setDomain] = useState('');

    useEffect(() => {
        const { primaryLogoUrl, primaryHash, secondaryLogoUrl, secondaryHash } = user;
        setLogo({
            key: primaryHash,
            uid: primaryHash,
            status: 'done',
            previewUrl: primaryLogoUrl,
            url: primaryLogoUrl,
            name: 'ddd',
            id: 1,
            thumbUrl: primaryLogoUrl
        });
        setLogoSecondary({
            key: secondaryHash,
            uid: secondaryHash,
            status: 'done',
            previewUrl: secondaryLogoUrl,
            url: secondaryLogoUrl,
            name: 'ddd',
            id: 1,
            thumbUrl: secondaryLogoUrl
        });
    }, [user]);

    const fetchData = async () => {
        const alphaName = await fetchAPI('GET', 'business', undefined, undefined, {
            handleErrorInternally: true
        });
        const domain = await fetchAPI('GET', 'business/domain', undefined, undefined, {
            handleErrorInternally: true
        });
        setAlphaName(alphaName ? alphaName.alphaName : undefined);
        setDomain(domain ? domain.domain : undefined);
    };

    const saveAlpha = async () => {
        await fetchAPI(
            'PUT',
            'businesses',
            undefined,
            {
                alphaName: alphaName || null
            },
            { handleErrorInternally: true }
        );
        await notification.success({
            message: intl.formatMessage({ id: 'sms_settings.saved' })
        });
        await fetchData();
    };

    const handleThemeUpdate = async () => {
        if (activeKey === 'personalPrintedDocuments') {
            await saveAlpha();

            return;
        }
        try {
            await fetchAPI(
                'PUT',
                'businesses',
                null,
                { palette },
                {
                    handleErrorInternally: true
                }
            );
            updateUser({
                ...user,
                palette
            });
            notification.success({
                message: intl.formatMessage({
                    id: 'theme_settings.save_success'
                })
            });
        } catch (e) {
            notification.error({
                message: intl.formatMessage({
                    id: 'subscription.cashless_error'
                })
            });
        }
    };

    const fetchLogo = async () => {
        const { primaryLogoUrl, primaryHash, secondaryLogoUrl, secondaryHash } = await fetchAPI(
            'GET',
            'business',
            null,
            null,
            { handleErrorInternally: true }
        );

        updateUser({
            ...user,
            primaryLogoUrl,
            primaryHash,
            secondaryLogoUrl,
            secondaryHash
        });
    };

    const deleteLogo = imageType => async () => {
        await fetchAPI(
            'PUT',
            'businesses/add/logo',
            null,
            { hash: imageType === 'primary' ? user.primaryHash : user.secondaryHash, imageType },
            { handleErrorInternally: true, rawResponse: true }
        );

        fetchLogo();
    };

    const updateLogo = imageType => async file => {
        if (imageType === 'primary' ? get(logo, 'url') : get(logoSecondary, 'url')) {
            deleteLogo(imageType)();
        }
        const formData = new FormData();
        const compressedFile = await imageCompression(file, {
            maxWidthOrHeight: 240,
            maxSizeMB: 2
        });
        formData.append('file', compressedFile);
        formData.append('imageType', imageType);
        formData.append('extension', file.type === 'image/png' ? 'png' : 'jpg');

        await fetch(`${__API_URL__}/businesses/add/logo`, {
            method: 'POST',
            headers: {
                Authorization: getToken()
            },
            body: formData
        });
        fetchLogo();
    };

    useEffect(() => {
        const setupFetch = async () => {
            await fetchData();
        };
        setupFetch();
    }, []);

    return (
        <Layout
            controls={
                <Button onClick={handleThemeUpdate} type='primary'>
                    <FormattedMessage id='save' />
                </Button>
            }
            title={<FormattedMessage id='navigation.theme_settings' />}
        >
            <Tabs activeKey={activeKey} onChange={setActiveKey} tabPosition='top'>
                <TabPane key='logo' tab={<FormattedMessage id='theme_settings.logo' />}>
                    <div className={Styles.settingsContainer}>
                        <div className={Styles.settingsBlock}>
                            <div className={Styles.labelBlock}>
                                <p className={Styles.label}>
                                    <FormattedMessage id='theme_settings.primary_logo' />
                                </p>
                            </div>
                            <Upload
                                action={updateLogo('primary')}
                                beforeUpload={file => {
                                    const isPNG = file.type === 'image/png';
                                    const isJPG = file.type === 'image/jpeg';
                                    if (!isPNG && !isJPG) {
                                        notification.error({
                                            message: `${file.name} ${intl.formatMessage({
                                                id: 'photo_modal.is_not_an_image'
                                            })}!`
                                        });
                                    }

                                    return isPNG || isJPG;
                                }}
                                className='avatar-uploader'
                                fileList={
                                    get(logo, 'url')
                                        ? [
                                              {
                                                  uid: '-1',
                                                  name: 'logo.png',
                                                  status: 'done',
                                                  url: `${get(logo, 'url')}`
                                              }
                                          ]
                                        : []
                                }
                                listType='picture-card'
                                name='avatar'
                                onRemove={deleteLogo('primary')}
                            >
                                {!get(logo, 'url') && (
                                    <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>
                                            <FormattedMessage id='general_settings.upload_logo' />
                                        </div>
                                    </div>
                                )}
                            </Upload>
                        </div>
                        <div className={Styles.settingsBlock}>
                            <div className={Styles.labelBlock}>
                                <p className={Styles.label}>
                                    <FormattedMessage id='theme_settings.secondary_logo' />
                                </p>
                            </div>
                            <Upload
                                action={updateLogo('secondary')}
                                beforeUpload={file => {
                                    const isPNG = file.type === 'image/png';
                                    const isJPG = file.type === 'image/jpeg';
                                    if (!isPNG && !isJPG) {
                                        notification.error({
                                            message: `${file.name} ${intl.formatMessage({
                                                id: 'photo_modal.is_not_an_image'
                                            })}!`
                                        });
                                    }

                                    return isPNG || isJPG;
                                }}
                                className='avatar-uploader'
                                fileList={
                                    get(logoSecondary, 'url')
                                        ? [
                                              {
                                                  uid: '-1',
                                                  name: 'logo.png',
                                                  status: 'done',
                                                  url: `${get(logoSecondary, 'url')}`
                                              }
                                          ]
                                        : []
                                }
                                listType='picture-card'
                                name='avatar'
                                onRemove={deleteLogo('secondary')}
                            >
                                {!get(logoSecondary, 'url') && (
                                    <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>
                                            <FormattedMessage id='general_settings.upload_logo' />
                                        </div>
                                    </div>
                                )}
                            </Upload>
                        </div>
                    </div>
                </TabPane>
                <TabPane key='colorSchemes' tab={<FormattedMessage id='theme_settings.color_schemes' />}>
                    <Select
                        className={Styles.themeSelect}
                        onChange={(value, { palette }) => {
                            setPalette(palette);
                        }}
                        placeholder={intl.formatMessage({ id: 'theme_settings.color_schemes' })}
                        value={undefined}
                    >
                        {colorSchemes.map(({ title, palette }) => (
                            <Option key={title} palette={palette} value={title}>
                                {title}
                            </Option>
                        ))}
                    </Select>
                    <div className={Styles.settingsContainer}>
                        {Object.keys(palette).map(key => (
                            <div key={key} className={Styles.settingsBlock}>
                                <div className={Styles.labelBlock}>
                                    <p className={Styles.label}>
                                        <FormattedMessage id={`theme_settings.colors.${key}`} /> ({key})
                                    </p>
                                    {/* <Tooltip title={intl.formatMessage({ id: 'reset' })}>
                                        <Button
                                            icon={<UndoOutlined />}
                                            onClick={() =>
                                                setPalette(palette => ({ ...palette, [key]: defaultColors[key] }))
                                            }
                                        />
                                    </Tooltip> */}
                                </div>
                                <div className={Styles.inputResetBlock}>
                                    <div className={Styles.inputBlock}>
                                        <ColorPicker
                                            onChange={color =>
                                                setPalette(palette => ({ ...palette, [key]: color.toHexString() }))
                                            }
                                            showText
                                            value={palette[key]}
                                        />
                                    </div>
                                    <div className={Styles.resetBlock}>
                                        <Button
                                            className={Styles.resetColor}
                                            onClick={() =>
                                                setPalette(palette => ({ ...palette, [key]: defaultColors[key] }))
                                            }
                                        >
                                            <FormattedMessage id='reset' />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </TabPane>
                <TabPane
                    key='personalPrintedDocuments'
                    tab={<FormattedMessage id='theme_settings.personal_print_documents' />}
                >
                    <div className={Styles.documentsContainer}>
                        {isGrantAccessed(user, grants.SETTINGS_THEME_SETTINGS, accesses.ROWO) && (
                            <div>
                                <p>
                                    <FormattedMessage id='sms_settings.alpha_name' />
                                </p>
                                <Input
                                    onChange={({ target }) => {
                                        setAlphaName(target.value);
                                    }}
                                    placeholder={intl.formatMessage({ id: 'theme_settings.alpha_name' })}
                                    style={{ minWidth: 180, maxWidth: 220 }}
                                    value={alphaName}
                                />
                            </div>
                        )}
                        <div>
                            <p>URL</p>
                            <Input
                                disabled
                                onChange={({ target }) => {
                                    setDomain(target.value);
                                }}
                                placeholder='URL'
                                style={{ minWidth: 180, maxWidth: 220 }}
                                value={domain}
                            />
                            <Button
                                icon={<RedoOutlined />}
                                onClick={async () => {
                                    await fetchAPI('PUT', 'business/domain', undefined, undefined, {
                                        handleErrorInternally: true
                                    });
                                    fetchData();
                                }}
                            />
                        </div>
                        <div>
                            <p>
                                <FormattedMessage id='theme_settings.telegram_name' />
                            </p>
                            <Input
                                onChange={({ target }) => {
                                    setTelegramName(target.value);
                                }}
                                placeholder={intl.formatMessage({ id: 'theme_settings.telegram_name' })}
                                style={{ minWidth: 180, maxWidth: 220 }}
                                value={telegramName}
                            />
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </Layout>
    );
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ThemeSettingsPage));
