/* eslint-disable react/sort-comp */
/* eslint-disable max-classes-per-file */
import {
    AppstoreAddOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    CopyOutlined,
    DeleteOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    ImportOutlined,
    MenuOutlined,
    PlusOutlined,
    PlusSquareOutlined,
    ProfileOutlined,
    QuestionCircleOutlined,
    ReconciliationOutlined,
    SaveFilled,
    SaveOutlined,
    SearchOutlined,
    StarFilled,
    StarOutlined,
    TeamOutlined,
    ToolOutlined,
    TransactionOutlined,
    TruckFilled,
    UsergroupAddOutlined
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Drawer,
    Dropdown,
    Image,
    Input,
    InputNumber,
    Menu,
    Modal,
    Select,
    Table,
    Tooltip,
    notification
} from 'antd';
import { Catcher } from 'commons';
import { Barcode, DraggableTable, EmployeeSupplierSelector, HamburgerMenu } from 'components';
import { MODALS } from 'core/modals/duck';
import dayjs from 'dayjs';
import EditLaborPercent from 'forms/OrderForm/EditLaborPercent';
import _, { debounce, get } from 'lodash';
import {
    AddServiceModal,
    ComplexesModal,
    CreateIncomeServiceModal,
    FavouriteServicesModal,
    LaborsNormHourModal
} from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { PencilIcon, WrenchIcon } from 'theme';
import { fetchAPI, filterStringByPart, numeralFormatter, numeralParser } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import ChangeCounterpartyRowsModal from './modals/ChangeCounterpartyRowsModal';
import UpdateLaborsPriceModal from './modals/UpdateLaborsPriceModal';
import Styles from './styles.m.css';

const { SubMenu } = Menu;
const INACTIVE = 'INACTIVE';

const complexesTypes = {
    OWN: { name: 'complexes.type.OWN', grant: grants.OPERATIONS_ORDER_DOCUMENT_JOBS_COMPLEXES },
    PRODUCTION: { name: 'complexes.type.PRODUCTION', grant: grants.DIRECTORIES_PRODUCTS_LIST_PRODUCTION_DISASSEMBLY },
    DISASSEMBLY: { name: 'complexes.type.DISASSEMBLY', grant: grants.DIRECTORIES_PRODUCTS_LIST_PRODUCTION_DISASSEMBLY }
};

@injectIntl
class ServicesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceModalVisible: false,
            serviceModalKey: 0,
            dataSource: [],
            selectedRowKeys: [],
            selectedRows: [],
            counterpartyTreeData: [],
            setPriceModalValue: 1,
            setPricePurchaseValue: 1,
            helperDrawerOpen: false,
            allLinks: [],
            crewStats: [],
            crewStatsSingle: [],
            search: '',
            openChangeCounterpartyModal: false
        };
        this.updateLabor = this.updateLabor.bind(this);
        this.updateDataSource = this.updateDataSource.bind(this);
        this.masterLabors = [];
        this.laborsTreeData = [];

        this.columns = () => [
            {
                title: () => {
                    const {
                        intl: { formatMessage },
                        isRTN
                    } = this.props;

                    const { selectedRows, selectedRowKeys, type } = this.state;

                    const actionsMenu = isRTN ? (
                        <Menu className={Styles.actionMenuDropdown}>
                            <Menu.Item
                                key='groupServicesMenu.updatePrice'
                                disabled={!selectedRowKeys.length}
                                onClick={async () => {
                                    const { status: st } = await fetchAPI(
                                        'GET',
                                        'orders/status',
                                        { orderId: this.props.orderId },
                                        null
                                    );
                                    if (st === 'success') {
                                        notification.error({
                                            message: this.props.intl.formatMessage({
                                                id: 'order_form_table.closed_order_notific'
                                            })
                                        });

                                        return;
                                    }
                                    this.setState({
                                        setPriceModal: selectedRows.filter(
                                            ({ agreement }) => agreement !== 'AGREED' && agreement !== 'REJECTED'
                                        )
                                    });
                                }}
                            >
                                <TransactionOutlined className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                {this.props.intl.formatMessage({ id: 'update_price' })}
                            </Menu.Item>
                            <Menu.Item key='groupServicesMenu.deleted' disabled={!selectedRowKeys.length}>
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.delete_confirm'
                                            }),
                                            onOk: async () => {
                                                const { status: st } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (st === 'success') {
                                                    notification.error({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'order_form_table.closed_order_notific'
                                                        })
                                                    });

                                                    return;
                                                }
                                                await fetchAPI(
                                                    'DELETE',
                                                    `orders/${this.props.orderId}/labors`,
                                                    {
                                                        ids: `[${this.state.selectedRows
                                                            .filter(
                                                                ({ stage, agreement }) =>
                                                                    stage == INACTIVE && agreement == 'UNDEFINED'
                                                            )
                                                            .map(({ id }) => id)}]`
                                                    },
                                                    undefined,
                                                    { handleErrorInternally: true }
                                                );
                                                await notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'details_table.deleted'
                                                    })
                                                });
                                                await this.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    ) : (
                        <Menu className={Styles.actionMenuDropdown}>
                            <SubMenu
                                key='groupDetailsMenu.changeResponsible'
                                disabled={!selectedRows.length}
                                popupClassName={Styles.customSubmenu}
                                title={
                                    <React.Fragment>
                                        <ToolOutlined className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                        {this.props.intl.formatMessage({
                                            id: 'order_form_table_requests.appoint_mechanic'
                                        })}
                                    </React.Fragment>
                                }
                            >
                                {this.props.employees
                                    .filter(({ posts }) => posts.find(({ postName }) => postName === 'MECHANIC'))
                                    .map(({ id: employeeId, surname, name }) => (
                                        <Menu.Item
                                            key={`groupDetailsMenu.changeResponsible.${employeeId}`}
                                            disabled={!selectedRowKeys.length}
                                            onClick={async () => {
                                                const payload = {
                                                    updateMode: true,
                                                    services: [
                                                        ...this.state.selectedRows.map(({ id, laborId }) => ({
                                                            id,
                                                            counterparty: 'EMPLOYEE',
                                                            businessSupplierId: null,
                                                            employeeId,
                                                            serviceId: laborId,
                                                            crewId: null
                                                        }))
                                                    ]
                                                };
                                                await fetchAPI(
                                                    'PUT',
                                                    `orders/${this.props.orderId}`,
                                                    undefined,
                                                    payload
                                                );
                                                await this.updateDataSource();
                                            }}
                                        >
                                            {surname} {name}
                                        </Menu.Item>
                                    ))}
                            </SubMenu>

                            <Menu.Item
                                key='groupDetailsMenu.changeResponsible.supplier'
                                disabled={!selectedRowKeys.length}
                                onClick={async () => {
                                    this.setState({
                                        openChangeCounterpartyModal: true,
                                        counterpartyChangeType: 'SUPPLIER'
                                    });
                                }}
                            >
                                <TruckFilled className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                {this.props.intl.formatMessage({
                                    id: 'order_form_table_requests.appoint_supplier'
                                })}
                            </Menu.Item>
                            <SubMenu
                                key='groupDetailsMenu.changeResponsible_crew'
                                disabled={!selectedRows.length}
                                title={
                                    <React.Fragment>
                                        <TeamOutlined className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                        {this.props.intl.formatMessage({
                                            id: 'order_form_table_requests.appoint_crew'
                                        })}
                                    </React.Fragment>
                                }
                            >
                                {this.props.crews.map(({ id: crewId, crewName }) => (
                                    <Menu.Item
                                        key={`groupDetailsMenu.changeResponsible.${crewId}`}
                                        disabled={!selectedRowKeys.length}
                                        onClick={async () => {
                                            const payload = {
                                                updateMode: true,
                                                services: [
                                                    ...this.state.selectedRows.map(({ id, laborId }) => ({
                                                        id,
                                                        counterparty: 'CREW',
                                                        businessSupplierId: null,
                                                        crewId,
                                                        employeeId: null,
                                                        serviceId: laborId
                                                    }))
                                                ]
                                            };
                                            await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);
                                            await this.updateDataSource();
                                        }}
                                    >
                                        {crewName}
                                    </Menu.Item>
                                ))}
                            </SubMenu>
                            {/* <Menu.Item
                                key='groupDetailsMenu.changeResponsible.crews'
                                disabled={!selectedRowKeys.length}
                                onClick={async () => {
                                    this.setState({
                                        openChangeCounterpartyModal: true,
                                        counterpartyChangeType: 'CREW'
                                    });
                                }}
                            >
                                <TeamOutlined className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                {this.props.intl.formatMessage({
                                    id: 'order_form_table_requests.appoint_crew'
                                })}
                            </Menu.Item> */}

                            <Menu.Item key='groupServicesMenu.addDetails' disabled={!selectedRowKeys.length}>
                                <div
                                    onClick={async () => {
                                        const { status: st } = await fetchAPI(
                                            'GET',
                                            'orders/status',
                                            { orderId: this.props.orderId },
                                            null
                                        );
                                        if (st === 'success') {
                                            notification.error({
                                                message: this.props.intl.formatMessage({
                                                    id: 'order_form_table.closed_order_notific'
                                                })
                                            });

                                            return;
                                        }
                                        const payload = {
                                            insertMode: true,
                                            details: [
                                                ...this.state.selectedRows.map(row => ({
                                                    storeGroupId: row.storeGroupId,
                                                    name: row.storeGroupName
                                                }))
                                            ]
                                        };
                                        if (this.props.tecdocId) {
                                            payload.modificationId = this.props.tecdocId;
                                        }
                                        await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);
                                        await this.updateDataSource();
                                    }}
                                >
                                    <PlusSquareOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'add_detail' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item key='groupServicesMenu.copy' disabled={!selectedRowKeys.length}>
                                <div
                                    onClick={async () => {
                                        const { status: st } = await fetchAPI(
                                            'GET',
                                            'orders/status',
                                            { orderId: this.props.orderId },
                                            null
                                        );
                                        if (st === 'success') {
                                            notification.error({
                                                message: this.props.intl.formatMessage({
                                                    id: 'order_form_table.closed_order_notific'
                                                })
                                            });

                                            return;
                                        }
                                        const payload = {
                                            insertMode: true,
                                            services: [
                                                ...this.state.selectedRows.map(row => ({
                                                    serviceId: row.laborId,

                                                    serviceName: row.serviceName,
                                                    employeeId: row.employeeId,
                                                    serviceHours: row.hours,
                                                    purchasePrice: Math.round(row.purchasePrice * 10000) / 10000,
                                                    count: row.count,
                                                    servicePrice: Math.round(row.price * 10000) / 10000,
                                                    comment: row.comment || {},
                                                    laborUnitId: row.laborUnitId,
                                                    serviceRowDiscount: row.serviceRowDiscount || 0
                                                }))
                                            ]
                                        };
                                        await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);
                                        await this.updateDataSource();
                                    }}
                                >
                                    <CopyOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.copy' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item
                                key='groupServicesMenu.updatePrice'
                                disabled={!selectedRowKeys.length}
                                onClick={async () => {
                                    const { status: st } = await fetchAPI(
                                        'GET',
                                        'orders/status',
                                        { orderId: this.props.orderId },
                                        null
                                    );
                                    if (st === 'success') {
                                        notification.error({
                                            message: this.props.intl.formatMessage({
                                                id: 'order_form_table.closed_order_notific'
                                            })
                                        });

                                        return;
                                    }
                                    this.setState({
                                        setPriceModal: selectedRows.filter(
                                            ({ agreement }) => agreement !== 'AGREED' && agreement !== 'REJECTED'
                                        )
                                    });
                                }}
                            >
                                <TransactionOutlined className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                {this.props.intl.formatMessage({ id: 'update_price' })}
                            </Menu.Item>
                            {isGrantAccessed(
                                this.props.user,
                                grants.OPERATIONS_ORDER_DOCUMENT_JOBS_COMPLEXES,
                                accesses.ROWO
                            ) && (
                                <Menu.Item key='groupDetailsMenu.add_complex'>
                                    <div
                                        onClick={() => {
                                            this.setState({ complexName: undefined, type: 'OWN' });
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'complexes.create_complex'
                                                }),
                                                content: (
                                                    <div>
                                                        <div style={{ marginBottom: 8 }}>
                                                            <Input
                                                                onChange={e =>
                                                                    this.setState({ complexName: e.target.value })
                                                                }
                                                                placeholder={this.props.intl.formatMessage({
                                                                    id: 'complexes.complex_name'
                                                                })}
                                                                value={this.state.complexName}
                                                            />
                                                        </div>
                                                        <div>
                                                            <Select
                                                                defaultValue='OWN'
                                                                onChange={value => this.setState({ type: value })}
                                                                optionFilterProp='children'
                                                                placeholder={this.props.intl.formatMessage({
                                                                    id: 'complexes.type'
                                                                })}
                                                                showSearch
                                                                style={{ marginBottom: 6, width: '100%' }}
                                                                value={this.state.type}
                                                            >
                                                                {Object.entries(complexesTypes).map(([key, value]) => (
                                                                    <Option
                                                                        key={key}
                                                                        disabled={
                                                                            !isGrantAccessed(
                                                                                this.props.user,
                                                                                value.grant
                                                                            )
                                                                        }
                                                                        value={key}
                                                                    >
                                                                        {this.props.intl.formatMessage({
                                                                            id: value.name
                                                                        })}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                ),
                                                onOk: async () => {
                                                    const complexLaborsData = this.state.dataSource.map(
                                                        ({
                                                            serviceName,
                                                            normHours,
                                                            price,
                                                            count,
                                                            laborUnitId,
                                                            laborId
                                                        }) => ({
                                                            laborName: serviceName,
                                                            laborHours: normHours,
                                                            laborPrice: price || 0,
                                                            laborCount: count,
                                                            ...(laborUnitId ? { laborUnitId } : {}),
                                                            laborLaborId: laborId
                                                        })
                                                    );
                                                    const complexProductsData = this.props.orderDetails.map(
                                                        ({
                                                            storeGroupId,
                                                            detailName,
                                                            count,
                                                            price,
                                                            supplierBrandId,
                                                            detailCode
                                                        }) => ({
                                                            productCode: detailCode || '-',
                                                            productStoreGroupId: storeGroupId,
                                                            productName: detailName,
                                                            productCount: count,
                                                            productPrice: price || 0,
                                                            ...(supplierBrandId
                                                                ? { productBrandId: supplierBrandId }
                                                                : {})
                                                        })
                                                    );

                                                    const body = {
                                                        complexData: {
                                                            name: this.state.complexName,
                                                            id: get(open, 'id'),
                                                            type: this.state.type || 'OWN'
                                                        },
                                                        complexLaborsData,
                                                        complexProductsData
                                                    };
                                                    const response = await fetchAPI(
                                                        get(open, 'id') ? 'PUT' : 'POST',
                                                        'business/complexes',
                                                        null,
                                                        body,
                                                        {
                                                            handleErrorInternally: true
                                                        }
                                                    );
                                                    if (response.complex) {
                                                        Modal.confirm({
                                                            onOk: async () => {
                                                                await fetchAPI(
                                                                    get(open, 'id') ? 'PUT' : 'POST',
                                                                    'business/complexes',
                                                                    null,
                                                                    { ...body, isRewrite: true },
                                                                    { handleErrorInternally: true }
                                                                );
                                                            },
                                                            onCancel: this.handleCancelComplex(),
                                                            title: this.props.intl.formatMessage({
                                                                id: 'complexes.already_exist'
                                                            })
                                                        });

                                                        return;
                                                    }

                                                    notification.success({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'barcode.success'
                                                        })
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        <PlusSquareOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'complexes.create_complex' })}
                                    </div>
                                </Menu.Item>
                            )}
                            <Menu.Item key='groupServicesMenu.deleted' disabled={!selectedRowKeys.length}>
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.delete_confirm'
                                            }),
                                            onOk: async () => {
                                                const { status: st } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (st === 'success') {
                                                    notification.error({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'order_form_table.closed_order_notific'
                                                        })
                                                    });

                                                    return;
                                                }
                                                await fetchAPI(
                                                    'DELETE',
                                                    `orders/${this.props.orderId}/labors`,
                                                    {
                                                        ids: `[${this.state.selectedRows
                                                            .filter(
                                                                ({ stage, agreement }) =>
                                                                    stage == INACTIVE && agreement == 'UNDEFINED'
                                                            )
                                                            .map(({ id }) => id)}]`
                                                    },
                                                    undefined,
                                                    { handleErrorInternally: true }
                                                );
                                                await notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'details_table.deleted'
                                                    })
                                                });
                                                await this.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item key='groupServicesMenu.frequent' disabled={!selectedRowKeys.length}>
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.favourite_confirm'
                                            }),
                                            onOk: async () => {
                                                const { status: st } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (st === 'success') {
                                                    notification.error({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'order_form_table.closed_order_notific'
                                                        })
                                                    });

                                                    return;
                                                }
                                                await fetchAPI(
                                                    'POST',
                                                    'orders/frequent/labors',
                                                    null,
                                                    this.state.selectedRows.map(row => ({
                                                        laborId: row.laborId,
                                                        name: row.serviceName,
                                                        hours: row.hours ? row.hours : 1,
                                                        purchasePrice: row.purchasePrice ? row.purchasePrice : 0,
                                                        count: row.count ? row.count : 1
                                                    })),
                                                    { handleErrorInternally: true }
                                                );
                                                await notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'details_table.added'
                                                    })
                                                });
                                                await this.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <StarFilled
                                        className={Styles.actionMenuIcon}
                                        style={{ color: 'gold' }}
                                        title={this.props.intl.formatMessage({
                                            id: 'add_to_favorites'
                                        })}
                                    />
                                    {this.props.intl.formatMessage({ id: 'add_to_favorites' })}
                                </div>
                            </Menu.Item>

                            <Menu.Item key='groupServicesMenu.laborStandarts' disabled={!selectedRowKeys.length}>
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'save_to_labors_standart_confirms'
                                            }),
                                            onOk: async () => {
                                                const { status: st } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (st === 'success') {
                                                    notification.error({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'order_form_table.closed_order_notific'
                                                        })
                                                    });

                                                    return;
                                                }
                                                const { region } = await fetchAPI('GET', 'business');
                                                const { success } = await fetchAPI('GET', 'labor_standarts');
                                                await fetchAPI(
                                                    'POST',
                                                    'labor_standarts',
                                                    null,
                                                    this.state.selectedRows.map(row => ({
                                                        modificationId: _.get(
                                                            this.props,
                                                            'selectedVehicle.modificationId'
                                                        ),
                                                        storeGroupId: row.storeGroupId,
                                                        regionId: region || 'UA.00.00.00',
                                                        laborId: row.laborId,
                                                        name: row.serviceName,
                                                        hours: row.count ? row.count : 1,
                                                        price: row.price,
                                                        orderId: this.props.orderId,
                                                        year: _.get(this.props, 'selectedVehicle.year'),
                                                        rewriteExisting: !success,
                                                        bodyId: _.get(this.props, 'selectedVehicle.bodyId')
                                                    })),
                                                    { handleErrorInternally: true }
                                                );
                                                await notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'save_to_labors_standart_notifications'
                                                    })
                                                });
                                            }
                                        });
                                    }}
                                >
                                    <SaveFilled
                                        className={Styles.actionMenuIcon}
                                        title={this.props.intl.formatMessage({
                                            id: 'save_to_labors_standart'
                                        })}
                                    />
                                    {this.props.intl.formatMessage({ id: 'save_to_labors_standarts' })}
                                </div>
                            </Menu.Item>

                            <Menu.Item
                                key='groupServicesMenu.importDocument'
                                onClick={async () => {
                                    const { status: st } = await fetchAPI(
                                        'GET',
                                        'orders/status',
                                        { orderId: this.props.orderId },
                                        null
                                    );
                                    if (st === 'success') {
                                        notification.error({
                                            message: this.props.intl.formatMessage({
                                                id: 'order_form_table.closed_order_notific'
                                            })
                                        });

                                        return;
                                    }

                                    this.props.setModal(MODALS.IMPORT_RECEIPT_DOCUMENT_MODAL, {
                                        visibleLabors: true
                                    });
                                }}
                            >
                                <ImportOutlined className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                {this.props.intl.formatMessage({ id: 'directory_page.import_document' })}
                            </Menu.Item>
                            <Menu.Item
                                key='groupServicesMenu.importService'
                                disabled={
                                    !selectedRowKeys.length ||
                                    this.state.selectedRows.find(({ businessSupplierId }) => !businessSupplierId)
                                }
                                onClick={async () => {
                                    const { status: st } = await fetchAPI(
                                        'GET',
                                        'orders/status',
                                        { orderId: this.props.orderId },
                                        null
                                    );
                                    if (st === 'success') {
                                        notification.error({
                                            message: this.props.intl.formatMessage({
                                                id: 'order_form_table.closed_order_notific'
                                            })
                                        });

                                        return;
                                    }
                                    const docs = await fetchAPI(
                                        'GET',
                                        'service/store_doc/appearance',
                                        {
                                            orderId: this.props.orderId
                                        },
                                        null,
                                        { handleErrorInternally: true }
                                    );

                                    if (docs) {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'order_docs.add_income_services_message'
                                            }),
                                            content: (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-around'
                                                    }}
                                                >
                                                    <div>
                                                        <Button
                                                            onClick={() => {
                                                                Modal.destroyAll();
                                                            }}
                                                        >
                                                            {this.props.intl.formatMessage({ id: 'cancel' })}
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            onClick={() => {
                                                                this.setState({
                                                                    visibleCreateIncomeServiceModal: true
                                                                });
                                                                Modal.destroyAll();
                                                            }}
                                                            type='primary'
                                                        >
                                                            {this.props.intl.formatMessage({
                                                                id: 'general_settings.submit'
                                                            })}
                                                        </Button>
                                                    </div>
                                                </div>
                                            ),
                                            footer: null,
                                            okType: 'default'
                                        });
                                    } else {
                                        this.setState({
                                            visibleCreateIncomeServiceModal: true
                                        });
                                    }
                                }}
                            >
                                <ReconciliationOutlined className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                {this.props.intl.formatMessage({ id: 'order_docs.add_income_services' })}
                            </Menu.Item>
                            {/* <Menu.Item
                                key='groupServicesMenu.crewPercent'
                                disabled={this.props.disabled}
                                onClick={() => {
                                    const crewObject = selectedRows.find(({ crewId }) => crewId);
                                    const allHaveSameId = crewObject
                                        ? selectedRows.every(obj => obj.crewId === crewObject.crewId)
                                        : false;
                                    const allCrewStats = allHaveSameId
                                        ? selectedRows.map(({ crewStats, ...rest }) => crewStats)
                                        : undefined;

                                    if (allHaveSameId) {
                                        this.setState({
                                            open: true,
                                            crewStats: allCrewStats
                                        });
                                    } else {
                                        notification.error({
                                            message: this.props.intl.formatMessage({
                                                id: 'crews.error_order_notific'
                                            })
                                        });
                                    }
                                }}
                            >
                                <UsergroupAddOutlined
                                    className={Styles.actionMenuIcon}
                                    style={{ fontSize: 18, marginRight: 0 }}
                                    // title={this.props.intl.formatMessage({
                                    //     id: 'save_to_labors_standart'
                                    // })}
                                />
                                {this.props.intl.formatMessage({ id: 'crews.crew_setting' })}
                            </Menu.Item> */}
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={actionsMenu} disabled={this.props.disabled} />;
                },
                key: 'actions',
                width: 48,
                align: 'center',
                render: row => {
                    const { isRTN } = this.props;
                    const disabled = row.agreement == 'AGREED' || row.agreement == 'REJECTED' || this.props.disabled;

                    const actionsMenu = () =>
                        isRTN ? (
                            <Menu>
                                <Menu.Item
                                    key='servicesActionMenu.delete'
                                    disabled={disabled}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.delete_confirm'
                                            }),
                                            onOk: async () => {
                                                const { status: st } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (st === 'success') {
                                                    notification.error({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'order_form_table.closed_order_notific'
                                                        })
                                                    });

                                                    return;
                                                }
                                                await fetchAPI('DELETE', `orders/${this.props.orderId}/labors`, {
                                                    ids: `[${row.id}]`
                                                });
                                                await this.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <div>
                                        <DeleteOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'delete' })}
                                    </div>
                                </Menu.Item>
                            </Menu>
                        ) : (
                            <Menu>
                                <Menu.Item
                                    key='servicesActionMenu.delete'
                                    disabled={disabled}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.delete_confirm'
                                            }),
                                            onOk: async () => {
                                                const { status: st } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (st === 'success') {
                                                    notification.error({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'order_form_table.closed_order_notific'
                                                        })
                                                    });

                                                    return;
                                                }
                                                await fetchAPI('DELETE', `orders/${this.props.orderId}/labors`, {
                                                    ids: `[${row.id}]`
                                                });
                                                await this.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <div>
                                        <DeleteOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'delete' })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item key='servicesMenu.addDetails' disabled={row.agreement === 'REJECTED'}>
                                    <div
                                        onClick={async () => {
                                            const { status: st } = await fetchAPI(
                                                'GET',
                                                'orders/status',
                                                { orderId: this.props.orderId },
                                                null
                                            );
                                            if (st === 'success') {
                                                notification.error({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'order_form_table.closed_order_notific'
                                                    })
                                                });

                                                return;
                                            }
                                            const { status } = await fetchAPI(
                                                'GET',
                                                'orders/status',
                                                { orderId: this.props.orderId },
                                                null
                                            );
                                            if (status === 'success') {
                                                window.location.reload();

                                                return;
                                            }
                                            const payload = {
                                                insertMode: true,
                                                details: [
                                                    {
                                                        storeGroupId: row.storeGroupId,
                                                        name: row.storeGroupName
                                                    }
                                                ]
                                            };

                                            if (this.props.tecdocId) {
                                                payload.modificationId = this.props.tecdocId;
                                            }
                                            await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);
                                            await this.updateDataSource();
                                        }}
                                    >
                                        <PlusSquareOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'add_detail' })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item key='servicesActionMenu.copy' disabled={disabled}>
                                    <div
                                        onClick={async () => {
                                            const { status: st } = await fetchAPI(
                                                'GET',
                                                'orders/status',
                                                { orderId: this.props.orderId },
                                                null
                                            );
                                            if (st === 'success') {
                                                notification.error({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'order_form_table.closed_order_notific'
                                                    })
                                                });

                                                return;
                                            }
                                            await fetchAPI(
                                                'PUT',
                                                `orders/${this.props.orderId}`,
                                                undefined,
                                                {
                                                    insertMode: true,
                                                    services: [
                                                        {
                                                            serviceId: row.laborId,
                                                            serviceName: row.serviceName,
                                                            employeeId: row.employeeId || null,
                                                            serviceHours: row.hours,
                                                            purchasePrice: Math.round(row.purchasePrice * 1000) / 1000,
                                                            count: row.count,
                                                            servicePrice: Math.round(row.price * 1000) / 1000,
                                                            comment: row.comment || {},
                                                            putAfter: row.order,
                                                            laborUnitId: row.laborUnitId,
                                                            serviceRowDiscount: row.serviceRowDiscount || 0
                                                        }
                                                    ]
                                                },
                                                { handleErrorInternally: true }
                                            );
                                            await this.updateDataSource();
                                        }}
                                    >
                                        <CopyOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.copy' })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item key='servicesActionMenu.frequent'>
                                    <div
                                        onClick={() => {
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: row.frequentLaborId
                                                        ? 'add_order_form.favourite_remove'
                                                        : 'add_order_form.favourite_confirm'
                                                }),
                                                onOk: async () => {
                                                    const { status: st } = await fetchAPI(
                                                        'GET',
                                                        'orders/status',
                                                        { orderId: this.props.orderId },
                                                        null
                                                    );
                                                    if (st === 'success') {
                                                        notification.error({
                                                            message: this.props.intl.formatMessage({
                                                                id: 'order_form_table.closed_order_notific'
                                                            })
                                                        });

                                                        return;
                                                    }
                                                    const data = [
                                                        {
                                                            laborId: row.laborId,
                                                            name: row.serviceName,
                                                            hours: row.hours ? row.hours : 1,
                                                            purchasePrice: row.purchasePrice ? row.purchasePrice : 0,
                                                            count: row.count ? row.count : 1
                                                        }
                                                    ];
                                                    if (row.frequentLaborId) {
                                                        await fetchAPI('DELETE', 'orders/frequent/labors', {
                                                            ids: `[${row.frequentLaborId}]`
                                                        });
                                                        await this.updateDataSource();
                                                    } else {
                                                        await fetchAPI(
                                                            'POST',
                                                            'orders/frequent/labors',
                                                            undefined,
                                                            data
                                                        );
                                                        await this.updateDataSource();
                                                    }
                                                }
                                            });
                                        }}
                                    >
                                        {row.frequentLaborId ? (
                                            <StarFilled
                                                className={Styles.actionMenuIcon}
                                                style={{
                                                    color: 'gold',
                                                    fontSize: 18,
                                                    marginRight: 0
                                                }}
                                                title={this.props.intl.formatMessage({
                                                    id: 'delete_from_favorites'
                                                })}
                                            />
                                        ) : (
                                            <StarOutlined
                                                className={Styles.actionMenuIcon}
                                                style={{
                                                    color: 'gold',
                                                    fontSize: 18,
                                                    marginRight: 0
                                                }}
                                                title={this.props.intl.formatMessage({
                                                    id: 'add_to_favorites'
                                                })}
                                            />
                                        )}
                                        {this.props.intl.formatMessage({
                                            id: row.frequentLaborId ? 'delete_from_favorites' : 'add_to_favorites'
                                        })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    key='servicesActionMenu.laborStandart'
                                    disabled={disabled || !this.props.selectedVehicle || !row.laborId || !row.price}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'save_to_labors_standart_confirm'
                                            }),
                                            onOk: async () => {
                                                const { status: st } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (st === 'success') {
                                                    notification.error({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'order_form_table.closed_order_notific'
                                                        })
                                                    });

                                                    return;
                                                }
                                                const { region } = await fetchAPI('GET', 'business');
                                                const { success } = await fetchAPI('GET', 'labor_standarts');
                                                const data = [
                                                    {
                                                        modificationId: _.get(
                                                            this.props,
                                                            'selectedVehicle.modificationId'
                                                        ),
                                                        storeGroupId: row.storeGroupId,
                                                        regionId: region || 'UA.00.00.00',
                                                        laborId: row.laborId,
                                                        name: row.serviceName,
                                                        hours: row.count ? row.count : 1,
                                                        price: row.price,
                                                        orderId: this.props.orderId,
                                                        year: _.get(this.props, 'selectedVehicle.year'),
                                                        rewriteExisting: !success,
                                                        bodyId: _.get(this.props, 'selectedVehicle.bodyId')
                                                    }
                                                ];
                                                await fetchAPI('POST', 'labor_standarts', null, data, {
                                                    handleErrorInternally: true
                                                });
                                                await notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'save_to_labors_standart_notification'
                                                    })
                                                });
                                            }
                                        });
                                    }}
                                >
                                    <SaveOutlined
                                        className={Styles.actionMenuIcon}
                                        style={{ fontSize: 18, marginRight: 0 }}
                                        title={this.props.intl.formatMessage({
                                            id: 'save_to_labors_standart'
                                        })}
                                    />
                                    {this.props.intl.formatMessage({ id: 'save_to_labors_standart' })}
                                </Menu.Item>
                                <Menu.Item
                                    key='groupServicesMenu.importService'
                                    disabled={!row.businessSupplierId}
                                    onClick={async () => {
                                        const { status: st } = await fetchAPI(
                                            'GET',
                                            'orders/status',
                                            { orderId: this.props.orderId },
                                            null
                                        );
                                        if (st === 'success') {
                                            notification.error({
                                                message: this.props.intl.formatMessage({
                                                    id: 'order_form_table.closed_order_notific'
                                                })
                                            });

                                            return;
                                        }
                                        const docs = await fetchAPI(
                                            'GET',
                                            '/service/store_doc/appearance',
                                            {
                                                orderId: this.props.orderId
                                            },
                                            null,
                                            { handleErrorInternally: true }
                                        );

                                        if (docs) {
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'order_docs.add_income_services_message'
                                                }),
                                                content: (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-around'
                                                        }}
                                                    >
                                                        <div>
                                                            <Button
                                                                onClick={() => {
                                                                    Modal.destroyAll();
                                                                }}
                                                            >
                                                                {this.props.intl.formatMessage({ id: 'cancel' })}
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Button
                                                                onClick={() => {
                                                                    this.setState({
                                                                        visibleCreateIncomeServiceModal: true,
                                                                        suppliersIncomeModalRow: row
                                                                    });
                                                                    Modal.destroyAll();
                                                                }}
                                                                type='primary'
                                                            >
                                                                {this.props.intl.formatMessage({
                                                                    id: 'general_settings.submit'
                                                                })}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                ),
                                                footer: null,
                                                okType: 'default'
                                            });
                                        } else {
                                            this.setState({
                                                visibleCreateIncomeServiceModal: true,
                                                suppliersIncomeModalRow: row
                                            });
                                        }
                                    }}
                                >
                                    <ReconciliationOutlined
                                        className={Styles.actionMenuIcon}
                                        style={{ fontSize: 18 }}
                                    />
                                    {this.props.intl.formatMessage({ id: 'order_docs.add_income_services' })}
                                </Menu.Item>

                                <Menu.Item
                                    key='servicesActionMenu.crewPercent'
                                    disabled={disabled || !row.crewId}
                                    onClick={() => {
                                        this.setState({
                                            open: true,
                                            crewStatsSingle: row.crewStats
                                        });
                                    }}
                                >
                                    <UsergroupAddOutlined
                                        className={Styles.actionMenuIcon}
                                        style={{ fontSize: 18, marginRight: 0 }}
                                        // title={this.props.intl.formatMessage({
                                        //     id: 'save_to_labors_standart'
                                        // })}
                                    />
                                    {this.props.intl.formatMessage({ id: 'crews.crew_setting' })}
                                </Menu.Item>
                            </Menu>
                        );

                    return (
                        <HamburgerMenu actionsMenu={actionsMenu} disabled={this.props.disabled}>
                            <Button
                                data-qa='btn_show_hamburger_menu_modal_services_table_order_page'
                                icon={<MenuOutlined />}
                            />
                        </HamburgerMenu>
                    );
                }
            },
            {
                title: () => {
                    const { isRTN, openRTNModal } = this.props;

                    return isRTN ? (
                        <div>
                            <div className={Styles.headerActions}>
                                <Tooltip placement='top' title={<FormattedMessage id='add' />}>
                                    <Button
                                        data-qa='btn_show_service_product_modal_services_table_order_page'
                                        disabled={this.props.disabled}
                                        icon={<PlusOutlined />}
                                        onClick={() => {
                                            openRTNModal();
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className={Styles.headerActions}>
                                <Tooltip placement='top' title={<FormattedMessage id='add' />}>
                                    <Button
                                        data-qa='btn_show_service_product_modal_services_table_order_page'
                                        disabled={this.props.disabled}
                                        icon={<PlusOutlined />}
                                        onClick={() => {
                                            this.showServiceProductModal(-1);
                                        }}
                                    />
                                </Tooltip>
                                {isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_JOBS_COMPLEXES) && (
                                    <ComplexesModal
                                        bodyId={this.props.bodyId}
                                        clientDiscount={this.props.selectedClient && this.props.selectedClient.discount}
                                        clientId={this.props.clientId}
                                        defaultEmployeeId={this.props.defaultEmployeeId}
                                        details={this.props.details}
                                        detailsDiscount={
                                            this.props.selectedClient && this.props.selectedClient.detailsDiscount
                                        }
                                        detailsTreeData={this.props.detailsTreeData}
                                        disabled={this.props.disabled}
                                        handleSearchLabors={this.handleSearchLabors}
                                        labors={this.props.labors}
                                        laborsDiscount={
                                            this.props.selectedClient && this.props.selectedClient.laborsDiscount
                                        }
                                        laborTimeMultiplier={this.props.laborTimeMultiplier}
                                        normHourPrice={this.props.normHourPrice}
                                        orderId={this.props.orderId}
                                        reloadOrderForm={(...args) => {
                                            const checkStatus = async (index = 0) => {
                                                if (index >= 20) {
                                                    notification.error({ message: 'Invalid' });

                                                    return;
                                                }
                                                const resp = await fetchAPI('GET', 'mq_processes', {
                                                    orderId: this.props.orderId
                                                });
                                                const status = _.get(resp, 'list[0].state');

                                                if (status && status !== 'OK') {
                                                    setTimeout(() => checkStatus(index + 1), 1000);

                                                    return;
                                                }
                                                if (status === 'OK') {
                                                    this.props.reloadOrderForm(...args);
                                                }
                                                this.updateDataSource();
                                            };

                                            checkStatus(0);
                                        }}
                                        tecdocId={this.props.tecdocId}
                                        unitDefault={this.props.unitDefault}
                                    />
                                )}
                                <Tooltip placement='top' title={<FormattedMessage id='add_labor.from_recom' />}>
                                    <Button
                                        disabled={
                                            this.props.disabled ||
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.OPERATIONS_ORDER_DOCUMENT_RECOMMENDATIONS
                                            )
                                        }
                                        icon={<ProfileOutlined />}
                                        onClick={() => {
                                            this.props.setModal(MODALS.RECOM_TO_SERVICE);
                                        }}
                                    />
                                </Tooltip>
                            </div>
                            <div className={Styles.headerActions}>
                                <Barcode
                                    button
                                    data-qa='btn_barcode_services_table_order_page'
                                    disabled={this.props.disabled}
                                    disableNotifications
                                    multipleMode
                                    onConfirm={async (code, pref, fullCode) => {
                                        const { status } = await fetchAPI(
                                            'GET',
                                            'orders/status',
                                            { orderId: this.props.orderId },
                                            null
                                        );
                                        if (status === 'success') {
                                            window.location.reload();

                                            return;
                                        }
                                        const barcodeData = await fetchAPI('GET', 'barcodes', {
                                            barcode: code
                                        });
                                        const laborBarcode = barcodeData.find(({ table }) => table == 'LABORS');

                                        if (laborBarcode) {
                                            const payload = {
                                                insertMode: true,
                                                details: [],
                                                services: []
                                            };
                                            const labor = await fetchAPI('GET', `labors/${laborBarcode.referenceId}`);
                                            payload.services.push({
                                                serviceId: labor.id,
                                                serviceName: labor.name || labor.defaultName,
                                                employeeId: this.props.defaultEmployeeId,
                                                serviceHours: 0,
                                                purchasePrice: 0,
                                                count: Number(labor.laborPrice.normHours) || 0,
                                                servicePrice: Number(labor.laborPrice.price) || this.props.normHourPrice
                                            });
                                            await fetchAPI('PUT', `orders/${this.props.orderId}`, null, payload);
                                            await this.updateDataSource();
                                        } else {
                                            notification.warning({
                                                message: this.props.intl.formatMessage({
                                                    id: 'order_form_table.code_not_found'
                                                })
                                            });
                                        }
                                    }}
                                    prefix='LBS'
                                />

                                <FavouriteServicesModal
                                    defaultEmployeeId={this.props.defaultEmployeeId}
                                    detailsTreeData={this.props.detailsTreeData}
                                    disabled={this.props.disabled}
                                    employees={this.props.employees}
                                    labors={this.props.labors}
                                    laborsTreeData={this.laborsTreeData}
                                    laborTimeMultiplier={this.props.laborTimeMultiplier}
                                    normHourPrice={this.props.normHourPrice}
                                    orderId={this.props.orderId}
                                    tecdocId={this.props.tecdocId}
                                    unitDefault={this.props.unitDefault}
                                    updateDataSource={this.updateDataSource}
                                    user={this.props.user}
                                />
                                <Tooltip title={<FormattedMessage id='visual_addition' />}>
                                    <Button
                                        disabled={this.props.disabled}
                                        icon={<AppstoreAddOutlined />}
                                        onClick={() => this.props.setModal(MODALS.VISUAL_ADDITION)}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    );
                },
                key: 'buttonGroup',
                width: 114,
                onCell: () => ({ style: { padding: 2 } }),
                render: row => {
                    const { isRTN } = this.props;
                    const confirmed = row.agreement.toLowerCase();
                    const disabled = confirmed != 'undefined' || this.props.disabled;
                    const stageDisabled = row.stage != INACTIVE;

                    return (
                        <div className={Styles.rowActions}>
                            {!isRTN && (
                                <Tooltip placement='top' title={<FormattedMessage id='labors_table.add_edit_button' />}>
                                    <Button
                                        data-qa='btn_add_edit_button_service_product_modal_services_table_order_page'
                                        disabled={disabled}
                                        icon={<WrenchIcon />}
                                        onClick={() => {
                                            this.showServiceProductModal(row.key);
                                        }}
                                    />
                                </Tooltip>
                            )}
                            <QuickEditModal
                                confirmed={confirmed != 'undefined'}
                                counterpartyTreeData={this.state.counterpartyTreeData}
                                disabled={!row.laborId || this.props.disabled}
                                employees={this.props.employees}
                                isRTN={isRTN}
                                labor={{ ...row }}
                                laborTimeMultiplier={this.props.laborTimeMultiplier}
                                onConfirm={this.updateLabor}
                                stageDisabled={stageDisabled}
                                suppliers={this.props.suppliers || []}
                                tableKey={row.key}
                                tecdocId={this.props.tecdocId}
                                units={this.props.units}
                                updateDataSource={this.updateDataSource}
                                user={this.props.user}
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.service_type' />,
                key: 'defaultName',
                dataIndex: 'defaultName',
                render: (data, row) => {
                    const laborId = `${String(row.laborId).substring(0, 4)}-${String(row.laborId).substring(4)}`;

                    return (
                        <div>
                            <div>{data || <FormattedMessage id='long_dash' />}</div>

                            {isGrantAccessed(this.props.user, grants.DIRECTORIES_JOBS) ? (
                                <Link
                                    style={{ textDecoration: 'underline' }}
                                    to={{
                                        pathname: book.laborsPage,
                                        state: {
                                            laborId: laborId.replace('-', '')
                                        }
                                    }}
                                >
                                    <div style={{ fontSize: 12 }}>{row.crossId ? row.crossId : laborId}</div>
                                </Link>
                            ) : (
                                <div style={{ fontSize: 12 }}>{row.crossId ? row.crossId : laborId}</div>
                            )}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='name_2' />,
                key: 'serviceName',
                dataIndex: 'serviceName',
                width: window.innerWidth >= 1440 && '20%',
                render: data => {
                    return data || <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: (
                    <React.Fragment>
                        <FormattedMessage id='order_form_table.short_master' /> /{' '}
                        <FormattedMessage id='order_form_table.short_supplier' />
                    </React.Fragment>
                ),
                key: 'employeeId',
                width: window.innerWidth >= 1440 && '15%',
                render: row => {
                    const { isRTN } = this.props;
                    const employeesOptions = this.props.employees
                        .filter(
                            ({ posts, disabled }) =>
                                !disabled && posts.map(({ postName }) => postName).includes('MECHANIC')
                        )
                        .map(({ name, surname, id }) => ({
                            value: id,
                            label: `${surname || ''} ${name || ''}`
                        }));

                    const suppliersOptions = this.props.suppliers.map(({ name, id }) => ({
                        value: id,
                        label: name
                    }));

                    const crews =
                        this.props.crews &&
                        this.props.crews.map(({ crewName, id }) => ({
                            value: id,
                            label: crewName
                        }));

                    return (
                        <EmployeeSupplierSelector
                            crews={crews}
                            disabled={this.props.disabled || isRTN}
                            employeesOptions={employeesOptions}
                            initialState={{
                                type: row.counterparty,
                                options:
                                    row.employeeId || row.businessSupplierId || row.crewId
                                        ? [
                                              {
                                                  value: row.crewId || row.employeeId || row.businessSupplierId,
                                                  label: row.crewId
                                                      ? row.crewName
                                                      : row.employeeId
                                                      ? row.employeeName
                                                      : row.businessSupplierName
                                              }
                                          ]
                                        : undefined
                            }}
                            onChange={async (value, type) => {
                                if (!value) {
                                    await fetchAPI(
                                        'PUT',
                                        `orders/${this.props.orderId}`,
                                        undefined,
                                        {
                                            updateMode: true,
                                            services: [
                                                {
                                                    laborUnitId: row.laborUnitId,
                                                    id: row.id,
                                                    serviceId: row.laborId,
                                                    counterparty: type,
                                                    employeeId: null,
                                                    businessSupplierId: null,
                                                    crewId: null
                                                }
                                            ]
                                        },
                                        { handleErrorInternally: true }
                                    );
                                    await this.updateDataSource();
                                } else {
                                    await fetchAPI(
                                        'PUT',
                                        `orders/${this.props.orderId}`,
                                        undefined,
                                        {
                                            updateMode: true,
                                            services: [
                                                {
                                                    id: row.id,
                                                    laborUnitId: row.laborUnitId,
                                                    serviceId: row.laborId,
                                                    counterparty: type,
                                                    employeeId: type === 'EMPLOYEE' ? value.key : null,
                                                    businessSupplierId: type === 'SUPPLIER' ? value.key : null,
                                                    crewId: type === 'CREW' ? value.key : null
                                                }
                                            ]
                                        },
                                        { handleErrorInternally: true }
                                    );
                                    await this.updateDataSource();
                                }
                            }}
                            suppliersOptions={suppliersOptions}
                            value={row.crewId || row.employeeId || row.businessSupplierId}
                        />
                        // <TreeSelect
                        //     allowClear
                        //     data-qa='tree_select_counterparty_employee_services_table_order_page'
                        //     disabled={this.props.disabled}
                        //     dropdownMatchSelectWidth={280}
                        //     filterTreeNode={(input, node) => {
                        //         return (
                        //             node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        //             String(node.props.value).indexOf(input.toLowerCase()) >= 0
                        //         );
                        //     }}
                        //     listHeight={440}
                        //     onChange={async value => {
                        //         if (!value) {
                        //             await fetchAPI(
                        //                 'PUT',
                        //                 `orders/${this.props.orderId}`,
                        //                 undefined,
                        //                 {
                        //                     updateMode: true,
                        //                     services: [
                        //                         {
                        //                             laborUnitId: row.laborUnitId,
                        //                             id: row.id,
                        //                             serviceId: row.laborId,
                        //                             counterparty: null,
                        //                             employeeId: null,
                        //                             businessSupplierId: null
                        //                         }
                        //                     ]
                        //                 },
                        //                 { handleErrorInternally: true }
                        //             );
                        //             await this.updateDataSource();
                        //         }
                        //     }}
                        //     onSelect={async (valueString, option) => {
                        //         const value = JSON.parse(valueString);
                        //         await fetchAPI(
                        //             'PUT',
                        //             `orders/${this.props.orderId}`,
                        //             undefined,
                        //             {
                        //                 updateMode: true,
                        //                 services: [
                        //                     {
                        //                         id: row.id,
                        //                         laborUnitId: row.laborUnitId,
                        //                         serviceId: row.laborId,
                        //                         counterparty: value.counterparty,
                        //                         employeeId: value.counterparty === 'EMPLOYEE' ? value.id : null,
                        //                         businessSupplierId: value.counterparty === 'SUPPLIER' ? value.id : null
                        //                     }
                        //                 ]
                        //             },
                        //             { handleErrorInternally: true }
                        //         );
                        //         await this.updateDataSource();
                        //     }}
                        //     placeholder={
                        //         <React.Fragment>
                        //             <FormattedMessage id='order_form_table.master' /> /{' '}
                        //             <FormattedMessage id='order_form_table.supplier' />
                        //         </React.Fragment>
                        //     }
                        //     showSearch
                        //     treeData={this.state.counterpartyTreeData}
                        //     treeDefaultExpandedKeys={['EMPLOYEE']}
                        //     value={
                        //         row.employeeId || row.businessSupplierId
                        //             ? JSON.stringify({
                        //                   counterparty: row.counterparty || 'EMPLOYEE',
                        //                   id: row.counterparty === 'SUPPLIER' ? row.businessSupplierId : row.employeeId
                        //               })
                        //             : undefined
                        //     }
                        // />
                    );
                }
            },
            ...(this.props.isRTN
                ? []
                : [
                      {
                          title: <FormattedMessage id='services_table.short_norm_hours' />,
                          key: 'hours',
                          align: 'center',
                          dataIndex: 'hours',
                          width: 64,
                          render: (data, { laborId, storeGroupId, id, price: rowPrice }) => {
                              return (
                                  <Tooltip
                                      placement='top'
                                      title={<FormattedMessage id='labors_table.check_labor_hours' />}
                                  >
                                      <Button
                                          data-qa='btn_show_horm_hour_modal_services_table_order_page'
                                          disabled={
                                              this.props.disabled ||
                                              !isGrantAccessed(
                                                  this.props.user,
                                                  grants.OPERATIONS_ORDER_DOCUMENT_JOBS_STANDARD_HOURS
                                              )
                                          }
                                          onClick={() => {
                                              this.props.setModal(MODALS.NORM_HOURS_MODAL, {
                                                  laborId,
                                                  storeGroupId,

                                                  onSelect: async ({ hours, price, normHourPrice }) => {
                                                      await fetchAPI(
                                                          'PUT',
                                                          `orders/${this.props.orderId}`,
                                                          undefined,
                                                          {
                                                              updateMode: true,
                                                              services: [
                                                                  {
                                                                      id,
                                                                      serviceId: laborId,
                                                                      serviceHours: hours,
                                                                      count: hours * this.props.laborTimeMultiplier,
                                                                      servicePrice: price || rowPrice || normHourPrice
                                                                  }
                                                              ]
                                                          },
                                                          { handleErrorInternally: true }
                                                      );
                                                      this.updateDataSource();
                                                  }
                                              });
                                          }}
                                          style={{ padding: '0px 12px' }}
                                      >
                                          {data ? (
                                              <React.Fragment>
                                                  {data}
                                                  <FormattedMessage id='order_form_table.hours_short' />
                                              </React.Fragment>
                                          ) : (
                                              <ClockCircleOutlined />
                                          )}
                                      </Button>
                                  </Tooltip>
                              );
                          }
                      }
                  ]),
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <div>
                            <Tooltip
                                placement='topRight'
                                title={this.props.intl.formatMessage({ id: 'client_container.discounts.info' })}
                            >
                                <QuestionCircleOutlined style={{ marginRight: 5, color: 'var(--primary)' }} />
                            </Tooltip>
                            <FormattedMessage id='order_form_table.short_discount' />
                        </div>
                    </div>
                ),
                className: Styles.numberColumn,
                width: 92,
                key: 'serviceRowDiscount',
                dataIndex: 'serviceRowDiscount',
                render: (data, row) => {
                    return <span>{`${row.serviceRowDiscount || 0}%`}</span>;
                }
            },
            {
                title: () => (
                    <div className={Styles.numberColumn}>
                        <Button
                            icon={this.props.purchasePrices ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            onClick={() => {
                                this.props.showPurchasePrices();
                            }}
                            type='text'
                        />
                        <FormattedMessage id='order_form_table.shortPurchasePrice' />
                    </div>
                ),
                className: Styles.numberColumn,
                // width: 96,
                key: 'primeCost',
                dataIndex: 'purchasePrice',
                render: (data, row) => {
                    if (!this.props.purchasePrices) {
                        return <FormattedMessage id='long_dash' />;
                    }

                    const strVal = Number(data).toFixed(2);
                    const strValTooltip = Number(data).toFixed(4);

                    return (
                        <Tooltip
                            title={
                                <div>
                                    <FormattedMessage id='order_form_table.prime_cost' />: {strValTooltip}
                                </div>
                            }
                        >
                            <span style={{ color: row.markupCheckFailed ? 'red' : null }}>
                                {data ? (
                                    `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </span>
                        </Tooltip>
                    );
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='order_form_table.short_price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'price',
                dataIndex: 'price',
                // width: 'fit-content',
                render: (data, row) => {
                    const strVal = Number(data).toFixed(2);
                    const strValTooltip = Number(data).toFixed(4);

                    return (
                        <Tooltip
                            title={
                                <div>
                                    <FormattedMessage id='order_form_table.price' />: {strValTooltip}
                                </div>
                            }
                        >
                            <span style={{ color: row.markupCheckFailed ? 'red' : null }}>
                                {data ? (
                                    `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </span>
                        </Tooltip>
                    );
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='order_form_table.short_count' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'count',
                // width: 'fit-content',
                dataIndex: 'count',
                render: data => {
                    const strVal = Number(data).toFixed(2);
                    const strValTooltip = Number(data).toFixed(4);

                    return (
                        <Tooltip
                            title={
                                <div>
                                    <FormattedMessage id='order_form_table.count' />: {strValTooltip}
                                </div>
                            }
                        >
                            <span>{data ? strVal : Number(0).toFixed(2)}</span>
                        </Tooltip>
                    );
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='services_table.units' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'measureUnit',
                dataIndex: 'measureUnit',
                width: window.innerWidth >= 1440 && 54,
                render: data => {
                    return <span>{data || undefined}</span>;
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='order_form_table.sum' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'sum',
                dataIndex: 'sum',
                // width: 'fit-content',
                render: (data, row) => {
                    const generalDiscount = _.get(this.props.fetchedOrder, 'order.servicesDiscount');
                    const strVal =
                        generalDiscount !== null || generalDiscount > 0
                            ? data - (data * generalDiscount) / 100
                            : generalDiscount < 0
                            ? data + (data * generalDiscount) / 100
                            : data;

                    return (
                        <Tooltip
                            title={
                                <div>
                                    <FormattedMessage id='order_form_table.sum' />: {Number(strVal).toFixed(4)}
                                </div>
                            }
                        >
                            <span style={{ color: row.markupCheckFailed ? 'red' : null }}>
                                {data ? (
                                    `${Number(strVal).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </span>
                        </Tooltip>
                    );
                }
            },
            ...(this.props.isRTN
                ? []
                : [
                      {
                          title: () => {
                              const updateAgreement = async value => {
                                  const payload = {
                                      updateMode: true,
                                      services: []
                                  };
                                  this.state.selectedRows.map(elem => {
                                      payload.services.push({
                                          id: elem.id,
                                          serviceId: elem.laborId,
                                          agreement: value.toUpperCase()
                                      });
                                  });
                                  await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);
                                  this.updateDataSource();
                              };
                              const menu = (
                                  <Menu onClick={this.handleMenuClick}>
                                      <Menu.Item
                                          key='undefined'
                                          onClick={() => {
                                              updateAgreement('undefined');
                                          }}
                                      >
                                          <QuestionCircleOutlined
                                              style={{
                                                  fontSize: 18,
                                                  verticalAlign: 'sub',
                                                  marginRight: 8
                                              }}
                                          />
                                          <FormattedMessage id='agreement.undefined' />
                                      </Menu.Item>
                                      <Menu.Item
                                          key='agreed'
                                          onClick={() => {
                                              updateAgreement('agreed');
                                          }}
                                          style={{ color: 'var(--green)' }}
                                      >
                                          <CheckCircleOutlined
                                              style={{
                                                  fontSize: 18,
                                                  verticalAlign: 'sub',
                                                  marginRight: 8
                                              }}
                                          />
                                          <FormattedMessage id='agreement.agreed' />
                                      </Menu.Item>
                                      <Menu.Item
                                          key='rejected'
                                          onClick={() => {
                                              updateAgreement('rejected');
                                          }}
                                          style={{ color: 'rgb(255, 126, 126)' }}
                                      >
                                          <CloseCircleOutlined
                                              style={{
                                                  fontSize: 18,
                                                  marginRight: 8
                                              }}
                                          />
                                          <FormattedMessage id='agreement.rejected' />
                                      </Menu.Item>
                                  </Menu>
                              );

                              return (
                                  <div>
                                      <FormattedMessage id='order_form_table.PD' />
                                      {isGrantAccessed(
                                          this.props.user,
                                          grants.OPERATIONS_ORDER_DOCUMENT_JOBS_REPAIR_ORDER_STATUS_CHANGE
                                      ) && (
                                          <div
                                              className={Styles.headerActions}
                                              style={{
                                                  paddingTop: 6,
                                                  opacity: this.state.selectedRowKeys.length == 0 && 0,
                                                  marginTop: this.state.selectedRowKeys.length == 0 && '-20px',
                                                  transitionDuration: '0.5s',
                                                  pointerEvents: this.state.selectedRowKeys.length == 0 && 'none'
                                              }}
                                          >
                                              <Dropdown overlay={menu} trigger={['click']}>
                                                  <QuestionCircleOutlined
                                                      style={{
                                                          fontSize: 24
                                                      }}
                                                  />
                                              </Dropdown>
                                          </div>
                                      )}
                                  </div>
                              );
                          },
                          key: 'agreement',
                          align: 'center',
                          width: 48,
                          dataIndex: 'agreement',
                          render: (data, row) => {
                              const { key } = row;
                              const confirmed = data.toLowerCase();
                              let color = null;
                              let icon = <QuestionCircleOutlined />;
                              switch (confirmed) {
                                  case 'rejected':
                                      color = 'rgb(255, 126, 126)';
                                      icon = <CloseCircleOutlined />;
                                      break;
                                  case 'agreed':
                                      color = 'var(--green)';
                                      icon = <CheckCircleOutlined />;
                                      break;
                                  default:
                                      color = null;
                                      icon = <QuestionCircleOutlined />;
                              }
                              const updateAgreement = value => {
                                  row.agreement = value.toUpperCase();
                                  this.updateLabor(key, row);
                              };
                              const menu = (
                                  <Menu onClick={this.handleMenuClick}>
                                      <Menu.Item
                                          key='undefined'
                                          onClick={() => {
                                              updateAgreement('undefined');
                                          }}
                                      >
                                          <QuestionCircleOutlined
                                              style={{
                                                  fontSize: 18,
                                                  verticalAlign: 'sub',
                                                  marginRight: 8
                                              }}
                                          />
                                          <FormattedMessage id='agreement.undefined' />
                                      </Menu.Item>
                                      <Menu.Item
                                          key='agreed'
                                          onClick={() => {
                                              updateAgreement('agreed');
                                          }}
                                          style={{ color: 'var(--green)' }}
                                      >
                                          <CheckCircleOutlined
                                              style={{
                                                  fontSize: 18,
                                                  verticalAlign: 'sub',
                                                  marginRight: 8
                                              }}
                                          />
                                          <FormattedMessage id='agreement.agreed' />
                                      </Menu.Item>
                                      <Menu.Item
                                          key='rejected'
                                          onClick={() => {
                                              updateAgreement('rejected');
                                          }}
                                          style={{ color: 'rgb(255, 126, 126)' }}
                                      >
                                          <CloseCircleOutlined
                                              style={{
                                                  fontSize: 18,
                                                  marginRight: 8
                                              }}
                                          />
                                          <FormattedMessage id='agreement.rejected' />
                                      </Menu.Item>
                                  </Menu>
                              );

                              return !isGrantAccessed(
                                  this.props.user,
                                  grants.OPERATIONS_ORDER_DOCUMENT_JOBS_REPAIR_ORDER_STATUS_CHANGE,
                                  accesses.ROWO
                              ) ? (
                                  <span
                                      style={{
                                          fontSize: 24,
                                          color
                                      }}
                                  >
                                      {icon}
                                  </span>
                              ) : (
                                  <div>
                                      <Dropdown disabled={this.props.disabled} overlay={menu} trigger={['click']}>
                                          <span
                                              style={{
                                                  fontSize: 24,
                                                  color
                                              }}
                                          >
                                              {icon}
                                          </span>
                                      </Dropdown>
                                  </div>
                              );
                          }
                      }
                  ])
        ];
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'order_mrd_add_labors_data' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    handleCancelComplex = () => {
        this.setState({
            complexName: undefined,
            type: undefined
        });
    };

    componentDidMount() {
        window.addEventListener('resize', this.setView);
        this.fetchLaborsTree();
        const tmp = [...this.props.orderServices];
        tmp.map((elem, i) => (elem.key = i));
        this.setState({
            dataSource: tmp
        });
    }

    // buildCounterpartyTree = async () => {
    //     const suppliers = await fetchAPI('GET', 'business_suppliers', {
    //         all: true,
    //         showHidden: true
    //     });
    //     const counterpartyTreeData = [
    //         {
    //             title: this.props.intl.formatMessage({ id: 'order_form_table.master' }),
    //             value: 'EMPLOYEE',
    //             selectable: false,
    //             children: this.props.employees
    //                 .filter(({ disabled, posts, id }) => {
    //                     if (this.props.orderServices.findIndex(({ employeeId }) => employeeId === id) !== -1) {
    //                         return true;
    //                     }

    //                     return !disabled && posts.findIndex(({ postName }) => postName === 'MECHANIC') !== -1;
    //                 })
    //                 .map(employee => ({
    //                     title: `${employee.surname} ${employee.name}`,
    //                     value: JSON.stringify({
    //                         counterparty: 'EMPLOYEE',
    //                         id: employee.id
    //                     })
    //                 }))
    //         },
    //         {
    //             title: this.props.intl.formatMessage({ id: 'order_form_table.supplier' }),
    //             value: 'SUPPLIER',
    //             selectable: false,
    //             children: suppliers
    //                 .filter(({ hide }) => !hide)
    //                 .map(supplier => ({
    //                     title: `${supplier.name}`,
    //                     value: JSON.stringify({
    //                         counterparty: 'SUPPLIER',
    //                         id: supplier.id
    //                     })
    //                 }))
    //         }
    //     ];
    //     this.setState({
    //         counterpartyTreeData,
    //         suppliers
    //     });
    // };

    async updateTimeMultiplier(multiplier) {
        this.laborTimeMultiplier = multiplier;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/${this.props.orderId}`;
        url += params;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ laborTimeMultiplier: multiplier })
            });
            const result = await response.json();
            if (result.success) {
                console.log('OK', result);
            } else {
                console.log('BAD', result);
            }
        } catch (error) {
            console.error('ERROR:', error);
        }
    }

    showServiceProductModal(key) {
        this.setState({
            serviceModalVisible: true,
            serviceModalKey: key
        });
    }

    hideServicelProductModal() {
        this.setState({
            serviceModalVisible: false
        });
    }

    updateDataSource() {
        if (this.state.fetched) {
            this.setState({
                fetched: false
            });
        }
        const callback = data => {
            data.orderServices.map((elem, index) => {
                elem.key = index;
            });
            this.setState({
                dataSource: data.orderServices,
                selectedRowKeys: [],
                selectedRows: [],
                fetched: true
            });
        };
        this.props.reloadOrderForm(callback, 'labors');
    }

    async updateLabor(key, labor) {
        this.state.dataSource[key] = labor;
        const data = {
            updateMode: true,
            services: [
                {
                    id: labor.id,
                    parentId: labor.parentId || undefined,
                    serviceId: labor.laborId,
                    laborUnitId: labor.laborUnitId || 1,
                    serviceName: labor.serviceName,
                    counterparty: labor.counterparty,
                    employeeId: labor.employeeId || null,
                    crewId: labor.crewId || null,
                    businessSupplierId: labor.businessSupplierId || null,
                    serviceHours: labor.hours,
                    purchasePrice: labor.purchasePrice || 0,
                    count: labor.count,
                    servicePrice: labor.price || 0,
                    serviceRowDiscount: labor.serviceRowDiscount || 0,
                    comment: labor.comment || {
                        comment: undefined,
                        positions: [],
                        problems: []
                    },
                    startTime: labor.startTime ? String(dayjs(labor.startTime).format('YYYY-MM-DD HH:mm')) : null,
                    endTime: labor.endTime ? String(dayjs(labor.endTime).format('YYYY-MM-DD HH:mm')) : null
                    // stage: labor.stage,
                }
            ]
        };
        if (
            isGrantAccessed(
                this.props.user,
                grants.OPERATIONS_ORDER_DOCUMENT_JOBS_REPAIR_ORDER_STATUS_CHANGE,
                accesses.ROWO
            )
        ) {
            data.services[0].agreement = labor.agreement;
        }
        const { status } = await fetchAPI('GET', 'orders/status', { orderId: this.props.orderId }, null);
        if (status === 'success') {
            window.location.reload();

            return;
        }
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/${this.props.orderId}`;
        url += params;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            this.updateDataSource();
        } catch (error) {
            console.error('ERROR:', error);
            this.updateDataSource();
        }
    }

    fetchLaborsTree() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/labors/master?makeTree=true`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.masterLabors = data.masterLabors;
                that.buildLaborsTree();
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    buildLaborsTree() {
        const treeData = [];
        for (let i = 0; i < this.masterLabors.length; i++) {
            const parentGroup = this.masterLabors[i];
            treeData.push({
                title: `${parentGroup.defaultMasterLaborName} (#${parentGroup.masterLaborId})`,
                name: parentGroup.defaultMasterLaborName,
                value: parentGroup.masterLaborId,
                className: Styles.groupTreeOption,
                selectable: false,
                children: []
            });
            for (let j = 0; j < parentGroup.childGroups.length; j++) {
                const childGroup = parentGroup.childGroups[j];
                treeData[i].children.push({
                    title: `${childGroup.defaultMasterLaborName} (#${childGroup.masterLaborId})`,
                    name: childGroup.defaultMasterLaborName,
                    value: childGroup.masterLaborId,
                    className: Styles.groupTreeOption,
                    selectable: false,
                    children: []
                });
                for (let k = 0; k < childGroup.childGroups.length; k++) {
                    const lastNode = childGroup.childGroups[k];
                    treeData[i].children[j].children.push({
                        title: `${lastNode.defaultMasterLaborName} (#${lastNode.masterLaborId})`,
                        name: lastNode.defaultMasterLaborName,
                        value: lastNode.masterLaborId,
                        className: Styles.groupTreeOption
                    });
                }
            }
        }
        this.laborsTreeData = treeData;
        this.setState({
            update: true
        });
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.activeKey !== 'services' && this.props.activeKey === 'services') ||
            prevProps.orderServices !== this.props.orderServices
        ) {
            const tmp = [...this.props.orderServices];
            tmp.map((elem, i) => (elem.key = i));
            this.setState({
                dataSource: tmp
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setView);
    }

    setView = () => {
        this.setState({});
    };

    handleSearchChange = debounce(e => this.setState({ search: e.target.value }), 300);

    filterDetails = dataSource => {
        const search = this.state.search.toLowerCase();

        return dataSource.filter(
            ({ serviceName, defaultName, laborId }) =>
                filterStringByPart(search, String(serviceName)) ||
                filterStringByPart(search, String(defaultName)) ||
                filterStringByPart(search, String(laborId))
        );
    };

    handleSearchSuppliers = _.debounce(value => {
        this.props.fetchSuppliersOptions(value, true);
    }, 1000);

    handleSearchLabors = _.debounce(value => {
        this.props.fetchLabors(value);
    }, 500);

    toggleSelectAll = (keys, data) => {
        this.setState({
            selectedRowKeys: keys.length === data.length ? [] : data.map(r => r.key),
            selectedRows: keys.length === data.length ? [] : data
        });
    };

    render() {
        const {
            user,
            isMobile,
            normHourPrice,
            disabled,
            tecdocId,
            labors,
            details,
            detailsTreeData,
            orderId,
            reloadOrderForm,
            modal,
            laborTimeMultiplier,
            defaultEmployeeId,
            employees,
            selectedVehicle,
            resetModal,
            setModal,
            units,
            unitDefault,
            beginDatetime,
            suppliers,
            selectedClient,
            isRTN
        } = this.props;
        const {
            selectedRowKeys,
            selectedRows,
            setPriceModal,
            setPriceModalValue,
            setPricePurchaseValue,
            counterpartyTreeData,
            dataSource,
            serviceModalKey,
            serviceModalVisible,

            visibleCreateIncomeServiceModal,
            suppliersIncomeModalRow,
            helperDrawerOpen,
            allLinks,
            search,
            open,
            edit,
            crewStats,
            crewStatsSingle,
            openChangeCounterpartyModal,
            counterpartyChangeType
        } = this.state;

        const tblDataSource = search.length ? this.filterDetails(dataSource) : dataSource;

        const { laborsDiscount, discount } = selectedClient;

        const columns = !isMobile
            ? this.columns()
            : this.columns().filter(
                  ({ key }) =>
                      key == 'serviceName' || key == 'price' || key == 'count' || key == 'sum' || key == 'measureUnit'
              );

        const headerCheckbox = (
            <Checkbox
                checked={selectedRowKeys.length}
                indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < tblDataSource.length}
                onChange={() => this.toggleSelectAll(selectedRowKeys, tblDataSource)}
            />
        );

        const rowSelection = {
            selectedRowKeys,
            columnTitle: headerCheckbox,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            },
            // onSelectAll: (selected, selectedRows, changeRows) => {
            //     if (selected) {
            //         const selectedRowKeys = tblDataSource.map(({ id }) => id);

            //         this.setState({
            //             selectedRowKeys,
            //             selectedRows: tblDataSource
            //         });

            //         return;
            //     }

            //     this.setState({
            //         selectedRowKeys: [],
            //         selectedRows: []
            //     });
            // },
            onSelect: (record, selected) => {
                if (selected) {
                    this.setState({
                        selectedRowKeys: [...selectedRows, record].map(({ id }) => id),
                        selectedRows: [...selectedRows, record]
                    });

                    return;
                }

                this.setState({
                    selectedRowKeys: selectedRowKeys.filter(id => id !== record.id),
                    selectedRows: selectedRows.filter(({ id }) => id !== record.id)
                });
            },
            getCheckboxProps: record => ({
                disabled: !record.id
            })
        };

        const pagination = {
            defaultPageSize: 25,
            size: 'small',
            pageSizeOptions: [15, 25, 50, 100],
            hideOnSinglePage: tblDataSource.length < 25,
            showSizeChanger: true
        };

        return (
            <Catcher>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 8
                    }}
                >
                    <div style={{ display: 'flex', gap: 8, width: '100%' }}>
                        <Input
                            addonBefore={<SearchOutlined />}
                            allowClear
                            onChange={this.handleSearchChange}
                            placeholder={this.props.intl.formatMessage({ id: 'order_form_table.services_search' })}
                            // value={search}
                        />

                        <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={async () => {
                                const links = await fetchAPI(
                                    'GET',
                                    '/helps',
                                    { helpId: 'order_mrd_add_labors_data' },
                                    undefined,
                                    {
                                        handleErrorInternally: true
                                    }
                                );
                                this.setState({
                                    allLinks: links,
                                    helperDrawerOpen: true
                                });
                            }}
                        />
                    </div>
                </div>
                <DraggableTable
                    addDragColumn
                    bordered
                    className={Styles.serviceTable}
                    columns={columns}
                    dataSource={tblDataSource}
                    onDragEnd={async (fromIndex, toIndex) => {
                        await fetchAPI(
                            'PUT',
                            'orders/swap_labors',
                            {
                                orderId: this.props.orderId,
                                order1: dataSource[fromIndex - 1].order,
                                order2: dataSource[toIndex - 1].order
                            },
                            undefined,
                            { handleErrorInternally: true }
                        );
                        await this.updateDataSource();
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => {
                                isMobile && this.showServiceProductModal(rowIndex);
                            }
                        };
                    }}
                    pagination={pagination}
                    rowSelection={!isMobile && rowSelection}
                    size='small'
                    sticky={
                        window.innerWidth >= 1440 && {
                            offsetHeader: -16
                        }
                    }
                />
                {isMobile && (
                    <div
                        style={{
                            margin: '12px 0px 8px',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <ComplexesModal
                            bodyId={this.props.bodyId}
                            clientDiscount={this.props.selectedClient && this.props.selectedClient.discount}
                            clientId={this.props.clientId}
                            details={details}
                            detailsDiscount={this.props.selectedClient && this.props.selectedClient.detailsDiscount}
                            detailsTreeData={detailsTreeData}
                            disabled={disabled}
                            handleSearchLabors={this.handleSearchLabors}
                            isMobile={isMobile}
                            labors={labors}
                            laborsDiscount={this.props.selectedClient && this.props.selectedClient.laborsDiscount}
                            normHourPrice={normHourPrice}
                            orderId={orderId}
                            reloadOrderForm={reloadOrderForm}
                            tecdocId={tecdocId}
                        />
                        <Button
                            data-qa='btn_show_service_product_modal_services_table_order_page'
                            onClick={() => this.showServiceProductModal(-1)}
                            style={{
                                margin: '0px 0px 0px 8px'
                            }}
                        >
                            <FormattedMessage id='add' />
                        </Button>
                    </div>
                )}
                <ChangeCounterpartyRowsModal
                    crews={this.props.crews}
                    handleSearchSuppliers={this.handleSearchSuppliers}
                    hideModal={() => {
                        this.setState({
                            openChangeCounterpartyModal: false
                        });
                    }}
                    open={openChangeCounterpartyModal}
                    orderId={orderId}
                    selectedRows={selectedRows}
                    suppliers={suppliers}
                    type={counterpartyChangeType}
                    updateDataSource={this.updateDataSource}
                />
                <AddServiceModal
                    beginDatetime={beginDatetime}
                    clientDiscount={discount}
                    crews={this.props.crews}
                    defaultEmployeeId={defaultEmployeeId}
                    details={details}
                    detailsTreeData={detailsTreeData}
                    disabled={this.props.disabled}
                    employees={employees}
                    handleSearchLabors={this.handleSearchLabors}
                    hideModal={() => this.hideServicelProductModal()}
                    isMobile={isMobile}
                    labor={dataSource[serviceModalKey]}
                    labors={labors}
                    laborsDiscount={laborsDiscount}
                    laborsTreeData={this.laborsTreeData}
                    laborTimeMultiplier={laborTimeMultiplier}
                    normHourPrice={normHourPrice}
                    orderId={orderId}
                    orderServices={dataSource}
                    setModal={setModal}
                    suppliers={suppliers || []}
                    tableKey={serviceModalKey}
                    tecdocId={tecdocId}
                    unitDefault={unitDefault}
                    units={units}
                    updateDataSource={this.updateDataSource}
                    updateLabor={this.updateLabor}
                    user={user}
                    visible={serviceModalVisible}
                />
                <LaborsNormHourModal
                    detailsTreeData={detailsTreeData}
                    orderId={orderId}
                    resetModal={resetModal}
                    selectedVehicle={selectedVehicle}
                    tecdocId={tecdocId}
                    user={user}
                    visible={modal}
                />
                <CreateIncomeServiceModal
                    hideModal={() => {
                        this.setState({
                            visibleCreateIncomeServiceModal: false,
                            selectedRowKeys: [],
                            selectedRows: [],
                            suppliersIncomeModalRow: undefined
                        });
                    }}
                    row={suppliersIncomeModalRow}
                    selectedRows={selectedRows}
                    updateDataSource={this.updateDataSource}
                    updateLabor={this.updateLabor}
                    user={user}
                    visible={visibleCreateIncomeServiceModal}
                />
                <UpdateLaborsPriceModal
                    hideModal={() => this.setState({ setPriceModal: undefined })}
                    isRTN={isRTN}
                    onConfirm={this.props.onConfirm}
                    open={setPriceModal}
                    orderId={orderId}
                    selectedRows={selectedRows}
                    updateDataSource={this.updateDataSource}
                />
                {/* <Modal
                    destroyOnClose
                    onCancel={() => {
                        this.setState({
                            setPriceModal: undefined,
                            setPriceModalValue: 1,
                            setPricePurchaseValue: 1
                        });
                    }}
                    onOk={async () => {
                        const payload = {
                            updateMode: true,
                            services: [
                                ...selectedRows.map(row => ({
                                    id: row.id,
                                    servicePrice:
                                        setPriceModal === 'fixed' ? setPriceModalValue : row.price * setPriceModalValue,
                                    purchasePrice:
                                        setPriceModal === 'fixed'
                                            ? setPricePurchaseValue
                                            : row.purchasePrice * setPricePurchaseValue,
                                    serviceId: row.laborId
                                }))
                            ]
                        };
                        try {
                            await fetchAPI('PUT', `orders/${orderId}`, undefined, payload, {
                                handleErrorInternally: true
                            });
                        } catch (e) {
                            notification.error({
                                message: this.props.intl.formatMessage({ id: 'error' })
                            });
                        }
                        await this.updateDataSource();
                        this.setState({
                            setPriceModal: undefined,
                            setPriceModalValue: 1,
                            setPricePurchaseValue: 1
                        });
                    }}
                    title={<FormattedMessage id='update_price' />}
                    visible={setPriceModal}
                >
                    <div style={{ margin: '0 0 8px 0' }}>
                        <FormattedMessage id={setPriceModal === 'fixed' ? 'product.sale_price' : 'factor_price'} />
                        <InputNumber
                            data-qa='input_number_set_price_table_order_page'
                            decimalSeparator=','
                            onChange={value => {
                                this.setState({
                                    setPriceModalValue: value
                                });
                            }}
                            precision={setPriceModal === 'fixed' ? 2 : 4}
                            step={setPriceModal === 'fixed' ? 5 : 0.002}
                            style={{ margin: '0 0 0 24px' }}
                            value={setPriceModalValue}
                        />
                    </div>
                    <div style={{ margin: '0 0 8px 0' }}>
                        <FormattedMessage
                            id={setPriceModal === 'fixed' ? 'order_form_table.prime_cost' : 'factor_purchase_price'}
                        />
                        <InputNumber
                            data-qa='input_number_set_price_purchase_table_order_page'
                            decimalSeparator=','
                            onChange={value => {
                                this.setState({
                                    setPricePurchaseValue: value
                                });
                            }}
                            precision={setPriceModal === 'fixed' ? 2 : 4}
                            step={setPriceModal === 'fixed' ? 5 : 0.002}
                            style={{ margin: '0 0 0 8px' }}
                            value={setPricePurchaseValue}
                        />
                    </div>
                </Modal> */}
                <EditLaborPercent
                    crews={this.props.crews}
                    crewStats={crewStats}
                    crewStatsSingle={crewStatsSingle}
                    edit={edit}
                    fetchCrews={this.props.fetchCrews}
                    hideModal={() => {
                        this.setState({
                            open: false,
                            edit: undefined,
                            crewStats: [],
                            crewStatsSingle: []
                        });
                    }}
                    open={open}
                    updateDataSource={this.updateDataSource}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Catcher>
        );
    }
}

export default ServicesTable;

const { Option } = Select;

@injectIntl
class QuickEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
        this.columns = () => [
            {
                title: <FormattedMessage id='order_form_table.service_type' />,
                key: 'defaultName',
                dataIndex: 'defaultName',
                render: data => {
                    return data || <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'serviceName',
                dataIndex: 'serviceName',
                render: data => {
                    return (
                        <Input
                            data-qa='input_service_name_table_order_page'
                            disabled={this.props.confirmed || this.props.stageDisabled || this.props.isRTN}
                            onChange={event => {
                                const newValue = event.target.value;
                                this.setState(prevState => ({
                                    dataSource: [{ ...prevState.dataSource[0], serviceName: newValue }],
                                    update: true
                                }));
                            }}
                            value={this.state.dataSource[0].serviceName}
                        />
                    );
                }
            },
            {
                title: (
                    <React.Fragment>
                        <FormattedMessage id='order_form_table.master' /> /{' '}
                        <FormattedMessage id='order_form_table.supplier' />
                    </React.Fragment>
                ),
                key: 'employeeId',
                render: row => {
                    const employeesOptions = this.props.employees
                        .filter(
                            ({ posts, disabled }) =>
                                !disabled && posts.map(({ postName }) => postName).includes('MECHANIC')
                        )
                        .map(({ name, surname, id }) => ({
                            value: id,
                            label: `${surname || ''} ${name || ''}`
                        }));

                    const suppliersOptions = this.props.suppliers.map(({ name, id }) => ({
                        value: id,
                        label: name
                    }));

                    const crews =
                        this.props.crews &&
                        this.props.crews.map(({ crewName, id }) => ({
                            value: id,
                            label: crewName
                        }));

                    return (
                        <EmployeeSupplierSelector
                            crews={crews}
                            disabled={this.props.disabled || this.props.isRTN}
                            employeesOptions={employeesOptions}
                            initialState={{
                                type: row.counterparty,
                                options:
                                    row.employeeId || row.businessSupplierId || row.crewId
                                        ? [
                                              {
                                                  value: row.crewId || row.employeeId || row.businessSupplierId,
                                                  label: row.crewId
                                                      ? row.crewName
                                                      : row.employeeId
                                                      ? row.employeeName
                                                      : row.businessSupplierName
                                              }
                                          ]
                                        : undefined
                            }}
                            onChange={async (value, type) => {
                                row.counterparty = type || 'EMPLOYEE';
                                row.employeeId = type === 'EMPLOYEE' && value ? value.key : null;
                                row.businessSupplierId = type === 'SUPPLIER' && value ? value.key : null;
                                row.crewId = type === 'CREW' && value ? value.key : null;
                                if (type === 'SUPPLIER') {
                                    row.businessSupplierName = value ? value.label : null;
                                } else if (type === 'CREW') {
                                    row.crewName = value ? value.label : null;
                                } else {
                                    row.employeeName = value ? value.label : null;
                                }
                                this.setState({});
                            }}
                            servicesStyle={{
                                width: '100%'
                            }}
                            suppliersOptions={suppliersOptions}
                            value={row.crewId || row.employeeId || row.businessSupplierId}
                        />
                        // <TreeSelect
                        //     data-qa='treeselect_counterparty_table_order_page'
                        //     dropdownMatchSelectWidth={280}
                        //     filterTreeNode={(input, node) => {
                        //         return (
                        //             node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        //             String(node.props.value).indexOf(input.toLowerCase()) >= 0
                        //         );
                        //     }}
                        //     getPopupContainer={trigger => trigger.parentNode}
                        //     listHeight={440}
                        //     onSelect={async (valueString, option) => {
                        //         const value = JSON.parse(valueString);
                        //         row.counterparty = value.counterparty;
                        //         row.employeeId = value.counterparty == 'EMPLOYEE' ? value.id : null;
                        //         row.businessSupplierId = value.counterparty == 'SUPPLIER' ? value.id : null;
                        //         this.setState({});
                        //     }}
                        //     placeholder={
                        //         <React.Fragment>
                        //             <FormattedMessage id='order_form_table.master' /> /{' '}
                        //             <FormattedMessage id='order_form_table.supplier' />
                        //         </React.Fragment>
                        //     }
                        //     showSearch
                        //     style={{ maxWidth: 180, color: 'var(--text)' }}
                        //     treeData={this.props.counterpartyTreeData}
                        //     treeDefaultExpandedKeys={['EMPLOYEE']}
                        //     value={
                        //         row.employeeId || row.businessSupplierId
                        //             ? JSON.stringify({
                        //                   counterparty: row.counterparty || 'EMPLOYEE',
                        //                   id: row.counterparty == 'SUPPLIER' ? row.businessSupplierId : row.employeeId
                        //               })
                        //             : undefined
                        //     }
                        // />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.discount' />
                        <Tooltip
                            placement='topRight'
                            title={this.props.intl.formatMessage({ id: 'client_container.discounts.info' })}
                        >
                            <QuestionCircleOutlined style={{ marginLeft: 4, color: 'var(--primary)' }} />
                        </Tooltip>
                    </div>
                ),
                key: 'serviceRowDiscount',
                dataIndex: 'serviceRowDiscount',
                width: '10%',
                align: 'right',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            className={Styles.serviceNumberInput}
                            decimalSeparator=','
                            disabled={this.props.confirmed}
                            formatter={value => `${value}%`}
                            max={100}
                            min={-100}
                            onChange={value => {
                                this.state.dataSource[0].serviceRowDiscount = value;
                                this.setState({
                                    update: true
                                });
                            }}
                            parser={value => value.replace('%', '').replace(',', '.')}
                            precision={2}
                            value={Math.round(data * 100) / 100 || 0}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                dataIndex: 'purchasePrice',
                render: data => {
                    return (
                        <InputNumber
                            className={Styles.serviceNumberInput}
                            data-qa='input_number_purchasePrice_table_order_page'
                            decimalSeparator=','
                            disabled={
                                !isGrantAccessed(
                                    this.props.user,
                                    grants.OPERATIONS_ORDER_DOCUMENT_JOBS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT
                                ) || this.props.isRTN
                            }
                            formatter={value => numeralFormatter(value)}
                            min={0}
                            onChange={value => {
                                this.state.dataSource[0].purchasePrice = value;
                                this.setState({
                                    update: true
                                });
                            }}
                            onStep={() => this.setState({})}
                            parser={value => numeralParser(value)}
                            precision={4}
                            value={data || 0}
                        />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'price',
                dataIndex: 'price',
                render: data => {
                    return (
                        <InputNumber
                            className={Styles.serviceNumberInput}
                            data-qa='input_number_price_coll_table_order_page'
                            decimalSeparator=','
                            disabled={
                                !isGrantAccessed(
                                    this.props.user,
                                    grants.OPERATIONS_ORDER_DOCUMENT_JOBS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT
                                ) ||
                                this.props.confirmed ||
                                this.props.isRTN
                            }
                            formatter={value => numeralFormatter(value)}
                            min={0}
                            onChange={value => {
                                this.state.dataSource[0].price = value;
                                this.state.dataSource[0].sum = value * this.state.dataSource[0].count;
                                this.setState({
                                    update: true
                                });
                            }}
                            onStep={() => this.setState({})}
                            parser={value => numeralParser(value)}
                            precision={4}
                            value={data || 0}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.count' />,
                key: 'count',
                dataIndex: 'count',
                render: data => {
                    return (
                        <InputNumber
                            className={Styles.serviceNumberInput}
                            data-qa='input_number_count_table_order_page'
                            decimalSeparator=','
                            disabled={this.props.confirmed}
                            formatter={value => numeralFormatter(value)}
                            min={0}
                            onChange={value => {
                                this.state.dataSource[0].count = value;
                                this.state.dataSource[0].sum = value * this.state.dataSource[0].price;
                                this.setState({
                                    update: true
                                });
                            }}
                            onStep={() => this.setState({})}
                            parser={value => numeralParser(value)}
                            precision={4}
                            step={0.1}
                            value={data || 0}
                        />
                    );
                }
            },
            {
                title: (
                    <React.Fragment>
                        <FormattedMessage id='services_table.units' /> <span style={{ color: 'red' }}>*</span>
                    </React.Fragment>
                ),
                key: 'laborUnitId',
                dataIndex: 'laborUnitId',
                width: '5%',
                render: (data, elem) => {
                    return (
                        <Select
                            ref={node => (this.laborRef = node)}
                            allowClear
                            data-qa='select_labor_unit_id_add_service_modal'
                            disabled={this.state.editing || elem.related}
                            dropdownMatchSelectWidth={100}
                            filterOption={(input, option) => {
                                const parts = input.toLowerCase().split(' ');

                                return (
                                    String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(option.value).indexOf(input.toLowerCase()) >= 0 ||
                                    String(option.cross_id).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(option.barcode).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    parts.every(part => String(option.children).toLowerCase().includes(part))
                                );
                            }}
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={(value, option) => {
                                this.state.dataSource[0].laborUnitId = value;
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.units_placeholder'
                            })}
                            showAction={['focus', 'click']}
                            showSearch
                            style={{ width: 100, color: 'var(--text)' }}
                            value={data}
                        >
                            {(this.props.units || []).map((elem, index) => (
                                <Option key={index} value={elem.id}>
                                    {elem.shortcut}
                                </Option>
                            ))}
                        </Select>
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.sum' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'sum',
                render: data => {
                    const sum = data.price * (data.count || 1) || 0;
                    const discountSum = sum - (sum * data.serviceRowDiscount) / 100;

                    const discount = discountSum.toFixed(4);
                    const sumValue = sum.toFixed(4);

                    return (
                        <InputNumber
                            className={Styles.serviceNumberInput}
                            data-qa='input_number_sum_coll_table_order_page'
                            decimalSeparator=','
                            disabled
                            formatter={value => numeralFormatter(value)}
                            onStep={() => this.setState({})}
                            parser={value => numeralParser(value)}
                            precision={4}
                            style={{ color: 'black', fontSize: discount > 9999 || sumValue > 9999 ? 11 : 13 }}
                            value={data.serviceRowDiscount > 0 ? discount : sumValue}
                        />
                    );
                }
            }
        ];
    }

    handleOk = () => {
        this.setState({
            visible: false
        });
        this.props.onConfirm(this.props.tableKey, this.state.dataSource[0]);
        this.props.updateDataSource();
    };

    handleCancel = () => {
        this.setState({
            visible: false
        });
    };

    render() {
        return (
            <React.Fragment>
                <Tooltip placement='top' title={<FormattedMessage id='quick_edit' />}>
                    <Button
                        data-qa='btn_quick_edit_table_order_page'
                        disabled={this.props.disabled}
                        icon={<PencilIcon />}
                        onClick={() => {
                            this.setState({
                                visible: true,
                                dataSource: [this.props.labor]
                            });
                        }}
                    />
                </Tooltip>
                <Modal
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='order_form_table.quick_edit' />}
                    visible={this.state.visible}
                    width='80%'
                >
                    <Table
                        bordered
                        columns={this.columns()}
                        dataSource={this.state.dataSource}
                        pagination={false}
                        size='small'
                    />
                </Modal>
            </React.Fragment>
        );
    }
}
